import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHeart,
  faCartShopping,
  faTag,
} from '@fortawesome/free-solid-svg-icons';

import './product-search-card.styles.css';

const SearchResultCard = ({ product, store = false, renderActionButton }) => {

  const formatPrice = (price) => {
    if (!price) return 'N/A';
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(price);
  };

  return (
    <div className={`product-search-card-container ${store ? 'store' : ''}`}>
      <div className={`product-search-image-container ${store ? 'store' : ''}`}>
        <img
          src={product.product.image.src}
          alt={product.product.title}
          className={`product-image ${store ? 'store' : ''}`}
        />
        <button className="price-button">
          <FontAwesomeIcon icon={faTag} /> {formatPrice(product.price)}
          {store && product.discount && (
            <span className="set-price">{product.set_price}</span>
          )}
          {store && product.discount && (
            <span className="discount-perc">{product.discount}</span>
          )}
        </button>
      </div>
      <div className={`brand-info ${store ? 'store' : ''}`}>
        <p className="brand-name">{product.product.vendor || ''}</p>
      </div>
      <div className={`product-info ${store ? 'store' : ''}`}>
        <a className="product-name">{product.product.title}</a>
        {renderActionButton(product)}
        {/* <button className="buy-now">Buy Now</button> */}
      </div>
      <div className={`interact-buttons ${store ? 'store' : ''}`}>
        <FontAwesomeIcon icon={faHeart} className="interact-icon" />
        <FontAwesomeIcon icon={faCartShopping} className="interact-icon" />
      </div>
    </div>
  );
};

export default SearchResultCard;
