export const ecommerceOptions = [
  { value: 'bigcommerce', label: 'BigCommerce' },
  { value: 'etsy', label: 'Etsy' },
  { value: 'shopify', label: 'Shopify' },
  { value: 'squarespace', label: 'Squarespace' },
  { value: 'woocommerce', label: 'WooCommerce' },
  { value: 'other', label: 'Other' },
];

export const creatorChallengeOptions = [
  { value: 'sell_more_products', label: 'Sell more products' },
  { value: 'increase_reach', label: 'Find new clients' },
  { value: 'grow_design_skills', label: 'Get new ideas' },
];
