import { Outlet } from 'react-router-dom';
import Navigation from '../components/navigation/home-navigation/home-navigation.component';
import Footer from '../components/footer-home/footer.component';

const HomeLayout = ({ user }) => {
  return (
    <>
      <Navigation user={user} />
      <Footer />
    </>
  );
};

export default HomeLayout;
