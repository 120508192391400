import React, { createContext, useState, useEffect, useContext } from 'react';
import { addToCart, createCart, getCart, removeFromCart } from '../utils/api/shopify';

export const CartContext = createContext({
  cart: null,
  setCart: (cartData) => {},
  addItemToCart: (variantId) => {},
  isVariantInCart: (variantId) => false,
  removeItemFromCart: (variantId) => {},
});

export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState(null);

  useEffect(() => {
    const initializeCart = async () => {
      let cartData = await getCart();
      if (!cartData) {
        cartData = await createCart();
        localStorage.setItem('cartId', cartData.id);
      }
      setCart(cartData);
    };
    initializeCart();
  }, []);

  const addItemToCart = async (variantId) => {
    const cartData = await addToCart(cart.id, variantId);
    setCart({
      ...cart,
      ...cartData,
    });
  };

  const isVariantInCart = (variantId) => {
    if (cart && cart.lines && cart.lines.edges) {
      return cart.lines.edges.some(edge => edge.node.merchandise.id === variantId);
    }
    return false;
  };

  const removeItemFromCart = async (variantId) => {
    if (!cart || !cart.lines || !cart.lines.edges) {
      console.error('Cart is not properly initialized');
      return;
    }

    const lineItem = cart.lines.edges.find(edge => edge.node.merchandise.id === variantId);
    console.log('lineItem', lineItem);
    if (!lineItem) {
      console.error('Line item not found for variant:', variantId);
      return;
    }

    const lineId = lineItem.node.id;
    const cartData = await removeFromCart(cart.id, lineId);
    setCart({
      ...cart,
      ...cartData,
    });
  };

  return (
    <CartContext.Provider value={{ cart, setCart, addItemToCart, isVariantInCart, removeItemFromCart }}>
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => useContext(CartContext);
