import { useState } from 'react';
import { SketchPicker, ChromePicker } from 'react-color';
import './color-picker.styles.css';

const ColorPicker = ({ name, onChange }) => {
  const [selectedColor, setSelectedColor] = useState('#ffffff');
  const [showColorPicker, setShowColorPicker] = useState(false);

  const handleColorChange = (color) => {
    setSelectedColor(color.hex);
    onChange(color);
  };

  const toggleColorPicker = () => {
    setShowColorPicker(!showColorPicker);
  };
  return (
    <div className="color-picker">
      <div className="color-picker-label">{name}</div>
      <div className="color-picker-container">
        <button
          className="color-picker-button"
          style={{ backgroundColor: selectedColor }}
          onClick={toggleColorPicker}
        />
        {showColorPicker && (
          <div className="color-picker-popover">
            <div className="color-picker-cover" onClick={toggleColorPicker} />
            <ChromePicker
              color={selectedColor}
              onChangeComplete={handleColorChange}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ColorPicker;
