import { useState } from 'react';
import { useRef } from 'react';
import { createPortal } from 'react-dom';

import { useModalContext } from '../../context/modal.context';

import Button from '../button/button.component';

import './add-to-folder.styles.css';

const AddToFolderModal = ({
  folders,
  onChange,
  onClose,
  onNewFolder,
  projectId,
  visible = false,
}) => {
  const optionsRef = useRef(null);
  const [selectedFolderId, setSelectedFolderId] = useState('');
  const { activeModal, setActiveModal } = useModalContext();

  const updateFolder = () => {
    const updatedFolders = folders.map((folder) => {
      if (folder.id === parseInt(selectedFolderId)) {
        if (!folder.contents.includes(projectId)) {
          return { ...folder, contents: [...folder.contents, projectId] };
        } else {
          console.log('Project is already in the folder');
        }
      }
      return folder;
    });
    onChange(updatedFolders);
    onClose();
    setActiveModal(null);
    setSelectedFolderId('');
  };

  const handleFolderChange = (e) => {
    setSelectedFolderId(e.target.value);
  };

  const close = () => {
    onClose();
    setActiveModal('');
    setSelectedFolderId('');
  };

  const newFolderOpen = () => {
    onNewFolder();
  };

  if (!visible) {
    return null;
  }

  return createPortal(
    <div
      ref={optionsRef}
      className={`add-to-folder-modal ${visible ? 'visible' : ''}`}
    >
      <div className="add-to-folder-modal-header">
        <h3>Add to folder</h3>
      </div>
      <div className="add-to-folder-modal-content">
        <p>Select a folder or create a new one:</p>
        <div className="select-folder-group">
          <select value={selectedFolderId} onChange={handleFolderChange}>
            <option value="" disabled>
              Select a folder...
            </option>
            {folders.map((folder) => (
              <option key={folder.id} value={folder.id}>
                {folder.text}
              </option>
            ))}
          </select>
          <div className="add-new-folder-button">
            <Button buttonType="simple" onClick={newFolderOpen}>
              New folder
            </Button>
          </div>
        </div>
        <div className="act-buttons">
          <div className="ok-cancel-button-container">
            <Button buttonType="cancel" onClick={close}>
              Cancel
            </Button>
          </div>
          <div className="ok-cancel-button-container">
            <Button buttonType="okay" onClick={updateFolder}>
              Add
            </Button>
          </div>
        </div>
      </div>
    </div>,
    document.getElementById('modal-portal'),
  );
};

export default AddToFolderModal;
