import { useContext, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import { ProjectContext } from '../../context/project.context';
import { useModalContext } from '../../context/modal.context';

import './rename-modal.styles.css';

const RenameProjectModal = ({ onClose, projectId, visible = false }) => {
  const renameModalRef = useRef(null);
  const inputRef = useRef(null);
  const { activeModal, setActiveModal } = useModalContext();
  const { currentProject, setCurrentProject, projects, setProjects } =
    useContext(ProjectContext);

  useEffect(() => {
    if (visible) {
      inputRef.current?.focus();
    }
  }, [visible]);

  const handleEnter = (event) => {
    let inputName;
    if (event.key === 'Enter') {
      event.preventDefault();
      inputName = event.target.value;
      if (inputName != '') {
        handleRename(inputName);
        setActiveModal('');
        onClose();
      } else {
        setActiveModal('');
        onClose();
      }
    } else if (event.key === 'Escape' || event.key === 'Esc') {
      setActiveModal('');
      onClose();
    }
  };

  const handleRename = (newProjectName) => {
    const now = new Date();
    const currentTime = now.toTimeString().split(' ')[0];
    const today = now.toISOString().split('T')[0];
    let workingProject;

    const selectedProject = projects.find(
      (project) => project.id === projectId,
    );

    workingProject = {
      ...selectedProject,
      text: newProjectName,
      date: today,
      time: currentTime,
    };
    setProjects(
      projects.map((project) =>
        project.id === selectedProject.id ? workingProject : project,
      ),
    );
  };

  const onRenameModalClose = () => {
    setActiveModal('');
    onClose();
  };

  if (!visible) {
    return null;
  }

  return createPortal(
    <div
      ref={renameModalRef}
      className={`rename-modal ${visible ? 'visible' : ''}`}
    >
      <div className="rename-input-group">
        <input
          ref={inputRef}
          type="text"
          id="new-name"
          placeholder="New project name..."
          onKeyDown={handleEnter}
        />
        <div
          className="rename-close-button-container"
          onClick={onRenameModalClose}
        >
          <FontAwesomeIcon icon={faPlus} className="rename-close-icon" />
        </div>
      </div>
    </div>,
    document.getElementById('modal-portal'),
  );
};

export default RenameProjectModal;
