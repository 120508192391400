// Libraries
import React, { useState, useEffect, useRef } from 'react';
import { Fragment, useContext } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import Grid from '@mui/material/Grid2';
import { Close } from '@mui/icons-material';

// Context
import { CreatorsContext } from '../../context/creator.context';
import { ProjectContext } from '../../context/project.context';
import { ProjectMessagesContext } from '../../context/messages.context';
import { UserContext } from '../../context/user.context';
import { useCommandContext } from '../../context/command.context';
import { useCtaContext } from '../../context/cta.context';
import { useImage } from '../../context/image.context';

// Components
import ChatInputGroup from '../../components/chat-input/chat-input.component';
import ChatMessages from '../../components/chat-messages/chat-messages.component';
import AssistantTopNavigation from '../../components/assistant-top-navigation/assistant-top-navigation.component';
import DragDropContainer from '../../components/drag-and-drop/drag-and-drop.component';

// Hooks
import { useImageUpload } from '../../hooks/image-upload.hook';

// Utils
import {
  createImageMessage,
  styleTransferParams,
} from '../../utils/openai/openai-assistants.util';
import { uploadFileToS3 } from '../../utils/s3-bucket-uploader';
import { imageGenerator } from '../../utils/image-generator.util';
import { translateHex } from '../../utils/openai/hex-translator.util';
import { createProject, getCommand } from '../../utils/api/projects';
import { processMarkdown } from '../../utils/openai/openai-assistants.util';
import { runOpenAIStream } from '../../utils/openai/openai-assistants.util';

// Assets
import shopResult from '../../assets/images/results/shop-results.png';

// Styles
import './assistant.styles.css';
import Box from '@mui/material/Box';
import { getCreatorByHandle } from '../../utils/api/creators';

const AssistantChat = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const startingCreatorHandle = query.get('creatorHandle');
  const createOnLoadHasRun = useRef(false);

  const messagesEndRef = useRef(null);
  const { currentCreator, setCurrentCreator } = useContext(CreatorsContext);
  const { currentUser } = useContext(UserContext);
  const navigate = useNavigate();
  const { currentProject, setCurrentProject, projects, setProjects } =
    useContext(ProjectContext);
  const { projectMessages, addMessageToCurrentProject } = useContext(
    ProjectMessagesContext,
  );
  const { activeCommand, setActiveCommand } = useCommandContext();
  const [commandBack, setCommandBack] = useState(false);
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState('Generating image(s)...');
  const [imagesReady, setImagesReady] = useState(false);
  const [userPrompt, setUserPrompt] = useState('');
  const [events, setEvents] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const { activeCta, setActiveCta } = useCtaContext();
  const [openForm, setOpenForm] = useState(false);
  const { projectCreators, addProjectCreator, isLoading } = useContext(
    ProjectMessagesContext,
  );
  const { uploadedImage, handleImageUpload, clearUploadedImage } = useImage();

  useEffect(() => {
    if (createOnLoadHasRun.current) {
      return;
    }
    createOnLoadHasRun.current = true;

    const lookUpStartingCreator = async () => {
      const creator = await getCreatorByHandle(startingCreatorHandle);
      addProjectCreator(creator);
      setCurrentCreator(creator);

      if (!currentProject) {
        // create a new project
        const now = new Date();
        const formattedDate = `${now.getMonth() + 1}-${now.getDate()}`;
        const newProject = await createProject(
          {
            name: `Project ${formattedDate}`,
          },
          creator?.id,
        );
        navigate(`/project/${newProject.id}`);
        return;
      }
    };

    if (startingCreatorHandle) {
      lookUpStartingCreator();
    }
  }, []);

  useEffect(() => {
    if (!currentProject) {
      setActiveCta('ctaActive');
      setActiveCommand('');
    } else {
      setActiveCta('ctaInactive');
    }
  }, [setActiveCta, currentProject, setCurrentProject]);

  useEffect(() => {
    if (!currentUser) {
      navigate('/');
    }
  }, [currentUser, navigate]);

  const tempProjectMessagesRef = useRef([]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [events, projects, currentProject, userPrompt]);

  useEffect(() => {
    if (imagesReady) {
      const prompt = userPrompt;
      console.log('User Prompt:', userPrompt);
      const newAIMessage = {
        sender: 'ai',
        text: `Here you go: ${prompt}`,
        imageUrl: images,
        imageUrl2: shopResult,
        creator: currentCreator,
      };

      setImagesReady(false);
    }
    scrollToBottom();
  }, [images, imagesReady]);

  useEffect(() => {
    if (errorMessage) {
      const newAIMessage = {
        sender: 'ai',
        text: errorMessage,
        creator: currentCreator,
      };

      setErrorMessage('');
    }
    scrollToBottom();
  }, [errorMessage]);

  useEffect(() => {
    if (projectCreators && projectCreators.length > 0 && !currentCreator) {
      setCurrentCreator(projectCreators[0]);
    }
  }, [projectCreators, currentCreator, setCurrentCreator]);

  const handleSendMessage = async (text, attachments) => {
    const newUserMessage = text;

    const { prompt, options } = await prepareUserMessage(
      newUserMessage,
      projectMessages,
      currentProject,
    );
    await addMessageToCurrentProject(prompt, options, currentCreator.id);
    setLoading(false);
    setLoadingText('');
  };

  const prepareUserMessage = async (
    newUserMessage,
    messageChain,
    workingProject,
  ) => {
    const trimmedMessage = newUserMessage.text.trim();
    const baseImageData = newUserMessage.imageData;
    const styleImageData = newUserMessage.styleImageData;
    const numberOfSamples = newUserMessage.samples;
    const baseImageUri = newUserMessage.imageUrl;
    const styleImageUri = newUserMessage.styleImageUrl;

    setLoading(true);
    setLoadingText('● ● ●');

    let prompt = trimmedMessage;
    let options = { command: null, numberOfSamples };

    if (trimmedMessage.toLowerCase().startsWith('/design')) {
      prompt = trimmedMessage.replace(/\/design:?\s*/i, '').trim();

      options = {
        command: 'DESIGN',
        baseImageData: baseImageData,
        numberOfSamples,
      };
    } else if (trimmedMessage.toLowerCase().startsWith('/moodboard')) {
      prompt = trimmedMessage.replace(/\/moodboard:?\s*/i, '').trim();

      options = {
        command: 'MOODBOARD',
        baseImageData,
        numberOfSamples,
      };
    } else if (trimmedMessage.toLowerCase().startsWith('/styletransfer')) {
      if (baseImageData && styleImageData) {
        prompt = trimmedMessage.replace(/\/styletransfer:?\s*/i, '').trim();
        setLoadingText('Analyzing images...');
        setLoading(true);

        options = {
          command: 'STYLE_TRANSFER',
          baseImageData,
          styleImageData,
          numberOfSamples,
        };
      } else {
        setErrorMessage(
          'Please provide both style and base images and try again.',
        );
      }
    } else if (trimmedMessage.toLowerCase().startsWith('/smartsearch')) {
      prompt = trimmedMessage.replace(/\/smartsearch\s*/i, '').trim();
      options = {
        command: 'SMART_SEARCH',
        numberOfSamples,
      };
      setLoading(true);
      setLoadingText('● ● ●');
    } else {
      setLoading(true);
      setLoadingText('● ● ●');
    }

    return { prompt, options };
  };

  const handleCtaClick = (visible) => {
    setOpenForm(visible);
  };

  const resetOpenForm = () => {
    setOpenForm(false);
  };

  const handleFilesDropped = (files) => {
    if (files.length > 0) {
      handleImageUpload(files[0]); // Assuming a single file
    }
  };

  return (
    <>
      <AssistantTopNavigation chat={true} store={false} discover={false} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
          width: '100%',
          flexGrow: 1,
          marginLeft: '20px',
          paddingLeft: '24px',
        }}
      >
        <Grid container sx={{ flexGrow: 1, overflow: 'auto' }}>
          <DragDropContainer onFilesDropped={handleFilesDropped}>
            <div className="chat-container">
              <ChatMessages
                messages={projectMessages || []}
                messagesEndRef={messagesEndRef}
                loading={loading}
                loadingMessage={loadingText}
                onCtaClick={handleCtaClick}
              />
            </div>
          </DragDropContainer>
        </Grid>
        <Grid container sx={{ flexGrow: 0, alignItems: 'start' }}>
          {uploadedImage && (
            <Grid size={1} sx={{ position: 'relative' }}>
              <img
                src={uploadedImage.dataUrl}
                alt="Uploaded"
                className="uploaded-base-image"
              />
              <div
                className="uploaded-base-image-close-button"
                onClick={clearUploadedImage}
              >
                <Close sx={{ fontSize: 12 }} />
              </div>
            </Grid>
          )}
          <Grid size={uploadedImage ? 11 : 12}>
            <div className="chat-input-container">
              <ChatInputGroup
                onSendMessage={handleSendMessage}
                loading={loading}
                loadingMessage={loadingText}
                commandBack={commandBack}
                openForm={openForm}
                setOpenForm={resetOpenForm}
              />
              <span className="note-text">
                This is a beta version, and some features may not work as
                expected.
              </span>
            </div>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default AssistantChat;
