import { fetchProtectedRoute } from './utils';

export const shopImage = async (imageUrl) => {
  const response = await fetchProtectedRoute(
    '/shop/search/image',
    'POST',
    null,
    {
      imageUrl,
    },
  );
  return response;
};
