import React from 'react';
import { CustomPicker } from 'react-color';
import { Saturation, Hue } from 'react-color/lib/components/common';
import './custom-color-picker.styles.css';

const CustomColorPicker = ({ hsl, hsv, onChange }) => {
  return (
    <div className="custom-color-picker">
      <div className="custom-sv-picker">
        <Saturation hsl={hsl} hsv={hsv} onChange={onChange} />
      </div>
      <div className="custom-hue-picker">
        <Hue hsl={hsl} onChange={onChange} direction="horizontal" />
      </div>
    </div>
  );
};

export default CustomPicker(CustomColorPicker);
