import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPencil,
  faBrush,
  faClipboard,
  faMagnifyingGlass,
  faCartShopping,
} from '@fortawesome/free-solid-svg-icons';
import './call-to-action.styles.css';

const ICONS = {
  design: faPencil,
  paint: faBrush,
  moodboard: faClipboard,
  smartSearch: faMagnifyingGlass,
};

const COMMAND = {
  design: 'Design',
  paint: 'Repaint',
  moodboard: 'Moodboard',
  smartSearch: 'SmartSearch',
};

const CallToAction = ({ ctaType, ctaText, onClick }) => {
  return (
    <div
      className="cta-container"
      onClick={() => {
        onClick(COMMAND[ctaType]);
      }}
    >
      <div className="cta-icon-container">
        <FontAwesomeIcon icon={ICONS[ctaType]} className="cta-icon" />
      </div>
      <div className="cta-text">{ctaText}</div>
    </div>
  );
};

export default CallToAction;
