import { useContext, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import { useModalContext } from '../../context/modal.context';

import { createNewFolder } from '../../utils/new-folder.util';

import './new-folder-modal.styles.css';

const NewFolderModal = ({
  onClose,
  projectId,
  handleNewFolder,
  visible = false,
}) => {
  const newFolderModalRef = useRef(null);
  const inputRef = useRef(null);
  const { activeModal, setActiveModal } = useModalContext();

  useEffect(() => {
    if (visible) {
      inputRef.current?.focus();
    }
  }, [visible]);

  const handleEnter = (event) => {
    let inputName;
    if (event.key === 'Enter') {
      event.preventDefault();
      inputName = event.target.value;
      if (inputName != '') {
        handleCreateFolder(inputName);
        setActiveModal('');
        onClose();
      } else {
        setActiveModal('');
        onClose();
      }
    } else if (event.key === 'Escape' || event.key === 'Esc') {
      setActiveModal('');
      onClose();
    }
  };

  const handleCreateFolder = (folderName) => {
    const newFolder = createNewFolder(folderName);
    const updateFolder = (folder) => {
      if (!folder.contents.includes(projectId)) {
        return { ...folder, contents: [...folder.contents, projectId] };
      } else {
        console.log('Project is already in the folder');
      }
      return folder;
    };
    const updatedFolder = updateFolder(newFolder);
    handleNewFolder(updatedFolder);
  };

  const onNewFolderModalClose = () => {
    setActiveModal('');
    onClose();
  };

  if (!visible) {
    return null;
  }

  return createPortal(
    <div
      ref={newFolderModalRef}
      className={`new-folder-modal ${visible ? 'visible' : ''}`}
    >
      <div className="new-folder-input-group">
        <input
          ref={inputRef}
          type="text"
          id="new-folder-name"
          placeholder="New folder name..."
          onKeyDown={handleEnter}
        />
        <div
          className="new-folder-close-button-container"
          onClick={onNewFolderModalClose}
        >
          <FontAwesomeIcon icon={faPlus} className="new-folder-close-icon" />
        </div>
      </div>
    </div>,
    document.getElementById('modal-portal'),
  );
};

export default NewFolderModal;
