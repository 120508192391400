export const folders = [
  {
    id: 1,
    text: 'House Designs',
    url: 'https://www.google.com',
    type: 'Projects',
    contents: [],
  },
  {
    id: 2,
    text: 'My living room',
    url: 'https://www.facebook.com',
    type: 'Projects',
    contents: [1, 2, 3],
  },
  {
    id: 3,
    text: 'Paint Samples',
    url: 'https://www.twitter.com',
    type: 'Projects',
    contents: [],
  },
];
