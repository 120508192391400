import React, { createContext, useContext, useState, useEffect } from 'react';
import { onAuthStateChange } from '../utils/api/auth';
import { getGoodHuesUser } from '../utils/api/users';

export const UserContext = createContext({
  currentUser: null,
  setCurrentUser: (user) => {}, // Update this line
});

export const UserProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const {
      data: { subscription },
    } = onAuthStateChange(async (user) => {
      if (user) {
        const { user: ghUser } = await getGoodHuesUser();
        setCurrentUser(ghUser);
      } else {
        setCurrentUser(null);
      }
      setIsLoading(false);
    });

    return () => {
      if (subscription) subscription.unsubscribe();
    };
  }, []);

  if (isLoading) {
    return null; // or a loading spinner
  }

  return (
    <UserContext.Provider value={{ currentUser, setCurrentUser }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => useContext(UserContext);
