import React, { useState, useEffect, useCallback } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import { getCreators } from '../../utils/api/creators';
import { debounce } from '../../utils/general';

export default function CreatorSelect({ open, onClose, onSelect }) {
  const [search, setSearch] = useState('');
  const [availableCreators, setAvailableCreators] = useState([]);

  const getAvailableCreators = async () => {
    const { creators } = await getCreators(search);

    // remove project creators from available creators
    // const projectCreatorsIds = projectCreators.map((creator) => creator.id);
    // const uniqueCreators = creators.filter(
    //   (creator) => !projectCreatorsIds.includes(creator.id),
    // );
    // limit to 10 creators
    const newAvailableCreators = creators.slice(0, 10);
    setAvailableCreators(newAvailableCreators);
  };

  const debouncedFetchCreators = useCallback(
    debounce(getAvailableCreators, 500),
    [],
  );

  const handleSearch = (value) => {
    setSearch(value);
    debouncedFetchCreators(value);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Select Creators for this Project</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Search Creators"
          type="text"
          fullWidth
          value={search}
          onChange={(e) => handleSearch(e.target.value)}
        />
        <List>
          {availableCreators.map((creator) => (
            <ListItem button key={creator.id} onClick={() => onSelect(creator)}>
              <ListItemAvatar>
                <Avatar src={creator.profile_image_url} />
              </ListItemAvatar>
              <ListItemText primary={creator.persona.handle} />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
