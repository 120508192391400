import { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import './tune-voice-hue.styles.css';

const TuneVoiceHue = ({ title, hue1, hue2, description }) => {
  const [showDescription, setShowDescription] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const gradientStyle = {
    background: `linear-gradient(to right, ${hue1}, ${hue2})`,
  };

  const handleMouseMove = (e) => {
    setPosition({ x: e.clientX, y: e.clientY });
  };

  return (
    <div className="tytv-hue-container">
      <div
        className="tune-your-twin-voice-option-container"
        onMouseOver={() => setShowDescription(true)}
        onMouseLeave={() => setShowDescription(false)}
        onMouseMove={handleMouseMove}
      >
        <div
          className="tune-your-twin-voice-option-hues"
          style={gradientStyle}
        ></div>
        <div className="tune-your-twin-voice-option-name">{title}</div>
      </div>
      {showDescription && (
        <div
          className="tune-your-twin-voice-option-description"
          style={{
            position: 'fixed',
            left: `${position.x + 10}px`,
            top: `${position.y + 10}px`,
          }}
        >
          {description}
        </div>
      )}
    </div>
  );
};

export default TuneVoiceHue;
