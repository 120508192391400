import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';

import { signInWithGoogle, signInWithEmail } from '../../utils/api/auth';

import FormInput from '../form-input/form-input.component';
import Button from '../button/button.component';

import './sign-in-form.styles.css';

const defaultFormFields = {
  email: '',
  password: '',
};

const SignInForm = () => {
  const [formFields, setFormFields] = useState(defaultFormFields);
  const { email, password } = formFields;
  const navigate = useNavigate();

  const handleGoogleSignIn = async () => {
    try {
      await signInWithGoogle();
    } catch (error) {
      console.error('Error signing in with Google', error);
      alert('Failed to sign in with Google');
    }
  };

  const resetFormFields = () => {
    setFormFields(defaultFormFields);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!email || !password) return;

    try {
      await signInWithEmail(email, password);
      resetFormFields();
    } catch (error) {
      console.error('Error signing in', error);
      alert('Invalid email or password');
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormFields({ ...formFields, [name]: value });
  };

  return (
    <div className="sign-in-form-container">
      <h2>Sign in</h2>
      <form onSubmit={handleSubmit}>
        <FormInput
          label="Email"
          inputOptions={{
            type: 'email',
            required: true,
            onChange: handleChange,
            name: 'email',
            value: email,
          }}
        />
        <FormInput
          label="Password"
          inputOptions={{
            type: 'password',
            required: true,
            onChange: handleChange,
            name: 'password',
            value: password,
          }}
        />
        <div className="buttons-container">
          <Button buttonType="login_form" type="submit">
            Log In
          </Button>
          <Button
            buttonType="google"
            type="button"
            onClick={handleGoogleSignIn}
          >
            Google Log In
          </Button>
        </div>
      </form>
      <div className="to-sign-up-container">
        <Link className="to-sign-up" to="/sign-up">
          Don't have an account? Sign up.
        </Link>
      </div>
    </div>
  );
};

export default SignInForm;
