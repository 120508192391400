import React, { useState } from 'react';
import { brandOptions } from '../../options/brands.options';
import { productTypeOptions } from '../../options/product-types.options';
import {
  ecommerceOptions,
  creatorChallengeOptions,
} from '../../options/ecommerce.options';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import theme from '../../theme';

const CreatorApplyModal = ({ isOpen, onClose, onSubmit }) => {
  const [formData, setFormData] = useState({
    email: '',
    creator_handle: '',
    instagram_url: '',
    tiktok_url: '',
    youtube_url: '',
    pinterest_url: '',
    linkedin_url: '',
    website_url: '',
    favorite_brands: [],
    sells_products: '',
    store_url: '',
    store_product_types: [],
    store_platform: '',
    creator_challenges: [],
    feature_requests: '',
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === 'radio') {
      setFormData((prevData) => ({
        ...prevData,
        [name]: checked,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleRadioChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value === 'true' ? true : false,
    }));
  };

  const handleMultiSelectChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };

  const handleFreeSoloChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const renderSelectField = (
    field,
    label,
    value,
    options,
    multiple = false,
  ) => {
    return (
      <FormControl fullWidth margin="normal">
        <InputLabel id={`simple-select-${field}`}>{label}</InputLabel>
        <Select
          labelId={`simple-select-${field}`}
          name={field}
          value={value}
          label={label}
          multiple={multiple}
          onChange={(e) => {
            multiple ? handleMultiSelectChange(e) : handleChange(e);
          }}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const renderFreeSolo = (field, label, value, options) => {
    return (
      <Autocomplete
        multiple
        options={options}
        value={value}
        onChange={(e, newValue) => handleFreeSoloChange(field, newValue)}
        freeSolo
        renderTags={(value, getTagProps) =>
          value.map((option, index) => {
            const { key, ...tagProps } = getTagProps({ index });
            return (
              <Chip variant="outlined" label={option} key={key} {...tagProps} />
            );
          })
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="filled"
            label={label}
            placeholder="Select or add new"
            margin="normal"
          />
        )}
      />
    );
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit(formData);
    onClose(); // Close the modal after submission
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        component: 'form',
        onSubmit: handleSubmit,
      }}
      sx={{
        '& .MuiDialog-paper': {
          backgroundColor: theme.palette.background.default,
        },
      }}
    >
      <DialogTitle>Creator account application</DialogTitle>
      <DialogContent>
        <DialogContentText>
          * Creator plans require application approval, please allow 48 hours
          for a response
        </DialogContentText>
        <TextField
          variant="outlined"
          size="small"
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          label="Email"
          required
          fullWidth
          margin="normal"
        />
        <TextField
          variant="outlined"
          size="small"
          type="text"
          name="creator_handle"
          value={formData.creator_handle}
          onChange={handleChange}
          label="Handle"
          helperText="This cannot be changed later"
          required
          fullWidth
          margin="normal"
        />
        <TextField
          variant="outlined"
          size="small"
          type="text"
          name="instagram_url"
          value={formData.instagram_url}
          onChange={handleChange}
          label="Instagram URL"
          fullWidth
          margin="normal"
        />
        <TextField
          variant="outlined"
          size="small"
          type="text"
          name="tiktok_url"
          value={formData.tiktok_url}
          onChange={handleChange}
          label="TikTok URL"
          fullWidth
          margin="normal"
        />
        <TextField
          variant="outlined"
          size="small"
          type="text"
          name="youtube_url"
          value={formData.youtube_url}
          onChange={handleChange}
          label="YouTube URL"
          fullWidth
          margin="normal"
        />
        <TextField
          variant="outlined"
          size="small"
          type="text"
          name="pinterest_url"
          value={formData.pinterest_url}
          onChange={handleChange}
          label="Pinterest URL"
          fullWidth
          margin="normal"
        />
        <TextField
          variant="outlined"
          size="small"
          type="text"
          name="linkedin_url"
          value={formData.linkedin_url}
          onChange={handleChange}
          label="LinkedIn URL"
          fullWidth
          margin="normal"
        />
        <TextField
          variant="outlined"
          size="small"
          type="text"
          name="website_url"
          value={formData.website_url}
          onChange={handleChange}
          label="Website URL"
          fullWidth
          margin="normal"
        />
        {renderFreeSolo(
          'favorite_brands',
          'Favorite Brands',
          formData.favorite_brands,
          brandOptions,
        )}
        <FormControl margin="normal">
          <FormLabel id="demo-row-radio-buttons-group-label">
            Do you sell your own products?
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="sells_products"
            onChange={handleRadioChange}
          >
            <FormControlLabel value="true" control={<Radio />} label="Yes" />
            <FormControlLabel value="false" control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>
        <TextField
          variant="outlined"
          size="small"
          type="text"
          name="store_url"
          value={formData.store_url}
          onChange={handleChange}
          label="Store URL"
          placeholder="Store URL"
          fullWidth
          margin="normal"
        />
        {renderFreeSolo(
          'store_product_types',
          'Store Product Types',
          formData.store_product_types,
          productTypeOptions,
        )}
        {renderSelectField(
          'store_platform',
          'Store Platform',
          formData.store_platform,
          ecommerceOptions,
        )}
        {renderSelectField(
          'creator_challenges',
          'Creator Challenges',
          formData.creator_challenges,
          creatorChallengeOptions,
          true,
        )}
        <TextField
          variant="outlined"
          size="small"
          type="text"
          name="feature_requests"
          value={formData.feature_requests}
          onChange={handleChange}
          placeholder="Feature Requests"
          fullWidth
          label="Feature Requests"
          margin="normal"
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="outlined" color="primary" type="submit">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreatorApplyModal;
