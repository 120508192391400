import React from 'react';
import TuneYourAIInstruction from '../tune-you-ai-instruction.component.jsx/tune-you-ai-instruction.component.jsx';
import Button from '../button/button.component.jsx';
import { getCreatorLink } from '../../utils/api/creators';

import './tune-your-ai.styles.css';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import MuiButton from '@mui/material/Button';
import { toast } from 'react-toastify';

const TuneYourAI = ({
  personaSettings,
  setPersonaSettings,
  creatorTexts,
  isLoading,
}) => {
  // Default values if creatorTexts is null
  const defaultTexts = {
    description: personaSettings?.description || '',
    instructions: personaSettings?.instructions || [],
    approaches: personaSettings?.approaches || [],
  };

  // Use default values if creatorTexts is null, and ensure arrays exist
  const texts = {
    description: creatorTexts?.description || defaultTexts.description,
    instructions: creatorTexts?.instructions || defaultTexts.instructions,
    approaches: creatorTexts?.approaches || defaultTexts.approaches,
  };

  const handleSave = () => {
    console.log('Save');
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPersonaSettings({ ...personaSettings, [name]: value });
  };

  const handleAddSupport = (field) => {
    setPersonaSettings({
      ...personaSettings,
      [field]: [...(personaSettings[field] || []), ''],
    });
  };

  const handleChangeSupport = (field, index, e) => {
    const { value } = e.target;
    const newSupport = [...(personaSettings[field] || [])];
    newSupport[index] = value;
    setPersonaSettings({ ...personaSettings, [field]: newSupport });
  };

  const handleDeleteSupport = (field, index) => {
    if (!personaSettings[field]) return;

    setPersonaSettings({
      ...personaSettings,
      [field]: personaSettings[field].filter((_, i) => i !== index),
    });
  };

  const handleCopyLink = () => {
    getCreatorLink(
      personaSettings?.handle,
      () => {
        toast.success('Link copied to clipboard!');
      },
      (err) => {
        console.error('Failed to copy link: ', err);
        toast.error('Failed to copy link');
      },
    );
  };

  return (
    <div className="tune-your-ai-container">
      <Typography variant="h6" sx={{ marginBottom: '20px' }}>
        AI twin name (Creator handle)
      </Typography>
      {/* <p className="tune-your-ai-subtitle">Can't be changed later</p> */}
      <input
        type="text"
        name="handle"
        className="ai-text-input"
        value={personaSettings?.handle || ''}
        disabled={!!personaSettings?.id}
        placeholder="What do you want to be called?"
        onChange={handleChange}
      />
      <Typography variant="h6">Your AI twin link:</Typography>
      <Typography
        variant="body1"
        sx={{ textAlign: 'center', margin: '12px 0' }}
      >
        Invite your audience to use your AI persona with this link
      </Typography>
      <Container
        sx={{ display: 'flex', justifyContent: 'center', marginBottom: '36px' }}
      >
        <MuiButton
          variant="contained"
          color="secondary"
          disabled={!personaSettings?.id}
          onClick={handleCopyLink}
        >
          Copy Your AI Twin Link
        </MuiButton>
      </Container>
      <Typography variant="h6" sx={{ marginBottom: '20px' }}>
        AI twin tagline
      </Typography>
      <input
        type="text"
        name="tagline"
        className="ai-text-input"
        value={personaSettings?.tagline || ''}
        placeholder="What would you like to be known for?"
        onChange={handleChange}
      />
      {/* <h1 className="tune-your-ai-title">AI twin description</h1>
      {isLoading ? (
        <div className="ai-textarea-input-loading">
          Generating description...
        </div>
      ) : (
        <textarea
          name="description"
          className="ai-textarea-input"
          placeholder="Your AI twin description"
          value={texts.description}
          onChange={handleChange}
        />
      )}
      <div className="tune-your-ai-instructions-container">
        <h1 className="tune-your-ai-title">Communication instructions</h1>
        {isLoading ? (
          <div className="ai-textarea-input-loading">
            Generating instructions...
          </div>
        ) : (
          texts.instructions.map((instruction, index) => (
            <TuneYourAIInstruction
            key={index}
            value={instruction}
            onChange={(e) => handleChangeSupport('instructions', index, e)}
            handleDelete={() => handleDeleteSupport('instructions', index)}
            />
          ))
        )}
        <Button
          buttonType="instruction"
          onClick={() => handleAddSupport('instructions')}
        >
          Add instruction
        </Button>
      </div>
      <div className="tune-your-ai-design-approach-container">
        <h1 className="tune-your-ai-title">Design approach</h1>
        {isLoading ? (
          <div className="ai-textarea-input-loading">
            Generating approaches...
          </div>
        ) : (
          texts.approaches.map((approach, index) => (
            <TuneYourAIInstruction
              key={index}
            value={approach}
            onChange={(e) => handleChangeSupport('approaches', index, e)}
            handleDelete={() => handleDeleteSupport('approaches', index)}
            />
          ))
        )}
        <Button
          buttonType="instruction"
          onClick={() => handleAddSupport('approaches')}
        >
          Add approach
        </Button>
      </div> */}
      <div className="tune-your-ai-save-container">
        <Button buttonType="login_form" onClick={handleSave}>
          Save
        </Button>
      </div>
    </div>
  );
};

export default TuneYourAI;
