import * as React from 'react';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { visuallyHidden } from '@mui/utils';
import theme from '../../../theme';

const headCells = [
  {
    id: 'image',
    numeric: false,
    disablePadding: false,
    disableSorting: true,
    label: '',
  },
  {
    id: 'title',
    numeric: false,
    disablePadding: false,
    disableSorting: false,
    label: 'Title',
  },
  {
    id: 'vendor',
    numeric: false,
    disablePadding: false,
    disableSorting: false,
    label: 'Vendor',
  },
  {
    id: 'productType',
    numeric: false,
    disablePadding: false,
    disableSorting: false,
    label: 'Product Type',
  },
  {
    id: 'price',
    numeric: true,
    disablePadding: false,
    disableSorting: false,
    label: 'Price',
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="secondary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {!headCell.disableSorting && (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function ProductSelectTable({
  page,
  products,
  totalProducts,
  selectedProducts,
  handleSelectAllClick,
  handleRequestSort,
  handleChangePage,
  renderFilterRow,
}) {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('title');

  const truncateText = (text, maxLength) => {
    if (!text) return '';
    return text.length > maxLength
      ? `${text.substring(0, maxLength)}...`
      : text;
  };

  return (
    <>
      <TableContainer
        sx={{
          backgroundColor: theme.palette.primary.light,
          border: `1px solid ${theme.palette.background.paper}`,
          borderRadius: '8px',
        }}
      >
        {renderFilterRow && renderFilterRow()}
        <Table
          sx={{
            minWidth: 750,
          }}
          aria-labelledby="tableTitle"
          size="medium"
        >
          <EnhancedTableHead
            numSelected={selectedProducts.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={products.length}
          />
          <TableBody
            sx={{
              '& .MuiTableCell-root': {
                textAlign: 'left',
                padding: '4px',
              },
              '& .MuiTableCell-root img': {
                maxWidth: '40px',
                maxHeight: '40px',
              },
            }}
          >
            {products.map((row, index) => {
              const isItemSelected = selectedProducts.includes(row.id);
              const labelId = `enhanced-table-checkbox-${index}`;

              return (
                <TableRow
                  hover
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row.id}
                  selected={isItemSelected}
                  sx={{ cursor: 'pointer' }}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="secondary"
                      checked={isItemSelected}
                      disabled={row.addedToStore}
                      inputProps={{
                        'aria-labelledby': labelId,
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <img src={row.image} alt={row.name} />
                  </TableCell>
                  <TableCell>{truncateText(row.title, 40)}</TableCell>
                  <TableCell>{row.vendor}</TableCell>
                  <TableCell>{row.productType}</TableCell>
                  <TableCell>{row.price}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={totalProducts}
        rowsPerPageOptions={[20]}
        rowsPerPage={20}
        page={page}
        onPageChange={handleChangePage}
      />
    </>
  );
}
