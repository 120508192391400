import { useState } from 'react';

import './sample-number.styles.css';

const SampleNumber = ({ onChange, samples, withLablel = true }) => {
  const [designCount, setDesignCount] = useState(1);

  const decrementDesignCount = () => {
    if (designCount > 1) {
      setDesignCount(designCount - 1);
      const newDesignCount = designCount - 1;
      onChange(newDesignCount);
    }
  };

  const incrementDesignCount = () => {
    if (designCount < 4) {
      setDesignCount(designCount + 1);
      const newDesignCount = designCount + 1;
      onChange(newDesignCount);
    }
  };

  return (
    <div className={`design-count ${samples ? '' : 'not-visible'}`}>
      <label
        className={`design-count-label ${withLablel ? '' : 'not-visible'}`}
      >
        # of designs
      </label>
      <div className={`count-element ${withLablel ? 'with-margin' : ''}`}>
        <button onClick={decrementDesignCount}>-</button>
        <span>{designCount}</span>
        <button onClick={incrementDesignCount}>+</button>
      </div>
    </div>
  );
};

export default SampleNumber;
