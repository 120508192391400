import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '../../components/button/button.component';
import TuneStyleSelector from '../tune-style-selector/tune-style-selector.component';

import { getMaterials, materialsData } from '../../options/materials.options';
import { getHuesAndFinishes, huesAndFinishesOptions } from '../../options/hues-and-finishes.options';
import { getStyleImages } from '../../options/style-images.options';
import './tune-your-design.styles.css';

const TuneYourDesign = ({ designStyle, setDesignStyle, handleNext }) => {
  const handleClickNext = () => {
    handleNext('ai-twin');
  };

  const handleToggleSelect = (field, style) => {
    const newDesignStyle = { ...designStyle };
    const newSelectedStyles = [...newDesignStyle[field]];
    if (newSelectedStyles.indexOf(style) > -1) {
      newSelectedStyles.splice(newSelectedStyles.indexOf(style), 1);
    } else {
      newSelectedStyles.push(style);
    }

    const updatedDesignStyle = {
      ...newDesignStyle,
      [field]: newSelectedStyles,
    };
    setDesignStyle(updatedDesignStyle);
  };

  const handleExtraInput = (e) => {
    const input = e.target.value;
    const field = e.target.name;
    const trimmedNewInputs = input.split(',').map((s) => s.trim());
    const newDesignStyle = { ...designStyle };
    newDesignStyle[field] = trimmedNewInputs;
    setDesignStyle(newDesignStyle);
  };

  const handleClearAll = () => {
    const newDesignStyle = { ...designStyle };

    setDesignStyle({
      ...newDesignStyle,
      preferred_styles: [],
    });
  };

  const styleImages = getStyleImages();
  const huesAndFinishes = getHuesAndFinishes();
  const materials = getMaterials();

  return (
    <div className="tune-your-design-container">
      <h1 className="tune-your-design-title">Preferred design styles</h1>
      <span className="tune-your-design-description">
        Check all options that apply.
      </span>
      <TuneStyleSelector
        category={null}
        selections={styleImages}
        selectedStyles={designStyle.preferred_styles}
        onToggleSelect={(style) =>
          handleToggleSelect('preferred_styles', style)
        }
      />
      <Link onClick={handleClearAll} className="clear-link">
        Clear selection
      </Link>
      {/* <ul>
        {selectedImages.map((imageName) => (
          <li key={imageName}>{imageName}</li>
        ))}
      </ul> */}
      <div className="tune-your-design-form">
        <input
          type="text"
          name="extra_styles"
          className="design-text-input"
          placeholder="Add any other styles not on the list."
          onChange={handleExtraInput}
        />
        <textarea
          className="design-textarea-input"
          name="signature_style"
          placeholder="What elements or techniques do you consider to be your signature in your designs?"
          maxLength={1000}
          onChange={(e) =>
            setDesignStyle({
              ...designStyle,
              signature_style: e.target.value,
            })
          }
        />
        <h1 className="tune-your-design-title">Preferred hues & finishes</h1>
        <span className="tune-your-design-description">
          Select all options that apply.
        </span>
        {Object.entries(huesAndFinishesOptions).map(([category, options]) => {
          return (
            <div key={category} className="tune-your-design-form-section">
              <h2 className="tune-your-design-subtitle">{category}</h2>
              <TuneStyleSelector
                key={category}
                category={category}
                selections={huesAndFinishes}
                selectedStyles={designStyle.preferred_hues_finishes}
                onToggleSelect={(style) =>
                  handleToggleSelect('preferred_hues_finishes', style)
              }
              />
            </div>
          );
        })}
        <input
          type="text"
          className="design-text-input"
          name="extra_hues_finishes"
          placeholder="Add any other hues & finishes not on the list."
          onChange={handleExtraInput}
        />
        <h1 className="tune-your-design-title">Preferred materials</h1>
        <span className="tune-your-design-description">
          Select all options that apply.
        </span>
        {Object.entries(materialsData).map(([category, options]) => {
          return (
            <div key={category} className="tune-your-design-form-section">
              <h2 className="tune-your-design-subtitle">{category}</h2>
              <TuneStyleSelector
                key={category}
                category={category}
                selections={materials}
                selectedStyles={designStyle.preferred_materials}
                onToggleSelect={(style) =>
                  handleToggleSelect('preferred_materials', style)
                }
              />
            </div>
          );
        })}
        <input
          type="text"
          className="design-text-input end"
          name="extra_materials"
          placeholder="Add any other materials not on the list."
          onChange={handleExtraInput}
        />
        <div className="next-button-container">
          <Button buttonType="simple" onClick={handleClickNext}>
            Next
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TuneYourDesign;
