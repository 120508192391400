import React, { useState, useEffect, useContext, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';

import { getImageOrientation } from '../../utils/image-orientation.util';

import './tune-style-selector.styles.css';

const TuneStyleSelector = ({ category, selections, selectedStyles, onToggleSelect }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [imageClass, setImageClass] = useState('');
  const [imageWidth, setImageWidth] = useState(0);
  const [hoveredDescription, setDescription] = useState('');
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const imageRef = useRef(null);

  useEffect(() => {
    if (imageWidth > 0) {
      document.documentElement.style.setProperty(
        '--image-width',
        `${imageWidth}px`,
      );
    }
  }, [imageWidth]);

  useEffect(() => {
    if (isOpen) {
      window.addEventListener('keydown', handleKeyDown);
    } else {
      window.removeEventListener('keydown', handleKeyDown);
    }

    // Cleanup on unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen]);

  const openModal = (index) => {
    setCurrentImageIndex(index);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleImageLoad = (e) => {
    const img = e.target;
    const orientationClass = getImageOrientation(img.width, img.height);
    setImageClass(orientationClass);
    if (imageRef.current) {
      setImageWidth(imageRef.current.clientWidth);
    }
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % selections.length);
  };

  const handlePreviousImage = () => {
    setCurrentImageIndex(
      (prevIndex) => (prevIndex - 1 + selections.length) % selections.length,
    );
  };

  const handleKeyDown = (event) => {
    if (!isOpen) return;
    if (event.key === 'ArrowRight' || event.key === ' ') {
      handleNextImage();
    } else if (event.key === 'ArrowLeft') {
      handlePreviousImage();
    }
  };

  const handleImageClick = (index) => {
    const name = selections[index].image.alt;

    onToggleSelect(name);
  };

  const handleMouseMove = (e) => {
    setPosition({ x: e.clientX, y: e.clientY });
  };

  const handleImageSelect = (e) => {
    onToggleSelect(e.target.name);
  };

  return (
    <div className="tune-styles-container">
      {selections.map((selection, index) => {
        const { image } = selection;
        return (
          (selection.category === undefined || selection.category === category) && (
            <div key={selection.value}>
              <div
              className="style-image-container"
              onMouseOver={() => setDescription(selection.description)}
              onMouseLeave={() => setDescription('')}
              onMouseMove={handleMouseMove}
            >
              <div className="style-image-wrapper">
                {image && (
                  <img
                    className="style-image"
                    src={image.src}
                    alt={image.alt}
                    onClick={() => openModal(index)}
                  />
                )}
                <input
                  className="style-check"
                  type="checkbox"
                  name={selection.value}
                  style={{ transform: 'scale(1.5)' }}
                  checked={selectedStyles.indexOf(selection.value) > -1}
                  onChange={handleImageSelect}
                />
              </div>
              <span className="style-name">{selection.name}</span>
            </div>
            {hoveredDescription && (
              <div
                className="style-description"
                style={{
                  position: 'fixed',
                  left: `${position.x + 10}px`,
                  top: `${position.y + 10}px`,
                }}
              >
                {hoveredDescription}
              </div>
            )}
            {isOpen && (
              <div className="style-modal-overlay" onClick={closeModal}>
                <div
                  className="style-modal-content"
                  onClick={(e) => e.stopPropagation()}
                >
                  <FontAwesomeIcon
                    icon={faChevronLeft}
                    className="chevron left-chevron"
                    onClick={handlePreviousImage}
                  />
                  <img
                    src={selections[currentImageIndex].image.src}
                    alt={selections[currentImageIndex].image.alt}
                    className={`style-modal-image ${imageClass}`}
                    onLoad={handleImageLoad}
                    ref={imageRef}
                    onClick={() => handleImageClick(currentImageIndex)}
                  />
                  <input
                    className="modal-style-check"
                    type="checkbox"
                    name={selections[currentImageIndex].value}
                    style={{ transform: 'scale(2)' }}
                    checked={
                      selectedStyles.indexOf(
                        selections[currentImageIndex].value,
                      ) > -1
                    }
                    onChange={handleImageSelect}
                  />
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    className="chevron right-chevron"
                    onClick={handleNextImage}
                  />
                </div>
              </div>
            )}
            </div>
          )
        );
      })}
    </div>
  );
};

export default TuneStyleSelector;
