import React, { createContext, useContext, useEffect, useState } from 'react';
import { getCreatorPersona } from '../utils/api/creators';

const MyCreatorContext = createContext({
  myCreator: null,
  setMyCreator: (creator) => {},
});

export const MyCreatorProvider = ({ children }) => {
  const [myCreator, setMyCreator] = useState(null);

  useEffect(() => {
    const fetchCreator = async () => {
      const { creator } = await getCreatorPersona();
      setMyCreator(creator);
    };
    fetchCreator();
  }, []);

  return (
    <MyCreatorContext.Provider value={{ myCreator, setMyCreator }}>
      {children}
    </MyCreatorContext.Provider>
  );
};

export const useMyCreatorContext = () => useContext(MyCreatorContext);
