import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      light: '#ffffff',
      main: '#fcf8f4', // Replace with your brand's primary color
    },
    secondary: {
      main: 'rgb(240, 49, 49)', // Replace with your brand's secondary color
    },
    background: {
      default: '#fcf8f4',
      paper: '#ffffff',
    },
    text: {
      primary: '#000000',
    },
  },
  typography: {
    fontFamily: '"Roboto", sans-serif',
    h1: {
      fontSize: '2.5rem',
      fontWeight: 700,
    },
  },
  spacing: 8, // Customize spacing if needed
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#1f1f1f',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '16px',
          backgroundColor: '#fcf8f4',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
        outlined: {
          borderColor: '#000000',
          color: '#000000',
        },
        outlinedPrimary: {
          backgroundColor: '#000000',
          color: '#ffffff',
        },
        outlinedSecondary: {
          backgroundColor: '#fcf8f4',
          color: '#000000',
        },
      },
    },
    MuiPopper: {
      styleOverrides: {
        root: {
          zIndex: 1200,
        },
      },
    },
  },
});

export default theme;
