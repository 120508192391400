import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useMyCreatorContext } from '../../../context/my-creator.context';
import { createSupplierConnection } from '../../../utils/api/creators';

const InstallDialog = ({ open, onClose, onSubmit }) => {
  const { myCreator } = useMyCreatorContext();
  const [shopDomain, setShopDomain] = useState('');
  const [storeConnection, setStoreConnection] = useState(null);

  useEffect(() => {
    if (myCreator && myCreator.store_domain) {
      setShopDomain(myCreator.store_domain);
    }
  }, [myCreator]);

  const handleInputChange = (event) => {
    setShopDomain(event.target.value);
  };

  const handleSave = async () => {
    const regex = /^[a-zA-Z0-9-]+\.myshopify\.com$/;
    if (regex.test(shopDomain)) {
      try {
        // Replace with actual API call to update the creator model
        const storeConnection = await createSupplierConnection(
          shopDomain && shopDomain.split('.')[0],
        );
        setStoreConnection(storeConnection);
        alert('Domain saved successfully!');
      } catch (error) {
        alert('Failed to save domain. Please try again.');
      }
    } else {
      alert(
        'Please enter a valid Shopify store domain, e.g., store.myshopify.com',
      );
    }
  };

  const handleSubmit = () => {
    if (storeConnection) {
      onSubmit(storeConnection.id);
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Install Shopify App</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Shopify Store Domain"
          type="text"
          fullWidth
          value={shopDomain}
          helperText="Enter the domain of your Shopify store, it should look like {store}.myshopify.com"
          onChange={handleInputChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSave} variant="contained" color="primary">
          Save
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="secondary"
          disabled={!storeConnection}
        >
          Install
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InstallDialog;
