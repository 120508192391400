import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTruckArrowRight,
  faCartFlatbed,
  faArrowRight,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';

import SampleNumber from '../sample-number/sample-number.component';
import UploadImage from '../upload-image/upload-image.component';
import './style-transfer.styles.css';

const StyleTransfer = ({ onChange, getBaseImage, getStyleImage, onClose }) => {
  const DesignCountChange = (newDesignCount) => {
    onChange(newDesignCount, 'number_samples', 'StyleTransfer');
  };

  return (
    <div className="style-transfer">
      <FontAwesomeIcon
        icon={faPlus}
        className={`close-style-transfer-button`}
        onClick={onClose}
      />
      <div className="style-transfer-row">
        <UploadImage
          onChange={onChange}
          command="StyleTransfer"
          getImage={getStyleImage}
          titleText="Upload image to transfer style FROM..."
        />
        <div className="transfer-box">
          <div className="transfer-icon-container">
            <SampleNumber onChange={DesignCountChange} samples={true} />
            <FontAwesomeIcon icon={faArrowRight} className="transfer-icon" />
          </div>
        </div>
        <UploadImage
          onChange={onChange}
          command="StyleTransfer"
          getImage={getBaseImage}
          titleText="Upload image to transfer style TO..."
        />
      </div>
    </div>
  );
};

export default StyleTransfer;
