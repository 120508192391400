import { createProject } from './api/projects';

export const createNewProject = async (newProject) => {
  const now = new Date();
  const currentTime = now.toTimeString().split(' ')[0];
  const today = now.toISOString().split('T')[0];

  try {
    const project = await createProject(newProject);

    return {
      ...project,
      text: `New Project ${today} ${currentTime}`,
      url: '',
      date: today,
      time: currentTime,
      messages: [],
      folders: [],
    };
  } catch (error) {
    console.error('Error creating thread:', error);
    return null;
  }
};
