import { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ProjectContext } from '../../context/project.context';
import { getProjectById } from '../../utils/api/projects'; // Import the new function

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faChevronRight,
  faEllipsis,
} from '@fortawesome/free-solid-svg-icons';

import { useModalContext } from '../../context/modal.context';
import { useCtaContext } from '../../context/cta.context';

import ProjectOptions from '../project-options-popover/project-options-popover.component';
import AddToFolderModal from '../add-to-folder/add-to-folder.component';
import RenameProjectModal from '../rename-modal/rename-modal.context';
import NewFolderModal from '../new-folder-modal/new-folder-modal.component';
import DeleteProjectModal from '../delete-project/delete-project.component';

import './project-list.styles.css';

const ProjectList = ({
  title,
  icon,
  previous = false,
  open = true,
  folders,
  onAddToFolder,
  onNewFolder,
}) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(open);
  const [projectOptions, setProjectOptions] = useState(null);
  const [optionsPosition, setOptionsPosition] = useState({ top: 0, left: 0 });
  const { currentProject, setCurrentProject, projects, fetchProjects } =
    useContext(ProjectContext);
  const [addToFolder, setAddToFolder] = useState(false);
  const [renameModal, setRenameModal] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const { activeModal, setActiveModal } = useModalContext();
  const { activeCta, setActiveCta } = useCtaContext();

  useEffect(() => {
    fetchProjects();
  }, []);

  const handleSelect = async (project) => {
    try {
      if (project.id !== currentProject?.id) {
        // const fullProjectData = await getProjectById(project.id);
        // setCurrentProject(fullProjectData); // Update with full project data
        // console.log(fullProjectData.name);

        // navigate to project/:projectId
        navigate(`/project/${project.id}`);
      }
    } catch (error) {
      console.error('Error selecting project:', error);
    }
  };

  const toggleList = () => {
    setIsOpen(!isOpen);
  };

  const filterProjects = (projects) => {
    const today = new Date().toISOString().split('T')[0];
    if (previous) {
      return projects.filter((project) => project.createdAt < today);
    } else {
      return projects.filter(
        (project) => project.createdAt.split('T')[0] === today,
      );
    }
  };

  const filteredProjects = filterProjects(projects);

  if (!filteredProjects || filteredProjects.length === 0) {
    return null;
  }

  const sortProjects = (projects) => {
    return projects.sort((a, b) => {
      return new Date(b.createdAt) - new Date(a.createdAt); // Sort with most recent first
    });
  };

  const openOptions = (event, projectId) => {
    event.stopPropagation();
    const target = event.currentTarget;
    if (target) {
      const rect = target.getBoundingClientRect();
      setOptionsPosition({ top: rect.bottom, left: rect.left });
      setProjectOptions(projectId);
    } else {
      console.error('Target element is undefined or null');
    }
  };

  const closeOptions = () => {
    setProjectOptions('');
  };

  const sortedProjects = sortProjects(filteredProjects);

  const handleAddToFolder = (projectId) => {
    setSelectedProjectId(projectId);
    setActiveModal('addToFolder');
    setAddToFolder(true);
  };

  const handleRenameProject = (projectId) => {
    setSelectedProjectId(projectId);
    setActiveModal('renameModal');
    setRenameModal(true);
    setAddToFolder(false);
  };

  const closeRenameModal = () => {
    setRenameModal(false);
    setSelectedProjectId(null);
    setActiveModal('');
  };

  const closeAddFolder = () => {
    setAddToFolder(false);
    setSelectedProjectId(null);
    setActiveModal('');
  };

  const closeDeleteModal = () => {
    setSelectedProjectId(null);
    setActiveModal('');
  };

  const handleFoldersUpdate = (updatedFolders) => {
    onAddToFolder(updatedFolders);
  };

  const handleOnNewFolder = (newFolder) => {
    onNewFolder(newFolder);
  };

  const handleCreateNewFolder = () => {
    setActiveModal('newFolder');
  };

  const newFolderClose = () => {
    setActiveModal('');
  };

  const handleDeleteProject = (projectId) => {
    setSelectedProjectId(projectId);
    setActiveModal('deleteModal');
    setRenameModal(false);
    setAddToFolder(false);
  };

  return (
    <div className="link-list">
      <div className="project-title-group" onClick={toggleList}>
        <h2>{title}</h2>
        <span className="project-chevron">
          <FontAwesomeIcon icon={isOpen ? faChevronDown : faChevronRight} />
        </span>
      </div>
      {isOpen && (
        <ul>
          {sortedProjects.map((project) => (
            <li
              key={project.id}
              className={`link-item ${currentProject && project.id === currentProject.id ? 'active' : ''}`}
            >
              <div
                className="icon-name-group"
                onClick={() => handleSelect(project)}
              >
                {icon && <FontAwesomeIcon icon={icon} className="link-icon" />}
                <a rel="noopener noreferrer">{project.name}</a>
              </div>
              {/* <span
                onClick={(event) => openOptions(event, project.id)}
                className="project-options-button"
              >
                <FontAwesomeIcon
                  icon={faEllipsis}
                  className={`project-ellipsis ${currentProject && project.id === currentProject.id ? 'active' : ''}`}
                />
              </span> */}
              <ProjectOptions
                addToFolder={() => handleAddToFolder(project.id)}
                renameProject={() => handleRenameProject(project.id)}
                deleteProject={() => handleDeleteProject(project.id)}
                visible={projectOptions === project.id}
                onClose={closeOptions}
                position={optionsPosition}
              />
              {addToFolder && selectedProjectId === project.id && (
                <AddToFolderModal
                  visible={activeModal === 'addToFolder'}
                  folders={folders}
                  onChange={handleFoldersUpdate}
                  onClose={closeAddFolder}
                  projectId={selectedProjectId}
                  onNewFolder={handleCreateNewFolder}
                />
              )}
              {renameModal && selectedProjectId === project.id && (
                <RenameProjectModal
                  visible={activeModal === 'renameModal'}
                  onClose={closeRenameModal}
                  projectId={selectedProjectId}
                />
              )}
              {selectedProjectId === project.id && (
                <DeleteProjectModal
                  visible={activeModal === 'deleteModal'}
                  onClose={closeDeleteModal}
                  projectId={selectedProjectId}
                />
              )}
            </li>
          ))}
        </ul>
      )}
      {activeModal === 'newFolder' && (
        <NewFolderModal
          visible={activeModal === 'newFolder'}
          handleNewFolder={handleOnNewFolder}
          projectId={selectedProjectId}
          onClose={newFolderClose}
        />
      )}
    </div>
  );
};

export default ProjectList;
