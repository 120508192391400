import { createPortal } from 'react-dom';
import { useRef } from 'react';
import CallToAction from '../call-to-action/call-to-action.component';

import { useCtaContext } from '../../context/cta.context';
import { useCommandContext } from '../../context/command.context';
import { useModalContext } from '../../context/modal.context';

import './cta-group.styles.css';

const CtaGroup = ({ visible, onCtaClick }) => {
  const ctaRef = useRef(null);
  const { activeCta, setActiveCta } = useCtaContext();
  const { activeCommand, setActiveCommand } = useCommandContext();
  const { activeModal, setActiveModal } = useModalContext();

  const handleClick = (command) => {
    setActiveCta('');
    setActiveCommand(command);
    setActiveModal('');
    onCtaClick(true);
  };

  if (!visible) {
    return null;
  }

  return createPortal(
    <div ref={ctaRef} className="cta-group-container">
      <div className="cta-group">
        <CallToAction
          ctaType="design"
          ctaText="Design my room"
          onClick={handleClick}
        />
        <CallToAction
          ctaType="paint"
          ctaText="Change wall color"
          onClick={handleClick}
        />
        <CallToAction
          ctaType="moodboard"
          ctaText="Create a moodboard"
          onClick={handleClick}
        />
        <CallToAction
          ctaType="smartSearch"
          ctaText="Where/What to buy?"
          onClick={handleClick}
        />
      </div>
    </div>,
    document.getElementById('cta-portal'),
  );
};

export default CtaGroup;
