import React, { useState, useMemo, useEffect } from 'react';

import TuneVoiceHue from '../tune-voice-hue/tune-voice-hue.component';

import './tune-voice-duo.styles.css';

const TuneVoiceDuo = ({
  title1,
  title2,
  hue11,
  hue12,
  hue21,
  hue22,
  above_zero,
  below_zero,
  description1,
  description2,
  property,
  onSliderChange,
  value,
}) => {
  const [sliderValue, setSliderValue] = useState(value || 0);

  useEffect(() => {
    setSliderValue(value || 0);
  }, [value]);

  const handleSliderChange = (e) => {
    const newValue = parseInt(e.target.value);
    setSliderValue(newValue);
    onSliderChange(
      e.target.name,
      newValue >= 0 ? above_zero : below_zero,
      newValue,
    );
  };

  const sliderStyle = useMemo(() => {
    const percentage = ((sliderValue + 100) / 200) * 100;
    const midPoint = 50;
    const fillPercentage = Math.abs(percentage - midPoint);
    const fillStart = sliderValue < 0 ? percentage : midPoint;
    const fillEnd = sliderValue < 0 ? midPoint : percentage;
    return {
      background: `linear-gradient(to right, 
                #DEDEDE 0%, #DEDEDE ${fillStart}%, 
                #FB8675 ${fillStart}%, #FB8675 ${fillEnd}%, 
                #DEDEDE ${fillEnd}%, #DEDEDE 100%)`,
    };
  }, [sliderValue]);

  return (
    <div className="tune-voice-duo-container">
      <TuneVoiceHue
        title={title1}
        hue1={hue11}
        hue2={hue12}
        description={description1}
      />
      <div className="tune-your-twin-slider-container">
        <input
          type="range"
          step="1"
          min="-100"
          max="100"
          value={sliderValue}
          onChange={handleSliderChange}
          className="tune-your-twin-slider"
          name={property}
          style={sliderStyle}
        />
        <div className="slider-value">{Math.abs(sliderValue)}%</div>
      </div>
      <TuneVoiceHue
        title={title2}
        hue1={hue21}
        hue2={hue22}
        description={description2}
      />
    </div>
  );
};

export default TuneVoiceDuo;
