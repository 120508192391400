import ProductCard from '../product-card/product-card.component';

import './product-grid.styles.css';

const ProductGrid = ({
  products,
  store = false,
  renderActionButton,
  size = 'xl',
}) => {
  return (
    <div className={`product-grid-container ${store ? 'store' : ''}`}>
      {store && (
        <div className="product-grid-title">
          Products I like - For the{' '}
          {/* <span className="product-category">{category}</span> -{' '}
          <span className="product-category">{subcategory}</span> */}
        </div>
      )}
      <div className={`product-grid ${store ? 'store' : ''}`}>
        {products.map((product) => (
          <ProductCard
            key={product.id}
            product={product}
            store={store}
            renderActionButton={renderActionButton}
          />
        ))}
      </div>
    </div>
  );
};

export default ProductGrid;
