export const brand_logos = [
    { name: "Alkemis.png", path: "https://goodhuesai.s3.amazonaws.com/brands/Alkemis.png" },
    { name: "Ashley.png", path: "https://goodhuesai.s3.amazonaws.com/brands/Ashley.png" },
    { name: "Astek-Home.png", path: "https://goodhuesai.s3.amazonaws.com/brands/Astek-Home.png" },
    { name: "Belle&June.png", path: "https://goodhuesai.s3.amazonaws.com/brands/Belle&June.png" },
    { name: "Blu-Dot.png", path: "https://goodhuesai.s3.amazonaws.com/brands/Blu-Dot.png" },
    { name: "C2-Paint.png", path: "https://goodhuesai.s3.amazonaws.com/brands/C2-Paint.png" },
    { name: "Everhem.png", path: "https://goodhuesai.s3.amazonaws.com/brands/Everhem.png" },
    { name: "Farrelly&Co.png", path: "https://goodhuesai.s3.amazonaws.com/brands/Farrelly&Co.png" },
    { name: "Fitueyes.png", path: "https://goodhuesai.s3.amazonaws.com/brands/Fitueyes.png" },
    { name: "Homebird.png", path: "https://goodhuesai.s3.amazonaws.com/brands/Homebird.png" },
    { name: "InteriorIcons.png", path: "https://goodhuesai.s3.amazonaws.com/brands/InteriorIcons.png" },
    { name: "Jonathan-Adler.png", path: "https://goodhuesai.s3.amazonaws.com/brands/Jonathan-Adler.png" },
    { name: "Kohler_Logo_CORP_2012-11-21_BLK.png", path: "https://goodhuesai.s3.amazonaws.com/brands/Kohler_Logo_CORP_2012-11-21_BLK.png" },
    { name: "LBE-Design.png", path: "https://goodhuesai.s3.amazonaws.com/brands/LBE-Design.png" },
    { name: "La-Jolie-Muse.png", path: "https://goodhuesai.s3.amazonaws.com/brands/La-Jolie-Muse.png" },
    { name: "Lindye-Galloway.png", path: "https://goodhuesai.s3.amazonaws.com/brands/Lindye-Galloway.png" },
    { name: "Met-Store.png", path: "https://goodhuesai.s3.amazonaws.com/brands/Met-Store.png" },
    { name: "Pier1.png", path: "https://goodhuesai.s3.amazonaws.com/brands/Pier1.png" },
    { name: "Poly&Bark.png", path: "https://goodhuesai.s3.amazonaws.com/brands/Poly&Bark.png" },
    { name: "Scout&Nimble.png", path: "https://goodhuesai.s3.amazonaws.com/brands/Scout&Nimble.png" },
    { name: "Soka-Home.png", path: "https://goodhuesai.s3.amazonaws.com/brands/Soka-Home.png" },
    { name: "Sophie-Lou-Jacobsen.png", path: "https://goodhuesai.s3.amazonaws.com/brands/Sophie-Lou-Jacobsen.png" },
    { name: "The-Shade-Store.png", path: "https://goodhuesai.s3.amazonaws.com/brands/The-Shade-Store.png" },
    { name: "Tonester.png", path: "https://goodhuesai.s3.amazonaws.com/brands/Tonester.png" },
    { name: "TwoPages.png", path: "https://goodhuesai.s3.amazonaws.com/brands/TwoPages.png" },
    { name: "arhaus.png", path: "https://goodhuesai.s3.amazonaws.com/brands/arhaus.png" },
    { name: "art.com.png", path: "https://goodhuesai.s3.amazonaws.com/brands/art.com.png" },
    { name: "benjamin-moore.png", path: "https://goodhuesai.s3.amazonaws.com/brands/benjamin-moore.png" },
    { name: "rove_concepts_logo.png", path: "https://goodhuesai.s3.amazonaws.com/brands/rove_concepts_logo.png" },
    { name: "sundaycitizen-logo.png", path: "https://goodhuesai.s3.amazonaws.com/brands/sundaycitizen-logo.png" }
  ];
  