import { useEffect } from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import { createPortal } from 'react-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderOpen } from '@fortawesome/free-regular-svg-icons';
import { faPencil, faTrashCan } from '@fortawesome/free-solid-svg-icons';

import './project-options-popover.styles.css';

const ProjectOptions = ({
  addToFolder,
  renameProject,
  deleteProject,
  visible = false,
  onClose,
  position,
}) => {
  const optionsRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (optionsRef.current && !optionsRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  if (!visible) {
    return null;
  }

  const handleAddToFolder = () => {
    addToFolder();
  };

  const handleRenameProject = () => {
    renameProject();
  };

  const handleDeleteProject = () => {
    deleteProject();
  };

  return createPortal(
    <div
      ref={optionsRef}
      className={`project-options-container ${visible ? 'visible' : ''}`}
      style={{ top: `${position.top - 5}px`, left: `${position.left + 10}px` }}
    >
      <ul>
        <li onClick={handleAddToFolder}>
          <FontAwesomeIcon icon={faFolderOpen} className="options-icon" />
          Add to folder
        </li>
        <li onClick={handleRenameProject}>
          <FontAwesomeIcon icon={faPencil} className="options-icon" />
          Rename
        </li>
        <li className="delete last" onClick={handleDeleteProject}>
          <FontAwesomeIcon icon={faTrashCan} className="options-icon" />
          Delete
        </li>
      </ul>
    </div>,
    document.getElementById('modal-portal'),
  );
};

export default ProjectOptions;
