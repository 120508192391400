import React, { useState, useEffect } from 'react';
import {
  addProductsToStore,
  installSalesChannel,
} from '../../utils/api/creators';
import { fetchProducts } from '../../utils/api/shopify';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './my-store.styles.css';
import { useMyCreatorContext } from '../../context/my-creator.context';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import ProductSelectTable from './components/product-select-table.component';
import Button from '@mui/material/Button';
import FilterOption from './components/filter-option.component'; // Import the FilterSelect component
import InstallDialog from './components/install-modal.component';

const MyStore = () => {
  const [products, setProducts] = useState([]);
  const [productFilters, setProductFilters] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({});
  const { myCreator } = useMyCreatorContext();
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [page, setPage] = useState(0);
  const [installModalOpen, setInstallModalOpen] = useState(false);

  useEffect(() => {
    if (myCreator) {
      if (!myCreator.persona) {
        toast.error('You need to create an AI persona to access this page.', {
          position: 'top-right',
        });
      } else {
        fetchProductsData(myCreator.persona.handle, selectedFilters);
      }
    }
  }, [myCreator]);

  useEffect(() => {
    if (productFilters.length > 0) {
      fetchProductsData(myCreator.persona.handle, selectedFilters);
    }
  }, [selectedFilters]);

  const fetchProductsData = async (creatorHandle) => {
    try {
      let joinedFilters = [];
      for (const filterValues of Object.values(selectedFilters)) {
        joinedFilters = joinedFilters.concat(filterValues);
      }
      const {
        products: fetchedProducts,
        filters,
        pageInfo,
        totalCount,
      } = await fetchProducts(creatorHandle, joinedFilters, 50);
      setProducts(fetchedProducts);
      setProductFilters(filters);
    } catch (error) {
      console.error('Error fetching products:', error);
      toast.error('Failed to fetch products. Please try again.');
    }
  };

  const addSelectedProductsToStore = async () => {
    try {
      await addProductsToStore(selectedProducts);
      toast.success('Product added to your store successfully!');
    } catch (error) {
      toast.error('Failed to add product to your store. Please try again.');
    }
  };

  const handleSelectAllClick = () => {
    if (selectedProducts.length === products.length) {
      setSelectedProducts([]);
    } else {
      setSelectedProducts(products.map((product) => product.id));
    }
  };

  const handleRowSelect = (productId) => {
    if (selectedProducts.includes(productId)) {
      setSelectedProducts((prevSelected) =>
        prevSelected.filter((id) => id !== productId),
      );
    } else {
      setSelectedProducts((prevSelected) => [...prevSelected, productId]);
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleFilterChange = (id, value) => {
    console.log(`handleFilterChange: ${id} ${value}`);
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      [id]: value,
    }));

    console.log('selectedFilters', selectedFilters);
  };

  const renderFilterRow = () => {
    return (
      <Grid container flexGrow={1}>
        {productFilters.map((filter) => (
          <Grid size={4} key={filter.id} padding={2}>
            <FilterOption
              sx={{ padding: 0 }}
              key={filter.id}
              filter={filter}
              selectedFilters={selectedFilters[filter.id] || []}
              onChange={handleFilterChange}
            />
          </Grid>
        ))}
        <Grid
          container
          justifyContent="flex-end"
          alignItems="center"
          size={4}
          padding={2}
        >
          <Grid>
            <Button
              variant="contained"
              color="secondary"
              onClick={addSelectedProductsToStore}
              disabled={selectedProducts.length === 0}
            >
              Add Selected Products
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const handleInstallSalesChannel = async (storeConnectionId) => {
    await installSalesChannel(storeConnectionId);
    toast.success('Sales channel installed successfully!');
  };

  if (!myCreator) {
    return <div>You need to create a creator profile to access this page.</div>;
  }

  return (
    <Grid
      container
      flexGrow={1}
      rowSpacing={6}
      padding={6}
      sx={{ paddingTop: '48px' }}
    >
      <Grid size={12}>
        <Typography variant="h4">Configure your Shopify store</Typography>
      </Grid>
      <Grid size={12}>
        <Grid container rowSpacing={2}>
          <Grid size={12}>
            <Typography variant="h6">
              Looking to sell more of your own products? Install our Shopify
              app.
            </Typography>
            <Typography variant="body1">
              After adding products to the <b>Goodhues</b> Sales Channel we will
              be able to automatically send you orders straight to your shopify.
            </Typography>
          </Grid>
          <Grid size={6}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setInstallModalOpen(true)}
            >
              Install Goodhues Shopify App
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid container flexGrow={1}>
        <Grid size={12}>
          <Typography variant="h4">Add Products to My Store</Typography>
        </Grid>
        <Grid size={12}>
          <Typography variant="body1">
            Add products to your store by selecting the products you want to
            sell and clicking the <b>Add Selected Products</b> button.
          </Typography>
        </Grid>
      </Grid>
      <Grid
        flexGrow={1}
        container
        sx={{
          '& .MuiCardContent-root': { minHeight: '115px' },
          '& .MuiCardMedia-root': { height: '150px' },
        }}
      >
        <ProductSelectTable
          page={page}
          products={products}
          totalProducts={products.length}
          selectedProducts={selectedProducts}
          handleRowSelect={handleRowSelect}
          handleRequestSort={handleRequestSort}
          handleSelectAllClick={handleSelectAllClick}
          handleChangePage={handleChangePage}
          renderFilterRow={renderFilterRow}
        />
      </Grid>
      <InstallDialog
        open={installModalOpen}
        onClose={() => setInstallModalOpen(false)}
        onSubmit={handleInstallSalesChannel}
      />
    </Grid>
  );
};

export default MyStore;
