import { useState } from 'react';
import { ChromePicker } from 'react-color';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import './wall-color.styles.css';

const WallColorPicker = ({ color, onChangeComplete, onClose }) => {
  const [wallColor, setWallColor] = useState('#ffffff');
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [colorBrand, setColorBrand] = useState('');

  const handleColorChange = (color) => {
    setWallColor(color.hex);
    onChangeComplete(color);
  };

  const handleBrandChange = (e) => {
    setColorBrand(e.target.value);
  };

  const toggleColorPicker = (e) => {
    e.stopPropagation();
    setShowColorPicker(!showColorPicker);
  };

  return (
    <div className="wall-color-module">
      <div className="wall-color-module-header">
        <p>Wall Color Module</p>
        <a className="wall-color-module-xmark" onClick={onClose}>
          <FontAwesomeIcon icon={faXmark} />
        </a>
      </div>
      <div className="wall-color-row">
        <div className="wall-color-label">Custom color</div>
        <div className="wall-color-picker-container">
          <button
            className="wall-color-picker"
            style={{ backgroundColor: wallColor }}
            onClick={toggleColorPicker}
          />
          {showColorPicker && (
            <div className="wall-popover">
              <div className="wall-cover" onClick={toggleColorPicker} />
              <ChromePicker
                color={wallColor}
                onChangeComplete={handleColorChange}
              />
            </div>
          )}
        </div>
      </div>
      <select value={colorBrand} onChange={handleBrandChange}>
        <option value="" disabled>
          Or choose a color from a brand...
        </option>
        <option value="tonester">Tonester Paints</option>
        <option value="another">Alkemis Paints</option>
        <option value="another">Farrelly Paints</option>
      </select>
    </div>
  );
};

export default WallColorPicker;
