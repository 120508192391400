export const styles = [
  { value: 'christmas', label: 'Christmas', status: 'inactive' },
  { value: 'halloween', label: 'Halloween', status: 'inactive' },
  { value: 'wes_anderson', label: 'Wes Anderson', status: 'active' },
  { value: 'stanley_kubrick', label: 'Stanley Kubrick', status: 'inactive' },
  { value: 'alfred_hitchcock', label: 'Alfred Hitchcock', status: 'inactive' },
  { value: 'jp_jeunet', label: 'Jean-Pierre Jeunet', status: 'inactive' },
  { value: 'salvador_dali', label: 'Salvador Dali', status: 'inactive' },
  { value: 'mc_escher', label: 'MC Escher', status: 'inactive' },
  { value: 'art_deco', label: 'Art Deco', status: 'active' },
  { value: 'art_nouveau', label: 'Art Nouveau', status: 'active' },
  { value: 'arts_crafts', label: 'Arts & Crafts', status: 'active' },
  { value: 'bauhaus', label: 'Bauhaus', status: 'active' },
  { value: 'bohemian', label: 'Bohemian', status: 'active' },
  { value: 'coastal', label: 'Coastal', status: 'active' },
  { value: 'contemporary', label: 'Contemporary', status: 'active' },
  { value: 'eclectic', label: 'Eclectic', status: 'active' },
  { value: 'farmhouse', label: 'Farmhouse', status: 'active' },
  { value: 'futuristic', label: 'Futuristic', status: 'active' },
  { value: 'hollywood_reg', label: 'Hollywood Regency', status: 'active' },
  { value: 'industrial', label: 'Industrial', status: 'active' },
  { value: 'japandi', label: 'Japandi', status: 'active' },
  { value: 'mediterranean', label: 'Mediterranean', status: 'active' },
  { value: 'mid_modern', label: 'Mid-Century Modern', status: 'active' },
  { value: 'minimal', label: 'Minimal', status: 'active' },
  { value: 'modern', label: 'Modern', status: 'active' },
  { value: 'moroccan', label: 'Moroccan', status: 'active' },
  { value: 'parisian', label: 'Parisian', status: 'active' },
  { value: 'scandi', label: 'Scandinavian', status: 'active' },
  { value: 'shabby_chic', label: 'Shabby Chic', status: 'active' },
  { value: 'traditional', label: 'Traditional', status: 'active' },
  { value: 'tropical', label: 'Tropical', status: 'active' },
  { value: '50_s', label: "50's", status: 'active' },
  { value: '60_s', label: "60's", status: 'active' },
  { value: '70_s', label: "70's", status: 'active' },
  { value: '80_s', label: "80's", status: 'active' },
];

export const colorMoods = [
  { value: 'earthy', label: 'Earthy' },
  { value: 'coastal', label: 'Coastal' },
  { value: 'classic', label: 'Classic' },
  { value: 'traditional', label: 'Traditional' },
  { value: 'serene', label: 'Serene' },
  { value: 'sophisticated', label: 'Sophisticated' },
  { value: 'ultra_modern', label: 'Ultra-Modern' },
  { value: 'energetic', label: 'Energetic' },
  { value: 'fun', label: 'Fun' },
  { value: 'eclectic', label: 'Eclectic' },
];

export const roomTypes = [
  { value: 'entrance', label: 'Residential - Entrance', status: 'active' },
  { value: 'living', label: 'Residential - Living Room', status: 'active' },
  { value: 'kitchen', label: 'Residential - Kitchen', status: 'active' },
  { value: 'bedroom', label: 'Residential - Bedroom', status: 'active' },
  {
    value: 'girl_bedroom',
    label: "Residential - Girl's Bedroom",
    status: 'active',
  },
  {
    value: 'boy_bedroom',
    label: "Residential - Boy's Bedroom",
    status: 'active',
  },
  { value: 'bathroom', label: 'Residential - Bathroom', status: 'active' },
  { value: 'dining', label: 'Residential - Dining Room', status: 'active' },
  { value: 'res_office', label: 'Residential - Home Office', status: 'active' },
  { value: 'nursery', label: 'Residential - Nursery', status: 'active' },
  {
    value: 'studio',
    label: 'Residential - Studio Apartment',
    status: 'active',
  },
  { value: 'camper', label: 'Residential - Camper Interior', status: 'active' },
  { value: 'terrace', label: 'Residential - Terrace', status: 'active' },
  { value: 'hallway', label: 'Residential - Hallway', status: 'active' },
  { value: 'com_office', label: 'Commercial - Office', status: 'active' },
  {
    value: 'office_lobby',
    label: 'Commercial - Office Lobby',
    status: 'active',
  },
  { value: 'coworking', label: 'Commercial - Coworking', status: 'active' },
  { value: 'coffee_shop', label: 'Commercial - Coffee Shop', status: 'active' },
  { value: 'store', label: 'Commercial - Store', status: 'active' },
  { value: 'restaurant', label: 'Commercial - Restaurant', status: 'active' },
  {
    value: 'rest_patio',
    label: 'Commercial - Restaurant Patio',
    status: 'active',
  },
  { value: 'hotel_lobby', label: 'Commercial - Hotel Lobby', status: 'active' },
  { value: 'hotel_room', label: 'Commercial - Hotel Room', status: 'active' },
  {
    value: 'hotel_bath',
    label: 'Commercial - Hotel Bathroom',
    status: 'active',
  },
];
