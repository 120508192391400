import './form-input.styles.css';

const FormInput = ({ label, inputOptions }) => {
  return (
    <div className="group">
      {label && <label className={`form-input-label`}>{label}</label>}
      <input className="form-input" {...inputOptions} />
    </div>
  );
};

export default FormInput;
