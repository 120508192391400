import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowUpFromBracket,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';

import { getImageOrientation } from '../../utils/image-orientation.util';

import { useImage } from '../../context/image.context';

import './upload-image.styles.css';

const UploadImage = ({ getImage, titleText = 'Upload Image (optional)' }) => {
  const [dragging, setDragging] = useState(false);
  const [file, setFile] = useState(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [imageClass, setImageClass] = useState('');

  const { uploadedImage, handleImageUpload, clearUploadedImage } = useImage();

  // Generate a unique ID for each instance of the component
  const uniqueId = `file-upload-${Math.random().toString(36).substr(2, 9)}`;

  useEffect(() => {
    if (uploadedImage && uploadedImage.file) {
      getImage(uploadedImage.file);
    }
  }, [uploadedImage, getImage]);

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleImageUpload(e.dataTransfer.files[0]);
      const file = e.dataTransfer.files[0];
      setFile(file);
      displayImage(file);
    }
  };

  const handleFileSelect = (e) => {
    if (e.target.files && e.target.files[0]) {
      handleImageUpload(e.target.files[0]);
      const file = e.target.files[0];
      setFile(file);
      displayImage(file);
    }
  };

  const displayImage = (file) => {
    getImage(file);
    const reader = new FileReader();
    reader.onloadend = () => {
      setImageSrc(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleImageLoad = (e) => {
    const img = e.target;
    const orientationClass = getImageOrientation(img.width, img.height);
    setImageClass(orientationClass);
  };

  const closeImage = (event) => {
    clearUploadedImage();
    setImageSrc(null);
    setFile(null);
  };

  return (
    <div className={`upload-image ${dragging ? 'dragging' : ''}`}>
      <p>{titleText}</p>
      <div
        className={`upload-box ${imageSrc ? 'hide-it' : ''}`}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <input
          type="file"
          onChange={handleFileSelect}
          style={{ display: 'none' }}
          id={uniqueId}
        />
        <label htmlFor={uniqueId}>
          <FontAwesomeIcon
            icon={faArrowUpFromBracket}
            className="upload-icon"
          />
          {file ? <p>{file.name}</p> : <p>Upload an image</p>}
        </label>
      </div>
      <div className="uploaded-image-container">
        {imageSrc && (
          <div>
            <img
              src={imageSrc}
              alt="Uploaded"
              className="uploaded-image"
              onLoad={handleImageLoad}
            />
            <a className="change-image" onClick={closeImage}>
              Change image
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default UploadImage;
