import { useState } from 'react';
import { SketchPicker, ChromePicker } from 'react-color';
import WallColorPicker from '../wall-color/wall-color.component';
import ColorPicker from '../color-picker/color-picker.component';

import { colorMoods } from '../../options/room-types-styles.options';

import './color-module.styles.css';

const ColorModule = ({ onChange, command }) => {
  const [accentColor, setAccentColor] = useState('#ffffff');
  const [wallColor, setWallColor] = useState('#ffffff');
  const [showWallColorPicker, setShowWallColorPicker] = useState(false);
  const [colorMood, setColorMood] = useState('');

  const handleColorChange = (color) => {
    setAccentColor(color.hex);
    onChange(color.hex, 'accent_color', command);
  };

  const handleWallColorChange = (color) => {
    setWallColor(color.hex);
    onChange(color.hex, 'wall_color', command);
  };

  const handleColorMoodChange = (e) => {
    setColorMood(e.target.value);
    onChange(
      e.target.options[e.target.selectedIndex].text,
      'color_mood',
      command,
    );
  };

  const toggleWallColorPicker = (e) => {
    e.stopPropagation();
    setShowWallColorPicker(!showWallColorPicker);
  };

  return (
    <div className="color-module">
      <p>Color Module</p>
      <div className="color-row-1">
        <ColorPicker name="Accent Color" onChange={handleColorChange} />
        <ColorPicker name="Wall Color" onChange={handleWallColorChange} />
        {/* <div className='paint-calculator-container'>
              <button className='paint-calculator-button'>Paint Calculator</button>
          </div> */}
      </div>
      {/* <button className='wall-color-button' onClick={toggleWallColorPicker}>Wall color...</button> */}
      {showWallColorPicker && (
        <div
          className="wall-color-module-popover"
          onClick={(e) => e.stopPropagation()}
        >
          {/* <div className="wall-color-module-cover" onClick={toggleWallColorPicker}/> */}
          <WallColorPicker
            color={wallColor}
            onChangeComplete={handleColorChange}
            onClose={toggleWallColorPicker}
          />
        </div>
      )}
      <select value={colorMood} onChange={handleColorMoodChange}>
        <option value="" disabled>
          Or choose a color mood...
        </option>
        {colorMoods.map((color) => (
          <option key={color.value} value={color.value}>
            {color.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default ColorModule;
