const SHOPIFY_DOMAIN = process.env.REACT_APP_SHOPIFY_SHOP_DOMAIN;
const STOREFRONT_ACCESS_TOKEN =
  process.env.REACT_APP_SHOPIFY_STOREFRONT_ACCESS_TOKEN;

const storefrontFetch = async (query, variables = {}) => {
  const response = await fetch(
    `https://${SHOPIFY_DOMAIN}/api/2024-10/graphql.json`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Shopify-Storefront-Access-Token': STOREFRONT_ACCESS_TOKEN,
      },
      body: JSON.stringify({ query, variables }),
    },
  );

  if (!response.ok) {
    throw new Error(`Shopify API error: ${response.statusText}`);
  }

  const result = await response.json();
  if (result.errors) {
    throw new Error(`GraphQL errors: ${JSON.stringify(result.errors)}`);
  }

  return result.data;
};

export const fetchProducts = async (creatorHandle, filters, after) => {
  const query = `
    query GetProducts($productFilters: [ProductFilter!], $query: String!) {
      search(first: 25, query: $query, productFilters: $productFilters, types: PRODUCT) {
        productFilters {
          id
          label
          type
          values {
            id
            label
            count
            input
          }
        }
        edges {
          node {
            ... on Product {
              id
              title
              description
              images(first: 1) {
                edges {
                  node {
                    originalSrc
                    altText
                  }
                }
              }
              priceRange {
                minVariantPrice {
                  amount
                  currencyCode
                }
              }
              productType
              tags
              vendor
            }
          }
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        totalCount
      }
    }
  `;

  const variables = {
    productFilters: filters.map((filter) => JSON.parse(filter)),
    query: `NOT tag:${creatorHandle}`,
  };

  const { search: data } = await storefrontFetch(query, variables);
  return {
    products: data.edges.map((edge) => ({
      ...edge.node,
      addedToStore: edge.node.tags.includes(`${creatorHandle}`),
      image: edge.node.images.edges[0]?.node.originalSrc,
      price: `${edge.node.priceRange.minVariantPrice.amount} ${edge.node.priceRange.minVariantPrice.currencyCode}`,
    })),
    filters: data.productFilters,
    pageInfo: data.pageInfo,
    totalCount: data.totalCount,
  };
};

export const fetchCreatorCollection = async (
  creatorHandle,
  filters = [],
  first = 250,
) => {
  const query = `
    query GetCreatorCollection($handle: String!, $first: Int!, $query: String) {
      collection(handle: $handle) {
        products(first: $first, query: $query) {
          filters {
            id
            label
            type
            values {
              id
              label
              count
              input
            }
          }
          edges {
            node {
              id
              title
              description
              handle
              priceRange {
                minVariantPrice {
                  amount
                  currencyCode
                }
              }
              images(first: 1) {
                edges {
                  node {
                    originalSrc
                    altText
                  }
                }
              }
              productType
              variants(first: 250) {
                edges {
                  node {
                    id
                    title
                    price {
                      amount
                      currencyCode
                    }
                    availableForSale
                    selectedOptions {
                      name
                      value
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;

  const queryString = filters
    .map((filter) => {
      return `filter.${filter.id}:${filter.value}`;
    })
    .join(' AND ');

  const variables = {
    handle: `${creatorHandle}-collection`,
    first,
    query: queryString,
  };

  const data = await storefrontFetch(query, variables);
  return data.collection?.products.edges.map((edge) => edge.node);
};

export const createCart = async () => {
  const mutation = `
      mutation cartCreate {
        cartCreate {
          cart {
            id
            checkoutUrl
          }
        }
      }
    `;

  const response = await storefrontFetch(mutation);
  return response.cartCreate.cart;
};

export const addToCart = async (cartId, variantId, quantity = 1) => {
  const mutation = `
      mutation cartLinesAdd($cartId: ID!, $lines: [CartLineInput!]!) {
        cartLinesAdd(cartId: $cartId, lines: $lines) {
          cart {
            id
            lines(first: 100) {
              edges {
                node {
                  id
                  quantity
                  merchandise {
                    ... on ProductVariant {
                      id
                      title
                      product {
                        title
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `;

  const variables = {
    cartId,
    lines: [
      {
        merchandiseId: variantId,
        quantity,
      },
    ],
  };

  const response = await storefrontFetch(mutation, variables);
  return response.cartLinesAdd.cart;
};

export const removeFromCart = async (cartId, lineId) => {
  const mutation = `
    mutation cartLinesRemove($cartId: ID!, $lineIds: [ID!]!) {
      cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
        cart {
          id
          lines(first: 100) {
            edges {
              node {
                id
                quantity
                merchandise {
                  ... on ProductVariant {
                    id
                    title
                    product {
                      title
                    }
                  }
                }
              }
            }
          }
        }
        userErrors {
          field
          message
        }
      }
    }
  `;

  const variables = {
    cartId,
    lineIds: [lineId],
  };

  const response = await storefrontFetch(mutation, variables);
  return response.cartLinesRemove.cart;
};

export const getCart = async () => {
  const cartId = localStorage.getItem('cartId');
  if (!cartId) return null;

  const query = `
    query getCart($cartId: ID!) {
      cart(id: $cartId) {
        id
        checkoutUrl
        lines(first: 100) {
          edges {
            node {
              id
              quantity
              merchandise {
                ... on ProductVariant {
                  id
                  title
                  product {
                    title
                  }
                }
              }
            }
          }
        }
      }
    }
  `;

  const variables = { cartId };

  try {
    const response = await storefrontFetch(query, variables);
    return response.cart;
  } catch (error) {
    console.error('Error fetching cart:', error);
    localStorage.removeItem('cartId'); // Clear invalid cart ID
    return null;
  }
};
