import { getAccessToken } from './auth';

const API_URL = process.env.REACT_APP_GH_API_URL;
const GH_URL = process.env.REACT_APP_GH_URL;

if (!API_URL) {
  throw new Error('API URL not found');
}

export const getAPIUrl = () => {
  return API_URL;
};

export const getGHUrl = () => {
  return GH_URL;
};

export const fetchProtectedRoute = async (url, method, headers, body) => {
  const token = await getAccessToken();
  let options = {
    method,
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  };

  if (body) {
    if (body instanceof FormData) {
      options.body = body;
    } else {
      options.headers['Content-Type'] = 'application/json';
      options.body = JSON.stringify(body);
    }
  }

  const inputUri = `${API_URL}${url}`;
  const response = await fetch(inputUri, options);

  if (!response.ok) {
    throw new Error(`Failed to send request ${inputUri}`);
  }

  const contentType = response.headers.get('content-type');
  if (contentType?.includes('text/event-stream')) {
    return response;
  } else {
    return response.json();
  }
};
