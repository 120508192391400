import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faWandMagicSparkles,
  faPaperPlane,
} from '@fortawesome/free-solid-svg-icons';
import Popover from '../../components/popover/popover.component';
import Button from '../../components/button/button.component';
import Slider from 'react-slick';
import { brand_logos } from '../../options/brands-logos.options';

const Home = () => {
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState('');
  const [logos, setLogos] = useState([]);

  const s3_url = process.env.REACT_APP_S3_URL;
  const logos_url = s3_url + '/brands/';

  useEffect(() => {
    const text = '/design a blue marble lounge chair';
    let index = 0;
    const interval = setInterval(() => {
      if (index < text.length) {
        setInputValue(text.slice(0, index + 1));
        index++;
      } else {
        clearInterval(interval);
      }
    }, 100);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const logoImages = brand_logos.map((logo) => logo.name);
    setLogos(logoImages);
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const recipient = 'brands@goodhues.ai';
  const subject = 'Inquiry from Website';
  const body =
    'Hello,\n\nI am interested in your services for brands. Can you please provide more information?\n\nName:\nEmail:\nCompany:\n\nThank you!';

  const mailtoLink = `mailto:${recipient}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

  return (
    <div className="home-body">
      <div className="home-content-container">
        <div className="text-block">
          <h1>Grow with designer specification at scale</h1>
          <a className="brands-contact-button" href={mailtoLink}>
            Contact Us
          </a>
        </div>
        <div className="image-block">
          <video 
            className="home-video" 
            poster={s3_url + '/brand/Brands.webp'} 
            autoPlay 
            loop 
            muted 
            playsInline
          >
            <source src={s3_url + '/brand/Brands HomeWeb.mp4'} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
      <div className="logo-carousel">
        <Slider {...settings}>
          {logos.map((logo, index) => (
            <div key={index}>
              <img
                src={logos_url + logo}
                alt={`Logo ${index + 1}`}
                className="logo-image"
              />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Home;
