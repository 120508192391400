import React, { createContext, useState, useContext, useCallback } from 'react';

const ImageContext = createContext();

const s3_url = process.env.REACT_APP_S3_URL;
export const DEFAULT_AVATAR = s3_url + '/avatars/generic_avatar.webp';

export const ImageProvider = ({ children }) => {
  const [uploadedImage, setUploadedImage] = useState(null);

  const handleImageUpload = useCallback((file) => {
    if (file instanceof Blob) {
      const reader = new FileReader();
      console.log('FileReader initialized');
      reader.onloadend = () => {
        setUploadedImage({
          file: file,
          dataUrl: reader.result,
        });
      };
      reader.readAsDataURL(file);
    } else {
      console.error('Invalid file type');
    }
  }, []);

  const clearUploadedImage = useCallback(() => {
    setUploadedImage(null);
  }, []);

  return (
    <ImageContext.Provider
      value={{ uploadedImage, handleImageUpload, clearUploadedImage }}
    >
      {children}
    </ImageContext.Provider>
  );
};

export const useImage = () => {
  const context = useContext(ImageContext);
  if (context === undefined) {
    throw new Error('useImage must be used within an ImageProvider');
  }
  return context;
};
