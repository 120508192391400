import React from 'react';
import ProductSearchCard from '../product-search-card/product-search-card.component';

import './product-search-grid.styles.css';

const ProductSearchGrid = ({ products, renderActionButton }) => {
  return (
    <div className={`product-search-grid-container`}>
      <div className={`product-search-grid`}>
        {products.map(
          (product, idx) =>
            product.product.image.src && (
              <ProductSearchCard key={idx} product={product} renderActionButton={renderActionButton} />
            ),
        )}
      </div>
    </div>
  );
};

export default ProductSearchGrid;
