export const huesAndFinishesOptions = {
  "Color Hues": [
    {
      "name": "Bold Brights (Warm)",
      "value": "bold_brights_(warm)"
    },
    {
      "name": "Bold Colors (Cool)",
      "value": "bold_colors_(cool)"
    },
    {
      "name": "Bold Colors (Warm)",
      "value": "bold_colors_(warm)"
    },
    {
      "name": "Cool Brights",
      "value": "cool_brights"
    },
    {
      "name": "Cool Neutrals",
      "value": "cool_neutrals"
    },
    {
      "name": "Dark Muted",
      "value": "dark_muted"
    },
    {
      "name": "Deep Darks",
      "value": "deep_darks"
    },
    {
      "name": "Dusky Purples",
      "value": "dusky_purples"
    },
    {
      "name": "Earthy Reds",
      "value": "earthy_reds"
    },
    {
      "name": "Frosty Whites",
      "value": "frosty_whites"
    },
    {
      "name": "Golden Yellows",
      "value": "golden_yellows"
    },
    {
      "name": "Leafy Greens",
      "value": "leafy_greens"
    },
    {
      "name": "Oceanic Blues",
      "value": "oceanic_blues"
    },
    {
      "name": "Pastels (cool)",
      "value": "pastels_(cool)"
    },
    {
      "name": "Peachy Pinks",
      "value": "peachy_pinks"
    },
    {
      "name": "Smoky Greys",
      "value": "smoky_greys"
    },
    {
      "name": "Sunset Oranges",
      "value": "sunset_oranges"
    },
    {
      "name": "Warm Brights",
      "value": "warm_brights"
    },
    {
      "name": "Warm Muted",
      "value": "warm_muted"
    },
    {
      "name": "Warm Neutrals",
      "value": "warm_neutrals"
    },
    {
      "name": "Warm Pastels",
      "value": "warm_pastels"
    },
    {
      "name": "Woodsy Browns",
      "value": "woodsy_browns"
    }
  ],
  "Finishes": [
    {
      "name": "Eggshell",
      "value": "eggshell"
    },
    {
      "name": "Glossy",
      "value": "glossy"
    },
    {
      "name": "Matte",
      "value": "matte"
    },
    {
      "name": "Metallic",
      "value": "metallic"
    },
    {
      "name": "Satin",
      "value": "satin"
    },
    {
      "name": "Textured",
      "value": "textured"
    },
    {
      "name": "Venetian Plaster",
      "value": "venetian_plaster"
    }
  ]
};

const s3_url = process.env.REACT_APP_S3_URL;

export const getHuesAndFinishes = () => {
  const huesAndFinishes = Object.entries(huesAndFinishesOptions).flatMap(([category, options]) => 
    options.map((option) => {
      const filename = option.name + '.webp';
      const src = s3_url + '/huesandfinishes/' + filename;

      return {
        image: {
          src,
          alt: option.name,
        },
        name: option.name,
        value: option.value,
        category,
      };
    })
  );

  return huesAndFinishes;
};