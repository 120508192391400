import './popover.styles.css';

const Popover = ({ children, text }) => {
  return (
    <div className="popover-container">
      {children}
      <span className="popover-text">{text}</span>
    </div>
  );
};

export default Popover;
