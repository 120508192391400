import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TuneVoiceDuo from '../../components/tune-voice-duo/tune-voice-duo.component';
import Button from '../../components/button/button.component';

import {
  voicesHuesOptions,
  personalities,
  personalityGradients,
} from '../../options/voices-hues.options';

import './tune-your-voice.styles.css';

const convertSliderValueToLetter = (option, value) => {
  if (typeof value !== 'number' || !option) {
    console.log('Invalid input:', { value, option });
    return '';
  }
  
  const letter = value >= 0 ? option.above_zero : option.below_zero;

  return letter;
};

const TuneYourVoice = ({
  personaPersonality,
  setPersonaPersonality,
  handleNext,
}) => {
  const [personalityType, setPersonalityType] = useState(() => {
    const initialType = {
      engagement_style: convertSliderValueToLetter(
        voicesHuesOptions[0],
        personaPersonality.engagement_style
      ),
      expression_style: convertSliderValueToLetter(
        voicesHuesOptions[1],
        personaPersonality.expression_style
      ),
      tone_style: convertSliderValueToLetter(
        voicesHuesOptions[2],
        personaPersonality.tone_style
      ),
      flow_style: convertSliderValueToLetter(
        voicesHuesOptions[3],
        personaPersonality.flow_style
      ),
    };
    return initialType;
  });

  const [personalityName, setPersonalityName] = useState('');
  const [personalityDescription, setPersonalityDescription] = useState('');
  const [personalityIcon, setPersonalityIcon] = useState('');
  const [selectedPersonality, setSelectedPersonality] = useState('');
  const bottomRef = useRef(null);

  useEffect(() => {
    if (personalityType && Object.keys(personalityType).length === 4) {
      const joinedPersonalityType = getConcisePersonalityType(personalityType);
      
      const personalityData = personalities[joinedPersonalityType];
      
      if (personalityData) {
        const { name, description, icon } = personalityData[0];        
        setPersonalityName(name);
        setPersonalityDescription(description);
        setPersonalityIcon(icon);
      } else {
        setPersonalityName('');
        setPersonalityDescription('');
        setPersonalityIcon('');
      }
    }
  }, [personalityType]);

  const getConcisePersonalityType = (personalityTypeMap) => {
    const result = Object.values(personalityTypeMap).join('');
    return result;
  };

  const handleSliderChange = (fieldKey, letter, value) => {
    setPersonaPersonality((prevValues) => {
      const newValues = { ...prevValues };
      newValues[fieldKey] = value;
      return newValues;
    });
    setPersonalityType((prevTypes) => {
      const newTypes = { ...prevTypes };
      newTypes[fieldKey] = letter;
      return newTypes;
    });
  };

  const handlePersonalityChange = (e) => {
    setSelectedPersonality(e.target.value);
  };

  const applySelectedPersonality = () => {
    if (selectedPersonality) {
      const personalityTypeLetters = selectedPersonality.split('');
      const personalityTypeValues = personalityTypeLetters.map(
        (letter, index) => {
          const option = voicesHuesOptions[index];
          return letter === option.above_zero ? 50 : -50;
        },
      );
      setPersonaPersonality({
        engagement_style: personalityTypeValues[0],
        expression_style: personalityTypeValues[1],
        tone_style: personalityTypeValues[2],
        flow_style: personalityTypeValues[3],
      });
      setPersonalityType({
        engagement_style: personalityTypeLetters[0],
        expression_style: personalityTypeLetters[1],
        tone_style: personalityTypeLetters[2],
        flow_style: personalityTypeLetters[3],
      });
    }
  };

  const handleClickNext = () => {
    handleNext('design');
  };

  return (
    <div className="tune-your-voice-container">
      <div className="onboarding-container">
        <h1 className="tune-your-twin-title">Tune your voice</h1>
        <span className="tune-your-twin-description">
          % degrees do affect your AI Twin's voice
        </span>
        <div className="tune-your-twin-options-container">
          {voicesHuesOptions.map((duo, index) => {
            const duoKey = Object.keys(duo)[0];
            const options = duo[duoKey];
            return (
              <TuneVoiceDuo
                key={index}
                title1={options[0].title}
                hue11={options[0].hue1.hex}
                hue12={options[0].hue2.hex}
                description1={options[0].description}
                title2={options[1].title}
                hue21={options[1].hue1.hex}
                hue22={options[1].hue2.hex}
                description2={options[1].description}
                above_zero={duo.above_zero}
                below_zero={duo.below_zero}
                value={personaPersonality[duo.db_field_key]}
                property={duo.db_field_key}
                onSliderChange={(property, letter, value) =>
                  handleSliderChange(property, letter, value)
                }
              />
            );
          })}
        </div>
        {personalityName && (
          <>
            <div className="personality-container">
              <div
                className="personality-icon"
                style={{
                  background:
                    personalityGradients[
                      getConcisePersonalityType(personalityType)
                    ],
                }}
              >
                <FontAwesomeIcon icon={personalityIcon} />
              </div>
              <h2 className="personality-type">
                Your voice is: {personalityName}
              </h2>
              <p className="personality-description">
                {personalityDescription}
              </p>
            </div>

            <div className="personality-select-container">
              <select
                value={selectedPersonality}
                onChange={handlePersonalityChange}
                className="personality-select"
              >
                <option value="">Compare to other voice styles</option>
                {Object.entries(personalities).map(([type, data]) => (
                  <option key={type} value={type}>
                    {data[0].name}
                  </option>
                ))}
              </select>
              <Button onClick={applySelectedPersonality} buttonType="home">
                Select
              </Button>
            </div>
            {selectedPersonality && (
              <div className="compare-personality-container">
                <div
                  className="compare-personality-icon"
                  style={{
                    background: personalityGradients[selectedPersonality],
                  }}
                >
                  <FontAwesomeIcon
                    icon={personalities[selectedPersonality][0].icon}
                  />
                </div>
                <p className="compare-personality-description">
                  {personalities[selectedPersonality][0].name}:{' '}
                  {personalities[selectedPersonality][0].description}
                </p>
              </div>
            )}
          </>
        )}
      </div>
      {!selectedPersonality && <div className="bottom-ref" />}
      <div className="next-button-container">
        <Button buttonType="simple" onClick={handleClickNext}>
          Next
        </Button>
      </div>
      <div ref={bottomRef} />
    </div>
  );
};

export default TuneYourVoice;
