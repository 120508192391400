import React from 'react';
import AssistantNavigation from '../components/navigation/assistant-navigation/assistant-navigation.component';
import { ProjectsProvider } from '../context/project.context';
import { ProjectMessagesProvider } from '../context/messages.context';

const AssistantLayout = ({ user }) => {
  return (
    <ProjectsProvider>
      <ProjectMessagesProvider>
        <AssistantNavigation user={user} />
      </ProjectMessagesProvider>
    </ProjectsProvider>
  );
};

export default AssistantLayout;
