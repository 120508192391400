import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { DialogContentText } from '@mui/material';

export default function ProjectDialog({ open, onClose, onSubmit, project }) {
  const [projectName, setProjectName] = useState(project?.name || '');

  const handleInputChange = (event) => {
    setProjectName(event.target.value);
  };

  const handleSubmit = () => {
    if (projectName.trim()) {
      onSubmit({ ...project, name: projectName });
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>{project ? 'Update Project' : 'Create Project'}</DialogTitle>
      <DialogContent>
        {!project && (
          <DialogContentText>
            Give your project a unique name so you can find it later
          </DialogContentText>
        )}
        <TextField
          autoFocus
          margin="dense"
          label="Project Name"
          type="text"
          fullWidth
          variant="outlined"
          value={projectName}
          onChange={handleInputChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained" color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} variant="contained" color="secondary">
          {project ? 'Update' : 'Create'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
