import React from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import OutlinedInput from '@mui/material/OutlinedInput';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const FilterOption = ({ filter, selectedFilters, onChange }) => {
  const renderFilterType = () => {
    switch (filter.type) {
      case 'LIST':
        return (
          <>
            <InputLabel id={`filter-select-label-${filter.id}`}>
              {filter.label}
            </InputLabel>
            <Select
              labelId={`filter-select-label-${filter.id}`}
              id={`filter-select-${filter.id}`}
              multiple
              value={selectedFilters}
              onChange={(e) => onChange(filter.id, e.target.value)}
              input={<OutlinedInput label="Brand" />}
              renderValue={(selected) =>
                selected
                  .map((s) => Object.values(JSON.parse(s)).join(', '))
                  .join(', ')
              }
              MenuProps={MenuProps}
            >
              {filter.values.map((value) => (
                <MenuItem key={value.id} value={value.input}>
                  <Checkbox
                    color="secondary"
                    checked={selectedFilters.includes(value.input)}
                  />
                  <ListItemText primary={value.label} />
                </MenuItem>
              ))}
            </Select>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <FormControl fullWidth variant="outlined" margin="normal">
      {renderFilterType()}
    </FormControl>
  );
};

export default FilterOption;
