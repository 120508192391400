import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCertificate, faCheck } from '@fortawesome/free-solid-svg-icons';

import genericAvatar from '../../assets/images/avatars/generic_avatar.webp';

import './creator-avatar.styles.css';

const CreatorAvatar = ({ avatar, size }) => {
  return (
    <div className="creator-avatar-container">
      <img
        src={avatar ? avatar : genericAvatar}
        className={`creator-store-avatar ${size === 'small' ? 'small' : ''}`}
      />
      <div className={`verify-icon ${size === 'small' ? 'small' : ''}`}>
        <FontAwesomeIcon
          icon={faCertificate}
          className={`certificate-icon ${size === 'small' ? 'small' : ''}`}
        />
        <FontAwesomeIcon
          icon={faCheck}
          className={`check-icon ${size === 'small' ? 'small' : ''}`}
        />
      </div>
    </div>
  );
};

export default CreatorAvatar;
