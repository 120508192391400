import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-regular-svg-icons';
import { faCartShopping, faTag } from '@fortawesome/free-solid-svg-icons';

import './product-card.styles.css';

const ProductCard = ({ product, store = false, renderActionButton }) => {
  const { id, title, description, priceRange, images, variants } = product;
  const price = priceRange.minVariantPrice.amount;
  const currencyCode = priceRange.minVariantPrice.currencyCode;
  const imageUrl = images.edges[0]?.node.originalSrc;
  const imageAlt = images.edges[0]?.node.altText || title;

  return (
    <div className={`product-card-container ${store ? 'store' : ''}`}>
      <div className={`product-image-container ${store ? 'store' : ''}`}>
        <img
          src={imageUrl}
          alt={imageAlt}
          className={`product-image ${store ? 'store' : ''}`}
        />
        <button className="price-button">
          <FontAwesomeIcon icon={faTag} /> {price}
          {/* {store && productData.discount && (
            <span className="set-price">{product.set_price}</span>
          )}
          {store && productData.discount && (
            <span className="discount-perc">{productData.discount}</span>
          )} */}
        </button>
      </div>
      <div className={`brand-info ${store ? 'store' : ''}`}>
        {/* <img src={brandData.logo} alt="Brand Logo" className="brand-logo" /> */}
        <p className="brand-name">{product.vendor}</p>
      </div>
      <div className={`product-info ${store ? 'store' : ''}`}>
        <h4>{title}</h4>
        <div className="price-button-container">
          <div className="price-tag">
            <FontAwesomeIcon icon={faTag} /> {price} {currencyCode}
          </div>
          {renderActionButton(product)}
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
