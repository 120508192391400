import './commands.styles.css';

const CommandButton = ({ command, onClick, selected }) => {
  return (
    <div>
      <button
        className={`command-button ${selected ? 'selected' : ''}`}
        onClick={() => onClick(command)}
      >
        /{command}
      </button>
    </div>
  );
};

export default CommandButton;
