import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faInstagram,
  faYoutube,
  faTiktok,
} from '@fortawesome/free-brands-svg-icons';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useCtaContext } from '../../context/cta.context';
import { useCart } from '../../context/cart.context';
import { CreatorsContext } from '../../context/creator.context';
import { ProjectContext } from '../../context/project.context';
import { createCart, addToCart } from '../../utils/api/shopify';

import AssistantTopNavigation from '../../components/assistant-top-navigation/assistant-top-navigation.component';
import ProductGrid from '../../components/product-grid/product-grid.component';
import CreatorAvatar from '../../components/creator-avatar/creator-avatar.component';
import { fetchCreatorCollection } from '../../utils/api/shopify';
import { getCreatorByHandle } from '../../utils/api/creators';

import './store.styles.css';

const Storefront = () => {
  const { username } = useParams();
  const navigate = useNavigate();
  const { activeCta, setActiveCta } = useCtaContext();
  const { currentCreator, setCurrentCreator } = useContext(CreatorsContext);
  const [productCategory, setProductCategory] = useState('all');
  const [productSubcategory, setProductSubcategory] = useState('all subcategories');
  const { currentProject, setCurrentProject } = useContext(ProjectContext);
  const { cart, setCart } = useCart();
  const [products, setProducts] = useState([]);
  const [error, setError] = useState(null);

  const s3_url = process.env.REACT_APP_S3_URL;
  const avatar = s3_url + '/avatars/generic_avatar.webp';

  useEffect(() => {
    setActiveCta('ctaInactive');
  }, [setActiveCta, currentProject, setCurrentProject]);

  const fetchCreator = useCallback(async () => {
    if (!username) return;

    setError(null);

    try {
      const creator = await getCreatorByHandle(username);
      setCurrentCreator(creator);
      await fetchCreatorProducts(username);
    } catch (err) {
      console.error('Error fetching creator:', err);
      setError('Failed to load creator data: ' + (err.message || 'Unknown error'));
    }
  }, [username, setCurrentCreator]);

  useEffect(() => {
    fetchCreator();
  }, [fetchCreator]);

  const fetchCreatorProducts = async () => {
    try {
      const fetchedProducts = await fetchCreatorCollection(username);
      
      if (fetchedProducts && fetchedProducts.length > 0) {
        setProducts(fetchedProducts);
        console.log(`Found ${fetchedProducts.length} products for creator ${username}`);
      } else {
        setProducts([]);
        toast.info('This creator doesn\'t have any products yet', {
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.error('Error fetching creator products:', error);
      setProducts([]);
      toast.error('Error fetching creator products', {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const availableCategories = () => {
    const categories = [
      'All',
      ...new Set(products.map((product) => product.productType)),
    ];
    return categories;
  };

  // const filteredProducts =
  //   productCategory === 'All'
  //     ? products
  //     : products.filter((product) => product.productType === productCategory);

  useEffect(() => {
    initializeCart();
  }, []);

  const initializeCart = async () => {
    try {
      const newCart = await createCart();
      setCart(newCart);
    } catch (error) {
      console.error('Error creating cart:', error);
      toast.error('Failed to initialize cart. Please try again.');
    }
  };

  const handleAddToCart = async (variantId) => {
    if (!cart) {
      toast.error('Cart is not initialized. Please try again.');
      return;
    }

    try {
      const updatedCart = await addToCart(cart.id, variantId);
      setCart({
        ...cart,
        ...updatedCart,
      });
      toast.success('Product added to cart successfully!');
    } catch (error) {
      console.error('Error adding to cart:', error);
      toast.error('Failed to add product to cart. Please try again.');
    }
  };

  const renderActionButton = (product) => {
    const variantId = product.variants?.edges[0]?.node.id;

    return (
      <button className="buy-now" onClick={() => handleAddToCart(variantId)}>
        Add to Cart
      </button>
    );
  };

  return (
    <div className="store-container">
      <div className="top-navigation">
        <AssistantTopNavigation store={true} chat={false} discover={false} />
      </div>
      <div className="store-content-container">
        <div className="creator-content-container">
          <CreatorAvatar avatar={currentCreator?.profile_image_url || avatar} />
          {currentCreator && (
            <>
              <h4>{currentCreator.persona?.handle}</h4>
              <p>{currentCreator.persona?.tagline}</p>
            </>
          )}
          <div className="creator-social-icons">
            {currentCreator?.instagram_url && (
              <a
                href={currentCreator.instagram_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  icon={faInstagram}
                  className="social-icon-style"
                />
              </a>
            )}
            {currentCreator?.tiktok_url && (
              <a
                href={currentCreator?.tiktok_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  icon={faTiktok}
                  className="social-icon-style"
                />
              </a>
            )}
            {currentCreator?.youtube_url && (
              <a
                href={currentCreator?.youtube_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  icon={faYoutube}
                  className="social-icon-style"
                />
              </a>
            )}
            {currentCreator?.website_url && (
              <a
                href={currentCreator?.website_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faGlobe} className="social-icon-style" />
              </a>
            )}
          </div>
        </div>
        <div className="products-categories">
          {availableCategories().map((category, idx) => (
            <div className="each-category" key={idx}>
              <span
                className={`category-select ${category === productCategory ? 'active' : ''}`}
                onClick={() => setProductCategory(category)}
              >
                {category}
              </span>
            </div>
          ))}
        </div>
        <div className="products-container">
          <h3>Products I like - {productCategory}</h3>
          <ProductGrid
            products={products}
            store={true}
            renderActionButton={renderActionButton}
            size="lg"
          />
        </div>
      </div>
    </div>
  );
};

export default Storefront;
