import React, { Fragment, useState } from 'react';
import { useContext } from 'react';
import { toast } from 'react-toastify';

import { UserContext } from '../../context/user.context';
import { deleteCreator, getCreatorLink } from '../../utils/api/creators';
import Button from '../button/button.component';
import MuiButton from '@mui/material/Button';

import './creator-top-navigation.styles.css';
import { useMyCreatorContext } from '../../context/my-creator.context';

const ENV = process.env.REACT_APP_ENV;

const CreatorTopNavigation = ({
  handleBack,
  handleDelete,
  handleSave,
  handleToggleVisibility,
}) => {
  const [isPublic, setIsPublic] = useState(false);
  const { currentUser } = useContext(UserContext);
  const { myCreator, setMyCreator } = useMyCreatorContext();

  const handleMakePublic = (event) => {
    setIsPublic(true);
  };

  const handleMakePrivate = (event) => {
    setIsPublic(false);
  };

  const randomNumber = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const handleDeleteCreator = async () => {
    try {
      await deleteCreator(currentUser.creator.id);
      toast.success('Creator deleted successfully');
    } catch (e) {
      console.error('Error deleting creator', e);
    }
  };

  const handleCopyLink = () => {
    if (!myCreator || !myCreator.persona) {
      return;
    }
    getCreatorLink(
      myCreator.persona.handle,
      () => {
        toast.success('Link copied to clipboard!');
      },
      (err) => {
        console.error('Failed to copy link: ', err);
        toast.error('Failed to copy link');
      },
    );
  };

  return (
    <Fragment>
      <div className="creator-top-navigation-container">
        <div className="creator-top-navigation-left"></div>
        <div className="creator-top-navigation-right">
          {myCreator && myCreator.persona && (
            <MuiButton
              variant="contained"
              color="secondary"
              onClick={handleCopyLink}
              sx={{ marginRight: '10px' }}
            >
              Copy Your Creator Link
            </MuiButton>
          )}
          {ENV !== 'production' && (
            <Button buttonType="primary" onClick={handleDeleteCreator}>
              Delete Creator
            </Button>
          )}
          <Button
            buttonType={isPublic ? 'creator_private' : 'creator_public'}
            onClick={isPublic ? handleMakePrivate : handleMakePublic}
          >
            {isPublic ? 'Private' : 'Public'}
          </Button>
          <Button buttonType="creator_save" onClick={handleSave}>
            Save
          </Button>
        </div>
      </div>
    </Fragment>
  );
};

export default CreatorTopNavigation;
