import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import theme from './theme';

import { CreatorsProvider } from './context/creator.context';
import { UserProvider } from './context/user.context';
import { ModalProvider } from './context/modal.context';
import { CtaProvider } from './context/cta.context';
import { CommandProvider } from './context/command.context';
import { ImageProvider } from './context/image.context';
import { MyCreatorProvider } from './context/my-creator.context';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <UserProvider>
          <CreatorsProvider>
            <ImageProvider>
              <ModalProvider>
                <CtaProvider>
                  <CommandProvider>
                    <App />
                  </CommandProvider>
                </CtaProvider>
              </ModalProvider>
            </ImageProvider>
          </CreatorsProvider>
        </UserProvider>
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
