import React, { useContext, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SwatchesPicker } from 'react-color';
import {
  faPlus,
  faPalette,
  faSwatchbook,
} from '@fortawesome/free-solid-svg-icons';

import CustomColorPicker from '../custom-color-picker/custom-color-picker.component';
import UploadImage from '../upload-image/upload-image.component';
import SampleNumber from '../sample-number/sample-number.component';

import { CreatorsContext } from '../../context/creator.context';

import './design-launcher.styles.css';
import { style_images } from '../../options/style-images.options';
import { roomTypes } from '../../options/room-types-styles.options';
import { getStyleImages } from '../../options/style-images.options';

const DesignLauncher = ({ onChange, getImage, onClose, command }) => {
  const { creators, currentCreator } = useContext(CreatorsContext);
  const [accentColor, setAccentColor] = useState('#ffffff');
  const [wallColor, setWallColor] = useState('#ffffff');
  const [colors, setColors] = useState({});
  const [isHover, setIsHover] = useState(false);
  const [indexHover, setIndexHover] = useState(null);
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedSpecs, setSelectedSpecs] = useState('styles');
  const [swatchesOrPicker, setSwatchesOrPicker] = useState('picker');
  const [selectedColorTo, setSelectedColorTo] = useState('accent');
  const [roomType, setRoomType] = useState('');

  const styleImages = getStyleImages();

  const DesignCountChange = (newDesignCount) => {
    onChange(newDesignCount, 'number_samples', command);
  };

  const onMouseEnter = (index) => {
    setIsHover(true);
    setIndexHover(index);
  };

  const onMouseLeave = () => {
    setIsHover(false);
    setIndexHover(null);
  };

  const onImageClick = (index, style) => {
    setSelectedImages((prevSelected) => {
      if (prevSelected.includes(index)) {
        return prevSelected.filter((i) => i !== index);
      } else {
        return [index, ...prevSelected].slice(0, 2);
      }
    });
  
    // Handle onChange after state update
    setTimeout(() => {
      setSelectedImages(current => {
        current.forEach((selectedIndex, i) => {
          const selectedStyle = filteredStyles[selectedIndex];
          if (selectedStyle) {
            onChange(selectedStyle.value, `design_style_${i + 1}`, command);
          }
        });
        return current;
      });
    }, 0);
  };

  const onSpecsClick = (specs) => {
    setSelectedSpecs(specs);
  };

  const handleRoomTypeChange = (e) => {
    setRoomType(e.target.value);
    onChange(
      e.target.options[e.target.selectedIndex].text,
      'type_of_room',
      command,
    );
  };

  const handleAnyColorChange = (color) => {
    const colorValue = color.hex;

    setAccentColor(colorValue);

    setColors((prevColors) => ({
      ...prevColors,
      [selectedColorTo]: colorValue,
    }));

    onChange(colorValue, `${selectedColorTo}_color`, command);
  };

  const switchTo = (type) => {
    setSwatchesOrPicker(type);
  };

  const filteredStyles = styleImages.filter(style => currentCreator?.design_style.preferred_styles.includes(style.value));

  return (
    <div className="design-launcher-form">
      <FontAwesomeIcon
        icon={faPlus}
        className={`design-launcher-close-button`}
        onClick={onClose}
      />
      <div className="design-launcher-form-row">
        {/* <UploadImage onChange={onChange} command='Design' getImage={getImage} /> */}
        {selectedSpecs === 'styles' && (
          <div className="style-selector">
            {filteredStyles.map(
            // {styleImages.map(
              (style, index) => {
                return style ? (
                  <div
                    className="style-selector-item-image"
                    onClick={() => onImageClick(index, style.name)}
                    onMouseEnter={() => onMouseEnter(index)}
                    onMouseLeave={onMouseLeave}
                    key={index}
                  >
                    <img
                      className="style-selector-item-image-img"
                      src={style.image.src}
                      alt={style.image.alt}
                      key={index}
                    />
                    <span
                      className={`style-selector-item-image-text ${isHover && indexHover === index ? 'hover' : ''} ${selectedImages[0] === index || selectedImages[1] === index ? 'selected' : ''}`}
                    >
                      {style.name}
                    </span>
                  </div>
                ) : null;
              },
            )}
          </div>
        )}
        {selectedSpecs === 'colors' && (
          <div className="color-selector-container">
            <div className="color-selector">
              <div className="swatches-or-picker-buttons-container">
                <div
                  className={`swatches-or-picker-button top-picker-type ${swatchesOrPicker === 'picker' ? 'selected' : ''}`}
                >
                  <FontAwesomeIcon
                    icon={faPalette}
                    onClick={() => switchTo('picker')}
                  />
                </div>
                <div
                  className={`swatches-or-picker-button ${swatchesOrPicker === 'swatches' ? 'selected' : ''}`}
                >
                  <FontAwesomeIcon
                    icon={faSwatchbook}
                    onClick={() => switchTo('swatches')}
                  />
                </div>
              </div>
              {swatchesOrPicker === 'picker' && (
                <div className="picker-selector">
                  <div className="color-selector-accent">
                    <span className="color-selector-label">
                      {selectedColorTo.toUpperCase()} Color
                    </span>
                    <CustomColorPicker
                      color={accentColor}
                      onChangeComplete={handleAnyColorChange}
                    />
                  </div>
                </div>
              )}
              {swatchesOrPicker === 'swatches' && (
                <div className="swatches-selector">
                  <div className="color-selector-accent">
                    <span className="color-selector-label">
                      {selectedColorTo.toUpperCase()} Color
                    </span>
                    <SwatchesPicker
                      width={220}
                      height={170}
                      color={accentColor}
                      onChangeComplete={handleAnyColorChange}
                    />
                  </div>
                </div>
              )}
              <div className="selected-colors-container">
                {Object.entries(colors).map(([colorType, colorValue]) => (
                  <div
                    className="selected-color"
                    key={colorType}
                    style={{ backgroundColor: colorValue }}
                  >
                    <span className="selected-color-label">
                      {colorType.toUpperCase()} Color
                    </span>
                  </div>
                ))}
              </div>
            </div>
            <div className="design-launcher-color-to-buttons">
              <button
                className={`design-launcher-color-to-button ${selectedColorTo === 'accent' ? 'selected' : ''}`}
                onClick={() => setSelectedColorTo('accent')}
              >
                Accent Color
              </button>
              <button
                className={`design-launcher-color-to-button ${selectedColorTo === 'walls' ? 'selected' : ''}`}
                onClick={() => setSelectedColorTo('walls')}
              >
                Walls Color
              </button>
              <button
                className={`design-launcher-color-to-button ${selectedColorTo === 'cushions' ? 'selected' : ''}`}
                onClick={() => setSelectedColorTo('cushions')}
              >
                Cushions Color
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="design-launcher-specs">
        <SampleNumber
          onChange={DesignCountChange}
          samples={true}
          withLablel={false}
        />
        <select value={roomType} onChange={handleRoomTypeChange}>
          <option value="" disabled>
            Choose type of room...
          </option>
          {roomTypes.map(
            (room) =>
              room.status === 'active' && (
                <option key={room.value} value={room.value}>
                  {room.label}
                </option>
              ),
          )}
        </select>
        <button
          className={`design-launcher-specs-button ${selectedSpecs === 'styles' ? 'selected' : ''}`}
          onClick={() => onSpecsClick('styles')}
        >
          #styles
        </button>
        <button
          className={`design-launcher-specs-button ${selectedSpecs === 'colors' ? 'selected' : ''}`}
          onClick={() => onSpecsClick('colors')}
        >
          #colors
        </button>
      </div>
    </div>
  );
};

export default DesignLauncher;
