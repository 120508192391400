import React, { Fragment, useContext, useState, useRef, useEffect } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { UserContext } from '../../../context/user.context';
import { signOut } from '../../../utils/api/auth';
import { useLocation, useNavigate } from 'react-router-dom';

import Button from '../../button/button.component';

import './home-navigation.styles.css';

import logo from '../../../assets/images/brand/goodhues_logo_black.png';

const Navigation = () => {
  const { currentUser } = useContext(UserContext);
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState('explorers');
  const [hoverPosition, setHoverPosition] = useState({ left: 0, width: 0 });
  const menuRef = useRef(null);
  const location = useLocation();
  const isAuthPage =
    location.pathname === '/sign-in' || location.pathname === '/sign-up';

    useEffect(() => {
      const selectedElement = menuRef.current?.querySelector('.selected');
      if (selectedElement) {
        const { offsetLeft, offsetWidth } = selectedElement;
        setHoverPosition({ left: offsetLeft, width: offsetWidth });
      }
  }, [selectedTab, navigate]);

  const handleButtonClick = (section) => {
    setSelectedTab(section);
    if (section === 'explorers') {
      navigate('/');
    } else if (section === 'creators') {
      navigate('/creators');
    }
  };

  return (
    <Fragment>
      <div>
        <div className="home-navigation-container">
          <Link
            className={`logo-container ${isAuthPage ? 'adjusted' : ''}`}
            to="/"
          >
            <img src={logo} className="main-logo" navigateto="/" />
          </Link>
          {!isAuthPage && (
            <div className="main-sections" ref={menuRef}>
              <div
                className={`main-section-button ${selectedTab === 'explorers' ? 'selected' : ''}`}
                onClick={() => handleButtonClick('explorers')}
              >
                Home
              </div>
              <div
                className={`main-section-button ${selectedTab === 'creators' ? 'selected' : ''}`}
                onClick={() => handleButtonClick('creators')}
              >
                Creators
              </div>
              <div
                className="main-section-button-hover-background"
                style={{
                  left: `${hoverPosition.left}px`,
                  width: `${hoverPosition.width}px`,
                }}
              />
            </div>
          )}
          {!isAuthPage && (
            <div className="login-signup-container">
              {!currentUser ? (
                <div className="logged-out">
                  <Button
                    buttonType="login"
                    type="button"
                    navigateTo="/sign-in"
                  >
                    Log in
                  </Button>
                  <Button
                    buttonType="signup"
                    type="button"
                    navigateTo="/sign-up"
                  >
                    Sign up
                  </Button>
                </div>
              ) : (
                <div className="logged-in">
                  <Button
                    buttonType="login"
                    type="button"
                    navigateTo="/assistant"
                  >
                    Assistant
                  </Button>
                  <span className="sign-out-link" onClick={signOut}>
                    Sign Out
                  </span>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <Outlet />
    </Fragment>
  );
};

export default Navigation;
