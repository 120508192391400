import React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';

import './user-settings-button.styles.css';

const formatDisplayName = (displayName) => {
  if (!displayName) return '';

  const nameParts = displayName.split(' ');
  if (nameParts.length === 1) {
    return displayName;
  }

  const firstName = nameParts[0];
  const lastNameInitial = nameParts[1].charAt(0);
  const formattedName = `${firstName} ${lastNameInitial}.`;

  if (formattedName.length > 14) {
    return `${firstName.charAt(0)}. ${lastNameInitial}.`;
  }

  return formattedName;
};

const UserSettingsButton = ({ user, onClick, collapsed }) => {
  const s3_url = process.env.REACT_APP_S3_URL;
  const avatar = s3_url + '/avatars/generic_avatar.webp';

  const handleClick = (event) => {
    if (onClick) {
      onClick(event);
    }
  };

  const formattedName = user ? formatDisplayName(user.display_name) : '';

  const buttonStyling = {
    minWidth: '0',
  };

  if (collapsed) {
    buttonStyling.width = '50px';
    buttonStyling.height = '50px';
  }

  return (
    <Button
      variant="outlined"
      onClick={handleClick}
      fullWidth
      sx={buttonStyling}
    >
      {user && (
        <>
          <Avatar
            src={user.photo_url ? user.photo_url : avatar}
            sx={{ width: 24, height: 24 }}
          />
          {!collapsed && (
            <div className="user-display-name">
              {user.display_name ? formattedName : 'User settings'}
            </div>
          )}
        </>
      )}
    </Button>
  );
};

export default UserSettingsButton;
