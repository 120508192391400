import React from 'react';
import { Link } from 'react-router-dom';

import Button from '../../components/button/button.component';

import './not-found.styles.css';

const NotFound = () => {
  return (
    <div className="not-found-container">
      <div className="not-found-content">
        <p>
          Oops! Just like this room, the page you're looking for doesn't exist.
        </p>
        <div className="not-found-button">
          <Button navigateTo="/project" buttonType="secondary">
            Go back to Home
          </Button>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
