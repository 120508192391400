// Libraries
import React, { useState, useContext, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus,
  faMicrophone,
  faPaperPlane,
  faWandMagicSparkles,
} from '@fortawesome/free-solid-svg-icons';

// Context
import { ProjectContext } from '../../context/project.context';
import { useCtaContext } from '../../context/cta.context';
import { useCommandContext } from '../../context/command.context';
import { CreatorsContext } from '../../context/creator.context';
import { useImage } from '../../context/image.context';

// Components
import CommandButton from '../commands/commands.component';
import CommandCategory from '../command-categories/command-categories.component';
import VisualizeForm from '../visualize/visualize.component';
import DesignLauncher from '../design-launcher/design-launcher.component';
import SmartSearch from '../smart-search/smart-search.component';
import Moodboard from '../moodboard/moodboard.component';
import StyleTransfer from '../style-transfer/style-transfer.component';
import MiniSpinner from '../mini-spinner/mini-spinner.component';
import Popover from '../popover/popover.component';
import Spinner from '../spinner/spinner.component';

// Utils
import { getCommandsByCategory } from '../../utils/command-list.util';
import { enhancePrompt } from '../../utils/api/projects';
import { translateHex } from '../../utils/openai/hex-translator.util';

// Styles
import './chat-input.styles.css';

const initialDesignParameters = {
  type_of_room: '',
  design_style_1: '',
  design_style_2: '',
  number_samples: 1,
  accent_color: '',
  wall_color: '',
  color_mood: '',
  base_image: '',
  reference_image: '',
  budget: '',
  room_size: '',
  other_info: '',
};

const ChatInputGroup = ({
  onSendMessage,
  loading,
  loadingMessage,
  commandBack,
  openForm = false,
  setOpenForm,
}) => {
  const [message, setMessage] = useState('');
  const [selectedCommand, setSelectedCommand] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [commandList, setCommandList] = useState(() =>
    getCommandsByCategory('all'),
  );
  const [isVisible, setIsVisible] = useState(false);
  const { currentProject, setCurrentProject, projects, setProjects } =
    useContext(ProjectContext);
  const { activeCta, setActiveCta } = useCtaContext();
  const { currentCreator } = useContext(CreatorsContext);
  const { activeCommand, setActiveCommand } = useCommandContext();
  const [designParameters, setDesignParameters] = useState(
    initialDesignParameters,
  );
  const [imageSrc, setImageSrc] = useState(null);
  const [imageBlob, setImageBlob] = useState(null);
  const [styleImageSrc, setStyleImageSrc] = useState(null);
  const [styleImageBlob, setStyleImageBlob] = useState(null);
  const [numberSamples, setNumberSamples] = useState(1);
  const [returnedCommand, setReturnedCommand] = useState(commandBack);
  const textareaRef = useRef(null);
  const [filteredCommands, setFilteredCommands] = useState([]);
  const [showAllCommands, setShowAllCommands] = useState(false);
  const [openCommandForm, setOpenCommandForm] = useState(openForm);
  const [isLoading, setIsLoading] = useState(false);

  const { uploadedImage, handleImageUpload, clearUploadedImage } = useImage();

  useEffect(() => {
    if (uploadedImage && uploadedImage.file) {
      displayImage(uploadedImage.file);
    }
  }, [uploadedImage, message]);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
    if (!isVisible) {
      if (!currentProject) {
        setActiveCta('ctaActive');
      }
    }
  }, [message, isVisible, selectedCommand]);

  useEffect(() => {
    if (openForm) {
      toggleCommands();
      setSelectedCommand(activeCommand.toLowerCase());
      setOpenForm(false);
    }
  }, [openForm]);

  useEffect(() => {
    if (activeCommand) {
      setMessage(`/${activeCommand}: `);
      setIsVisible(true);
      if (textareaRef.current) {
        textareaRef.current.focus();
      }
    }
  }, [activeCommand]);

  useEffect(() => {
    if (commandBack) {
      setReturnedCommand(commandBack);
      setMessage('');
    } else {
      setReturnedCommand(null);
    }
  }, [commandBack]);

  const handleSend = async () => {
    setActiveCta('');
    setActiveCommand(null);
    clearUploadedImage();
    if (message.trim()) {
      const trimmedMessage = message.trim();
      const now = new Date();
      const currentTime = now.toTimeString().split(' ')[0];
      const today = now.toISOString().split('T')[0];
      let workingProject;

      // Send the message
      const newUserMessage = {
        sender: 'user',
        text: trimmedMessage,
        imageUrl: imageSrc,
        imageData: imageBlob,
        styleImageUrl: styleImageSrc,
        styleImageData: styleImageBlob,
        samples: numberSamples,
      };
      console.log('newUserMessage', newUserMessage);
      console.log('uploadedImage', uploadedImage);
      console.log('imageSrc', imageSrc);
      console.log('imageBlob', imageBlob);
      console.log('styleImageSrc', styleImageSrc);
      console.log('styleImageBlob', styleImageBlob);
      console.log('numberSamples', numberSamples);

      onSendMessage(newUserMessage, workingProject);

      setMessage('');
      setSelectedCommand('');
      setImageSrc('');
      setStyleImageSrc('');
      setStyleImageBlob('');
      setImageBlob('');
      setNumberSamples(1);
      setDesignParameters(initialDesignParameters);
      setIsVisible(false);
      setReturnedCommand(null);
      setActiveCta('');
    }
  };

  const handleEnhancePrompt = async () => {
    if (message.trim() !== '') {
      setActiveCta('');
      setIsLoading(true);
      const enhancedPrompt = await enhancePrompt(message);
      if (selectedCommand !== '') {
        setMessage(`/${selectedCommand}: ${enhancedPrompt}`);
      } else {
        setMessage(enhancedPrompt);
      }
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const handleEnter = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSend();
    }
  };

  const handleCommandClick = (command) => {
    setSelectedCommand(command.toLowerCase());
    setActiveCta('');
    if (message.trim() === '') {
      setMessage(`/${command.toLowerCase()}`);
    } else {
      setMessage(
        `/${command.toLowerCase()}: ${message.slice(message.indexOf(':') + 1).trim()}`,
      );
    }
    setShowAllCommands(false);
    if (textareaRef.current) {
      textareaRef.current.focus();
    }
  };

  const handleChange = (e) => {
    const inputValue = e.target.value.toLowerCase();
    setSelectedCategory('all');

    if (inputValue.startsWith('/')) {
      setIsVisible(true);
      setShowAllCommands(false);
      const commandText = inputValue.slice(1); // Remove the '/' at the beginning
      const categoryCommands = getCommandsByCategory('all');
      const matchingCommands = categoryCommands.filter((command) =>
        command.toLowerCase().startsWith(commandText),
      );
      setFilteredCommands(matchingCommands);

      if (
        matchingCommands.length === 1 &&
        matchingCommands[0].toLowerCase() === commandText
      ) {
        setSelectedCommand(matchingCommands[0].toLowerCase());
        setActiveCta('');
      } else {
        setSelectedCommand('');
      }
    } else {
      const categoryCommands = getCommandsByCategory('all');
      setFilteredCommands(categoryCommands);
      setSelectedCommand('');
    }

    setMessage(e.target.value);
  };

  const handleCommandInput = (input, input_type, command) => {
    if (input_type.endsWith('_color') || input_type in designParameters) {
      setDesignParameters((prevState) => {
        const newDesignParameters = {
          ...prevState,
          [input_type]: input,
        };

        const parameterTexts = {
          type_of_room: (value) => `${value},`,
          color_mood: (value) => `${value} color mood,`,
          design_style_1: (value) => `${value} style,`,
          design_style_2: (value) => `mixed with ${value} style,`,
          number_samples: (value) => ``,
          budget: (value) => `$${value} budget,`,
          room_size: (value) => `${value} sq feet,`,
          other_info: (value) => value,
        };

        const messageText = Object.entries(newDesignParameters)
          .filter(([key, value]) => value !== '' && value !== null)
          .map(([key, value]) => {
            if (key.endsWith('_color')) {
              const colorType = key.replace('_color', '');
              return `${value} ${colorType} color,`;
            }
            return parameterTexts[key]
              ? parameterTexts[key](value)
              : `${value} ${key.replace('_', ' ')},`;
          })
          .join(' ')
          .trim();

        setMessage(`/${command}: ${messageText}`);
        setNumberSamples(newDesignParameters.number_samples);

        return newDesignParameters;
      });
    }
  };

  const handleCloseForm = () => {
    setSelectedCommand('');
    setActiveCommand('');
    setDesignParameters(initialDesignParameters);
    setIsVisible(false);
  };

  const toggleCommands = () => {
    setShowAllCommands(!showAllCommands);
    if (!showAllCommands) {
      const categoryCommands = getCommandsByCategory(selectedCategory);
      setFilteredCommands(categoryCommands);
    }
    setIsVisible(!isVisible);
    setSelectedCommand('');
    setActiveCommand('');
    setDesignParameters(initialDesignParameters);
  };

  const displayImage = (file) => {
    setImageBlob(file);
    const reader = new FileReader();
    reader.onloadend = () => {
      setImageSrc(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const displayStyleImage = (file) => {
    setStyleImageBlob(file);
    const reader = new FileReader();
    reader.onloadend = () => {
      setStyleImageSrc(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category.toLowerCase());
    const categoryCommands = getCommandsByCategory(category);
    setCommandList(categoryCommands);
    setFilteredCommands(categoryCommands);
    setShowAllCommands(true);
  };

  return (
    <div className="user-input-container">
      <div className={`forms-container ${isVisible ? 'visible' : ''}`}>
        {selectedCommand === 'design' && (
          <DesignLauncher
            onChange={handleCommandInput}
            getImage={displayImage}
            onClose={handleCloseForm}
            command="design"
          />
        )}
        {selectedCommand === 'smartsearch' && (
          <SmartSearch
            onChange={handleCommandInput}
            onClose={handleCloseForm}
          />
        )}
        {selectedCommand === 'moodboard' && (
          <Moodboard
            onChange={handleCommandInput}
            getImage={displayImage}
            onClose={handleCloseForm}
          />
        )}
        {selectedCommand === 'styletransfer' && (
          <StyleTransfer
            onChange={handleCommandInput}
            getBaseImage={displayImage}
            getStyleImage={displayStyleImage}
            onClose={handleCloseForm}
          />
        )}
      </div>
      <div
        className={`commands-group ${isVisible || activeCommand ? 'visible' : ''}`}
      >
        <div className="command-category-container">
          <CommandCategory
            category="All"
            onClick={() => handleCategoryClick('all')}
            active={selectedCategory === 'all'}
          />
          <CommandCategory
            category="Design"
            onClick={() => handleCategoryClick('design')}
            active={selectedCategory === 'design'}
          />
          <CommandCategory
            category="Paint"
            onClick={() => handleCategoryClick('paint')}
            active={selectedCategory === 'paint'}
          />
          <CommandCategory
            category="Material"
            onClick={() => handleCategoryClick('material')}
            active={selectedCategory === 'material'}
          />
          <CommandCategory
            category="Lighting"
            onClick={() => handleCategoryClick('lighting')}
            active={selectedCategory === 'lighting'}
          />
        </div>
        <div className="commands-container">
          {(showAllCommands ? commandList : filteredCommands).map(
            (command, idx) => (
              <CommandButton
                key={idx}
                command={command}
                selected={
                  selectedCommand === command.toLowerCase() ||
                  activeCommand === command
                }
                onClick={() => handleCommandClick(command)}
              />
            ),
          )}
        </div>
      </div>

      <div className="input-group">
        <button
          className={`input-plus-button ${loading ? 'hidden' : ''} ${isVisible ? 'visible' : ''}`}
          onClick={toggleCommands}
        >
          <FontAwesomeIcon icon={faPlus} className={`add-button`} />
        </button>
        {/* {loading && <Spinner />} */}
        <textarea
          className="input-group-field"
          placeholder={`${loading ? loadingMessage : `Message ${currentCreator?.persona?.handle || 'GoodHues'}... Start your message with "/" for a command...`}`}
          value={message}
          onChange={handleChange}
          onKeyDown={handleEnter}
          ref={textareaRef}
          rows="1"
          disabled={isLoading}
        />
        {isLoading ? (
          <MiniSpinner />
        ) : (
          <Popover text="Enhance Prompt">
            <button className="input-group-button">
              <FontAwesomeIcon
                icon={faWandMagicSparkles}
                className="voice-button"
                onClick={handleEnhancePrompt}
              />
            </button>
          </Popover>
        )}
        <button
          className="input-group-button"
          onClick={handleSend}
          disabled={isLoading}
        >
          <FontAwesomeIcon
            icon={faPaperPlane}
            className={`send-button ${message.trim() ? 'active' : 'inactive'}`}
          />
        </button>
      </div>
    </div>
  );
};

export default ChatInputGroup;
