import React, { useState, useEffect, useContext } from 'react';
import { useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useCart } from '../../context/cart.context';

import { createCart, addToCart } from '../../utils/api/shopify';
import { addProductToStore } from '../../utils/api/creators';
import { getImageOrientation } from '../../utils/image-orientation.util';
import { performProductSearch } from '../../utils/google-vision.util';
import { shopImage } from '../../utils/api/shop';

import ProductSearchGrid from '../product-search-grid/product-search-grid.component';

import './image-modal.styles.css';

const ImageModal = ({ imageUrl, initialIndex, imageArray }) => {
  const { addItemToCart, isVariantInCart, removeItemFromCart } = useCart();
  const [isOpen, setIsOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(initialIndex);
  const [imageClass, setImageClass] = useState('');
  const [imageWidth, setImageWidth] = useState(0);
  const [products, setProducts] = useState([]);
  const imageRef = useRef(null);
  const [cart, setCart] = useState(null);

  const openModal = async () => {
    setIsOpen(true);
    try {
      console.log('shopping image', imageUrl);
      const shopify_products = await shopImage(imageUrl);
      console.log('products', shopify_products);
      setProducts(shopify_products);
    } catch (error) {
      console.error('Error performing product search:', error);
    }
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleImageLoad = (e) => {
    const img = e.target;
    const orientationClass = getImageOrientation(img.width, img.height);
    setImageClass(orientationClass);
    if (imageRef.current) {
      setImageWidth(imageRef.current.clientWidth);
    }
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % imageArray.length);
  };

  const handlePreviousImage = () => {
    setCurrentImageIndex(
      (prevIndex) => (prevIndex - 1 + imageArray.length) % imageArray.length,
    );
  };

  const handleKeyDown = (event) => {
    if (!isOpen) return;
    if (event.key === 'ArrowRight' || event.key === ' ') {
      handleNextImage();
    } else if (event.key === 'ArrowLeft') {
      handlePreviousImage();
    }
  };

  useEffect(() => {
    if (imageWidth > 0) {
      document.documentElement.style.setProperty(
        '--image-width',
        `${imageWidth}px`,
      );
    }
  }, [imageWidth]);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen]);

  useEffect(() => {
    initializeCart();
  }, []);

  const initializeCart = async () => {
    try {
      const newCart = await createCart();
      setCart(newCart);
    } catch (error) {
      console.error('Error creating cart:', error);
      toast.error('Failed to initialize cart. Please try again.');
    }
  };

  const handleAddToCart = async (variantId) => {
    try {
      await addItemToCart(variantId);
      toast.success('Product added to cart successfully!');
    } catch (error) {
      console.error('Error adding to cart:', error);
      toast.error('Failed to add product to cart. Please try again.');
    }
  };

  const handleRemoveFromCart = async (variantId) => {
    try {
      await removeItemFromCart(variantId);
      toast.success('Product removed from cart successfully!');
    } catch (error) {
      console.error('Error removing from cart:', error);
      toast.error('Failed to remove product from cart. Please try again.');
    }
  };

  const renderActionButton = (variant) => {
    const variantId = variant.admin_graphql_api_id;
    const isInCart = isVariantInCart(variantId);
  
    return (
      <button 
        className={`buy-now ${isInCart ? 'in-cart' : ''}`} 
        onClick={(e) => {
          e.stopPropagation();
          isInCart ? handleRemoveFromCart(variantId) : handleAddToCart(variantId);
        }}
      >
        {isInCart ? 'Remove' : 'Add to Cart'}
      </button>
    );
  };

  return (
    <div className='image-modal'>
      <div className="thumbnail-container">
        <img
          src={imageUrl}
          alt="Thumbnail"
          onClick={openModal}
          className="thumbnail"
        />
        <button className="shop-image" onClick={openModal}>
          Shop Image
        </button>
      </div>
      {isOpen && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="image-modal-content" onClick={(e) => e.stopPropagation()}>
            {/* <span className={`close-button ${imageClass}`} onClick={closeModal}>&times;</span> */}
            <FontAwesomeIcon
              icon={faChevronLeft}
              className="chevron left-chevron"
              onClick={handlePreviousImage}
            />
            <img
              src={imageArray[currentImageIndex]}
              alt="Full Size"
              className={`modal-image ${imageClass}`}
              onLoad={handleImageLoad}
              ref={imageRef}
            />
            <FontAwesomeIcon
              icon={faChevronRight}
              className="chevron right-chevron"
              onClick={handleNextImage}
            />
          </div>
          <div onClick={(e) => e.stopPropagation()}>  
            <ProductSearchGrid products={products} renderActionButton={renderActionButton} />
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageModal;
