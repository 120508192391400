import { folders } from '../options/folders.options';

export const createNewFolder = (name) => {
  const newFolder = {
    id: folders.length + 1,
    text: name,
    url: '',
    type: 'Projects',
    contents: [],
  };

  return newFolder;
};
