import React, { createContext, useEffect, useState } from 'react';
import { useUserContext } from './user.context';
import { getCreators } from '../utils/api/creators';
import { DEFAULT_AVATAR } from './image.context';

export const CreatorsContext = createContext({
  currentCreator: null,
  setCurrentCreator: (creator) => null,
  getCreatorProfileImage: (creatorId) => null,
  creators: null,
});

export const CreatorsProvider = ({ children }) => {
  const { currentUser } = useUserContext();
  const [currentCreator, setCurrentCreator] = useState(null);
  const [creators, setCreators] = useState([]);

  useEffect(() => {
    const fetchCreators = async () => {
      try {
        const { creators } = await getCreators();
        setCreators(creators);
      } catch (e) {}
    };

    if (currentUser) {
      fetchCreators();
    }
  }, [currentUser]);

  const getCreatorProfileImage = (creatorId) => {
    return (
      creators.find((creator) => creator.id === creatorId)?.profile_image_url ||
      DEFAULT_AVATAR
    );
  };

  return (
    <CreatorsContext.Provider
      value={{
        currentCreator,
        setCurrentCreator,
        creators,
        getCreatorProfileImage,
      }}
    >
      {children}
    </CreatorsContext.Provider>
  );
};
