import React, { useState, useEffect, useRef } from 'react';
import Grid from '@mui/material/Grid2';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import Popper from '@mui/material/Popper';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import { Outlet } from 'react-router-dom';
import logo_white from '../../../assets/images/brand/logo_white.svg';
import theme from '../../../theme';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(10)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(11)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2),
  justifyContent: 'space-between',
  flexGrow: 0,
}));

const DrawerFooter = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2),
  justifyContent: 'center',
  marginTop: 'auto',
  position: 'relative',
  flexGrow: 0,
}));

const DrawerBody = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  overflow: 'hidden',
}));

const CollapseButtonContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0 5px',
  backgroundColor: '#fcf8f4',
  position: 'absolute',
  top: 0,
  left: '100%',
  bottom: 0,
}));

export default function DrawerWrapper({
  isCollapsed,
  toggleCollapse,
  popperOpen,
  renderDrawerFooter,
  renderDrawerBody,
  renderPopperContent,
  renderDrawerHeader,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const anchorRef = useRef(null);

  useEffect(() => {
    setAnchorEl(anchorRef.current);
  }, []);

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Drawer
        open={!isCollapsed} // Use the isCollapsed state to control the drawer
        variant="permanent"
        anchor="left"
        sx={{
          '& .MuiAvatar-root': {
            position: 'absolute',
            left: '8px',
            width: '32px',
            height: '32px',
          },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
          },
          '& .MuiButton-outlined': {
            backgroundColor:
              theme.components?.MuiDrawer?.styleOverrides?.paper || 'black',
            padding: '12px',
            borderRadius: '32px',
            borderColor: 'gray',
            minWidth: '0px',
          },
          '& .MuiButton-text': {
            padding: '16px 0px',
          },
          '& .link-button-text-wrapper': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: isCollapsed ? 'center' : 'flex-start',
            width: '140px',
          },
          '& .svg-inline--fa': {
            marginRight: isCollapsed ? '0px' : '16px',
            color: 'white',
          },
          '& .MuiPaper-root': {
            overflow: 'visible',
          },
        }}
      >
        <Popper
          sx={{
            '& .MuiPaper-root': {
              color: 'white',
              background:
                theme.components?.MuiDrawer?.styleOverrides?.paper
                  ?.backgroundColor || 'black',
              margin: '16px',
            },
          }}
          open={popperOpen}
          anchorEl={anchorEl}
          placement={'right-end'}
          transition
          onClick={() => toggleCollapse()}
        >
          {({ TransitionProps }) => (
            <Paper
              sx={{
                padding: '8px',
                borderRadius: '16px',
                '& .MuiDivider-root': {
                  borderColor: 'gray',
                },
                '& .MuiButton-root': {
                  padding: '20px',
                },
                '& .svg-inline--fa': {
                  marginRight: '16px',
                },
              }}
            >
              <Fade {...TransitionProps} timeout={150}>
                {renderPopperContent()}
              </Fade>
            </Paper>
          )}
        </Popper>
        <DrawerHeader>
          <Link style={{ padding: '16px' }} to="/project">
            <img src={logo_white} alt="Logo" />
          </Link>
          {renderDrawerHeader && renderDrawerHeader()}
        </DrawerHeader>
        <DrawerBody>{renderDrawerBody()}</DrawerBody>
        <DrawerFooter ref={anchorRef}>{renderDrawerFooter()}</DrawerFooter>
        <CollapseButtonContainer>
          <button className="collapse-button" onClick={toggleCollapse}></button>
        </CollapseButtonContainer>
      </Drawer>
      <Grid container flexGrow={1} style={{ position: 'relative' }}>
        <Outlet />
      </Grid>
    </Box>
  );
}
