import './subscribe-form.styles.css';

import Button from '../button/button.component';

const Subscribe = () => {
  return (
    <div className="subscribe-form-container">
      <h4>Subscribe</h4>
      <p>
        Join our mailing list and be the first to know about our latest updates.
      </p>
      <label>Email address</label>
      <input type="email" placeholder="example@company.com" />
      <div className="footer-button">
        <Button buttonType="secondary" type="submit">
          Subscribe
        </Button>
      </div>
    </div>
  );
};

export default Subscribe;
