import React, { useState, useEffect } from 'react';
import './privacy-policy.styles.css';

const PrivacyPolicy = () => {
  const [activeSection, setActiveSection] = useState('');

  useEffect(() => {
    // Function to handle scroll and update active section
    const handleScroll = () => {
      const sections = document.querySelectorAll('.privacy-policy-section-content');
      const scrollPosition = window.scrollY + 100; // Offset for better UX

      sections.forEach((section) => {
        const top = section.offsetTop;
        const height = section.offsetHeight;
        const id = section.getAttribute('id');

        if (scrollPosition >= top && scrollPosition < top + height) {
          setActiveSection(id);
        }
      });
    };

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Initial check

    // Cleanup
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  const sections = [
    {
      id: 'information-collection',
      title: '1. What Information Do We Collect?',
      content: (
        <>
          <h3>Personal information you disclose to us</h3>
          <p><strong>In Short: </strong>We collect personal information that you provide to us.</p>
          
          <p>We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.</p>
          
          <p>Personal Information Provided by You. The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include the following:</p>
          <ul>
            <li>names</li>
            <li>email addresses</li>
            <li>usernames</li>
            <li>passwords</li>
            <li>contact preferences</li>
            <li>billing addresses</li>
            <li>debit/credit card numbers</li>
            <li>phone numbers</li>
            <li>contact or authentication data</li>
            <li>mailing addresses</li>
          </ul>

          <h3>Sensitive Information</h3>
          <p>When necessary, with your consent or as otherwise permitted by applicable law, we process the following categories of sensitive information:</p>
          <ul>
            <li>financial data</li>
          </ul>

          <h3>Payment Data</h3>
          <p>We may collect data necessary to process your payment if you make purchases, such as your payment instrument number, and the security code associated with your payment instrument. All payment data is stored by Stripe. You may find their privacy notice link(s) here: <a href="https://stripe.com/privacy">https://stripe.com/privacy</a>.</p>

          <h3>Social Media Login Data</h3>
          <p>We may provide you with the option to register with us using your existing social media account details, like your Facebook, Twitter, or other social media account. If you choose to register in this way, we will collect the information described in the section called "HOW DO WE HANDLE YOUR SOCIAL LOGINS?" below.</p>

          <h3>Information automatically collected</h3>
          <p><strong>In Short: </strong>Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics — is collected automatically when you visit our Services.</p>

          <p>We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as:</p>
          <ul>
            <li>Log and Usage Data: Service-related, diagnostic, usage, and performance information our servers automatically collect when you access or use our Services.</li>
            <li>Device Data: Information about your computer, phone, tablet, or other device you use to access the Services.</li>
            <li>Location Data: Information about your device's location, which can be either precise or imprecise.</li>
          </ul>
        </>
      )
    },
    {
      id: 'information-processing',
      title: '2. How Do We Process Your Information?',
      content: (
        <>
          <p><strong>In Short: </strong>We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.</p>

          <p>We process your personal information for a variety of reasons, depending on how you interact with our Services, including:</p>
          <ul>
            <li>To facilitate account creation and authentication and otherwise manage user accounts.</li>
            <li>To request feedback.</li>
            <li>To send you marketing and promotional communications.</li>
            <li>To deliver targeted advertising to you.</li>
            <li>To protect our Services.</li>
            <li>To identify usage trends.</li>
            <li>To determine the effectiveness of our marketing and promotional campaigns.</li>
            <li>To save or protect an individual's vital interest.</li>
          </ul>
        </>
      )
    },
    {
      id: 'legal-bases',
      title: '3. What Legal Bases Do We Rely on to Process Your Information?',
      content: (
        <>
          <p><em>In Short: </em>We only process your personal information when we believe it is necessary and we have a valid legal reason (i.e., legal basis) to do so under applicable law, like with your consent, to comply with laws, to provide you with services to enter into or fulfill our contractual obligations, to protect your rights, or to fulfill our legitimate business interests.</p>

          <h3>If you are located in the EU or UK, this section applies to you.</h3>
          <p>The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the valid legal bases we rely on in order to process your personal information. As such, we may rely on the following legal bases to process your personal information:</p>
          <ul>
            <li><strong>Consent.</strong> We may process your information if you have given us permission.</li>
            <li><strong>Legitimate Interests.</strong> We may process your information when we believe it is reasonably necessary to achieve our legitimate business interests.</li>
            <li><strong>Legal Obligations.</strong> We may process your information where we believe it is necessary for compliance with our legal obligations.</li>
            <li><strong>Vital Interests.</strong> We may process your information where we believe it is necessary to protect your vital interests or the vital interests of a third party.</li>
          </ul>

          <h3>If you are located in Canada, this section applies to you.</h3>
          <p>We may process your information if you have given us specific permission (i.e., express consent) to use your personal information for a specific purpose, or in situations where your permission can be inferred (i.e., implied consent).</p>
        </>
      )
    },
    {
      id: 'information-sharing',
      title: '4. When and With Whom Do We Share Your Personal Information?',
      content: (
        <>
          <p><strong>In Short: </strong>We may share information in specific situations described in this section and/or with the following categories of third parties.</p>
          <p>Vendors, Consultants, and Other Third-Party Service Providers. We may share your data with third-party vendors, service providers, contractors, or agents ("third parties") who perform services for us or on our behalf and require access to such information to do that work. The categories of third parties we may share personal information with are as follows:</p>
          <ul>
            <li>Data Analytics Services</li>
            <li>Payment Processors</li>
          </ul>
          <p>We also may need to share your personal information in the following situations:</p>
          <ul>
            <li><strong>Business Transfers.</strong> We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</li>
          </ul>
        </>
      )
    },
    {
      id: 'third-party-websites',
      title: '5. What Is Our Stance on Third-Party Websites?',
      content: (
            <>
              <p><strong>In Short: </strong>We are not responsible for the safety of any information that you share with third parties that we may link to or who advertise on our Services, but are not affiliated with, our Services.</p>
              <p>The Services may link to third-party websites, online services, or mobile applications and/or contain advertisements from third parties that are not affiliated with us and which may link to other websites, services, or applications. Accordingly, we do not make any guarantee regarding any such third parties, and we will not be liable for any loss or damage caused by the use of such third-party websites, services, or applications.</p>
            </>
          )
    },
    {
      id: 'cookies',
      title: '6. Do We Use Cookies and Other Tracking Technologies?',
      content: (
        <>
          <p><strong>In Short: </strong>We may use cookies and other tracking technologies to collect and store your information.</p>

          <p>We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Notice.</p>
        </>
      )
    },
    {
      id: 'social-logins',
      title: '7. How Do We Handle Your Social Logins?',
      content: (
        <>
          <p><strong>In Short: </strong>If you choose to register or log in to our Services using a social media account, we may have access to certain information about you.</p>

          <p>Our Services offer you the ability to register and log in using your third-party social media account details (like your Facebook or Twitter logins). Where you choose to do this, we will receive certain profile information about you from your social media provider. The profile information we receive may vary depending on the social media provider concerned, but will often include your name, email address, friends list, and profile picture, as well as other information you choose to make public on such a social media platform.</p>

          <p>We will use the information we receive only for the purposes that are described in this privacy notice or that are otherwise made clear to you on the relevant Services. Please note that we do not control, and are not responsible for, other uses of your personal information by your third-party social media provider. We recommend that you review their privacy notice to understand how they collect, use, and share your personal information, and how you can set your privacy preferences on their sites and apps.</p>
        </>
      )
    },
    {
      id: 'information-retention',
      title: '8. How Long Do We Keep Your Information?',
      content: (
        <>
          <p><strong>In Short: </strong>We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice unless otherwise required by law.</p>

          <p>We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements).</p>
        </>
      )
    },
    {
      id: 'information-security',
      title: '9. How Do We Keep Your Information Safe?',
      content: (
        <>
          <p><strong>In Short: </strong>We aim to protect your personal information through a system of organizational and technical security measures.</p>

          <p>We have implemented appropriate and reasonable technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment.</p>
        </>
      )
    },
    {
      id: 'minors',
      title: '10. Do We Collect Information From Minors?',
      content: (
        <>
          <p><strong>In Short: </strong>We do not knowingly collect data from or market to children under 18 years of age.</p>

          <p>We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent's use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under age 18, please contact us at compliance@goodhues.ai.</p>
        </>
      )
    },
    {
      id: 'privacy-rights',
      title: '11. What Are Your Privacy Rights?',
      content: (
        <>
          <p><strong>In Short: </strong>You may review, change, or terminate your account at any time.</p>

          <p>If you are located in the EEA or UK and you believe we are unlawfully processing your personal information, you also have the right to complain to your local data protection supervisory authority. You can find their contact details here: <a href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm">https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm</a>.</p>

          <p>If you are located in Switzerland, the contact details for the data protection authorities are available here: <a href="https://www.edoeb.admin.ch/edoeb/en/home.html">https://www.edoeb.admin.ch/edoeb/en/home.html</a>.</p>

          <h3>Withdrawing your consent</h3>
          <p>If we are relying on your consent to process your personal information, which may be express and/or implied consent depending on the applicable law, you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us using the contact details provided in the section "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?" below.</p>

          <p>However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, when applicable law allows, will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.</p>
        </>
      )
    },
    {
      id: 'do-not-track',
      title: '12. Controls for Do-Not-Track Features',
      content: (
        <>
          <p>Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.</p>
        </>
      )
    },
    {
      id: 'california-rights',
      title: '13. Do California Residents Have Specific Privacy Rights?',
      content: (
        <>
          <p><strong>In Short: </strong>Yes, if you are a resident of California, you are granted specific rights regarding access to your personal information.</p>

          <p>California Civil Code Section 1798.83, also known as the "Shine The Light" law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below.</p>

          <p>If you are under 18 years of age, reside in California, and have a registered account with Services, you have the right to request removal of unwanted data that you publicly post on the Services. To request removal of such data, please contact us using the contact information provided below and include the email address associated with your account and a statement that you reside in California. We will make sure the data is not publicly displayed on the Services, but please be aware that the data may not be completely or comprehensively removed from all our systems (e.g., backups, etc.).</p>
        </>
      )
    },
    {
      id: 'virginia-rights',
      title: '14. Do Virginia Residents Have Specific Privacy Rights?',
      content: (
        <>
          <p><strong>In Short: </strong>Yes, if you are a resident of Virginia, you may be granted specific rights regarding access to and use of your personal information.</p>

          <h3>Virginia CDPA Privacy Notice</h3>
          <p>Under the Virginia Consumer Data Protection Act (CDPA):</p>

          <p>"Consumer" means a natural person who is a resident of the Commonwealth acting only in an individual or household context. It does not include a natural person acting in a commercial or employment context.</p>

          <p>"Personal data" means any information that is linked or reasonably linkable to an identified or identifiable natural person. "Personal data" does not include de-identified data or publicly available information.</p>

          <p>"Sale of personal data" means the exchange of personal data for monetary consideration.</p>

          <p>If you are a Virginia resident, you may have the following rights:</p>
          <ul>
            <li>Right to be informed whether or not we are processing your personal data</li>
            <li>Right to access your personal data</li>
            <li>Right to correct inaccuracies in your personal data</li>
            <li>Right to request deletion of your personal data</li>
            <li>Right to obtain a copy of the personal data you previously shared with us</li>
            <li>Right to opt out of the processing of your personal data for targeted advertising</li>
          </ul>
        </>
      )
    },
    {
      id: 'updates',
      title: '15. Do We Make Updates to This Notice?',
      content: (
        <>
          <p><strong>In Short: </strong>Yes, we will update this notice as necessary to stay compliant with relevant laws.</p>

          <p>We may update this privacy notice from time to time. The updated version will be indicated by an updated "Revised" date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.</p>
        </>
      )
    },
    {
      id: 'contact',
      title: '16. How Can You Contact Us About This Notice?',
      content: (
        <>
          <p>If you have questions or comments about this notice, you may email us at compliance@goodhues.ai or contact us by post at:</p>

          <address>
            Goodhues, Inc.<br />
            254 Chapman Rd<br />
            Ste 208 #11021<br />
            Newark, DE 19702<br />
            United States
          </address>
        </>
      )
    },
    {
      id: 'data-review',
      title: '17. How Can You Review, Update, or Delete the Data We Collect From You?',
      content: (
        <>
          <p>Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it. To request to review, update, or delete your personal information, please contact us at compliance@goodhues.ai.</p>
        </>
      )
    }
  ];

  return (
    <section className="privacy-policy-section">
      <div className="privacy-policy-container">
        <div className="privacy-policy-content">
          <div className="privacy-policy-header">
            <h1>Privacy Policy</h1>
            <p className="company-intro">
              This privacy notice for Goodhues, Inc. ("Company," "we," "us," or "our"), 
              describes how and why we might collect, store, use, and/or share ("process") 
              your information when you use our services ("Services"), such as when you:
            </p>
            <ul>
              <li>Visit our website at <a href="http://www.goodhues.ai">http://www.goodhues.ai</a>, or any website of ours that links to this privacy notice</li>
              <li>Engage with us in other related ways, including any sales, marketing, or events</li>
            </ul>
          </div>

          <div className="privacy-policy-contact">
            <p>
              Questions or concerns? Reading this privacy notice will help you understand 
              your privacy rights and choices. If you do not agree with our policies and 
              practices, please do not use our Services. If you still have any questions 
              or concerns, please contact us at <a href="mailto:info@goodhues.ai">info@goodhues.ai</a>.
            </p>
          </div>

          <div className="privacy-policy-key-points">
            <h2>Summary of Key Points</h2>
            <p>This summary provides key points from our privacy notice, but you can find out more details about any of these topics by using our table of contents below to find the section you are looking for.</p>
          </div>

          <div className="privacy-policy-layout">
            <nav className="privacy-policy-toc sticky">
              <h2>Table of Contents</h2>
              <ol>
                {sections.map((section) => (
                  <li 
                    key={section.id} 
                    className={activeSection === section.id ? 'active' : ''}
                  >
                    <a href={`#${section.id}`}>{section.title}</a>
                  </li>
                ))}
              </ol>
            </nav>

            <div className="privacy-policy-main">
              <div className="privacy-policy-sections">
                {sections.map((section) => (
                  <div 
                    key={section.id} 
                    id={section.id} 
                    className="privacy-policy-section-content"
                  >
                    <h2>{section.title}</h2>
                    {section.content}
                  </div>
                ))}
              </div>

              <div className="privacy-policy-footer">
                <p>Last updated: 2024</p>
                <address>
                  Goodhues, Inc.<br />
                  254 Chapman Rd<br />
                  Ste 208 #11021<br />
                  Newark, DE 19702<br />
                  United States
                </address>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PrivacyPolicy;