import React, { useContext, useEffect } from 'react';
import {
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation,
  useParams,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { CartProvider } from './context/cart.context';
import { UserContext, UserProvider } from './context/user.context';

import Home from './routes/home/home.component';
import Creators from './routes/creators/creators.component';
import Brands from './routes/brands/brands-component';
import AssistantChat from './routes/assistant/assistant.component';
import HomeLayout from './layouts/home.layout';
import AssistantLayout from './layouts/assistant.layout';
import SignIn from './routes/authenticate/sign-in.component';
import SignUp from './routes/authenticate/sign-up.component';
import Storefront from './routes/store/store.component';
import Discover from './routes/discover/discover.component';
import NotFound from './routes/not-found/not-found.component';
import TuneYourTwin from './routes/tune-your-twin/tune-your-twin.component';
import MyStore from './routes/my-store/my-store.component';
import ProtectedRoute from './components/protected-route/protected-route.component';
import Spinner from './components/spinner/spinner.component';
import CreatorNavigation from './components/navigation/creator-navigation/creator-navigation.component';
import PrivacyPolicy from './routes/privacy-policy/privacy-policy.component';
import TermsOfService from './routes/terms-of-service/terms-of-service.component';
import { MyCreatorProvider } from './context/my-creator.context';

const App = () => {
  const { currentUser, isLoading } = useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!isLoading && currentUser && location.pathname === '/') {
      navigate('/project', { replace: true });
    }
  }, [isLoading, currentUser, location.pathname, navigate]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <CartProvider>
      <UserProvider>
        <Routes>
          {/* Home routes */}
          <Route path="/" element={<HomeLayout />}>
            <Route index element={<Home />} />
            <Route path="creators" element={<Creators />} />
            <Route path="brands" element={<Brands />} />
            <Route path="sign-in" element={<SignIn />} />
            <Route path="sign-up" element={<SignUp />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="terms-of-service" element={<TermsOfService />} />
          </Route>

          {/* Assistant routes */}
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <AssistantLayout user={currentUser} />
              </ProtectedRoute>
            }
          >
            <Route path="discover" index element={<Discover />} />
            <Route
              path="project/:projectId?"
              index
              element={<AssistantChat />}
            />
            <Route path="creator/:handle/store" element={<Storefront />} />
          </Route>
          <Route
            path="my-creator"
            element={
              <ProtectedRoute>
                <MyCreatorProvider>
                  <CreatorNavigation />
                </MyCreatorProvider>
              </ProtectedRoute>
            }
          >
            <Route path="settings" index element={<TuneYourTwin />} />
            <Route path="store" element={<MyStore />} />
          </Route>
          <Route path="/404" element={<NotFound />} />
          <Route path="*" element={<Navigate to="/404" replace />} />
        </Routes>
        <ToastContainer />
      </UserProvider>
    </CartProvider>
  );
};

export default App;
