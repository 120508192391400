import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faInstagram,
  faLinkedin,
  faXTwitter,
  faTiktok,
} from '@fortawesome/free-brands-svg-icons';

import Subscribe from '../subscribe-form/subscribe-form.component';

import './footer.styles.css';

import logo from '../../assets/images/brand/logo_white.svg';

const Footer = () => {
  const navigate = useNavigate();

  return (
    <div className="footer-container">
      <div className="footer-top">
        <div className="left-block">
          <div className="secondary-links">
            <img src={logo} className="logo-footer" />
            <a>Contact</a>
            <a href="/terms-of-service">Terms Of Service</a>
            <a href="/privacy-policy">Privacy Policy</a>
            <a className='partners-button' onClick={() => navigate('/brands')}>Partners</a>
          </div>
          <div className="socials">
            <a>
              <FontAwesomeIcon icon={faInstagram} />
            </a>
            <a>
              <FontAwesomeIcon icon={faLinkedin} />
            </a>
            <a>
              <FontAwesomeIcon icon={faTiktok} />
            </a>
            <a>
              <FontAwesomeIcon icon={faXTwitter} />
            </a>
          </div>
        </div>
        <div className="right-block">
          <div className="subscribe-form">
            <Subscribe />
          </div>
        </div>
      </div>
      <div className="copyright">
        Copyright Goodhues Inc 2024. All rights reserved.
      </div>
    </div>
  );
};

export default Footer;
