export const style_images = [
  {
    filename: 'victorian.jpg',
    name: 'Victorian',
    description: 'Ornate, intricate detailing, rich colors, luxurious fabrics.',
    handle: 'victorian',
  },
  {
    filename: 'georgian.jpg',
    name: 'Georgian',
    description:
      'Symmetrical layouts, classical proportions, elegant detailing.',
    handle: 'georgian',
  },
  {
    filename: 'colonial.jpg',
    name: 'Colonial',
    description:
      'Simple, functional, classic wood furniture, understated decor.',
    handle: 'colonial',
  },
  {
    filename: 'neoclassical.jpg',
    name: 'Neoclassical',
    description:
      'Inspired by classical Greek and Roman design, elegant, symmetrical.',
    handle: 'neoclassical',
  },
  {
    filename: 'french_country.jpg',
    name: 'French Country',
    description: 'Rustic charm, distressed wood, soft fabrics, vintage style.',
    handle: 'french_country',
  },
  {
    filename: 'tuscan.jpg',
    name: 'Tuscan',
    description:
      'Earthy tones, rustic wood, wrought iron, Mediterranean influence.',
    handle: 'tuscan',
  },
  {
    filename: 'shaker.jpg',
    name: 'Shaker',
    description: 'Minimalist, functional, craftsmanship-focused, clean lines.',
    handle: 'shaker',
  },
  {
    filename: 'traditional.jpg',
    name: 'Traditional',
    description:
      'Classic designs, formal elements, rich colors, European influences.',
    handle: 'traditional',
  },
  {
    filename: 'bauhaus.jpg',
    name: 'Bauhaus',
    description:
      'Function-focused, clean lines, geometric forms, minimalist aesthetic.',
    handle: 'bauhaus',
  },
  {
    filename: 'modern.jpg',
    name: 'Modern',
    description:
      'Clean lines, simple forms, natural materials, minimal ornamentation.',
    handle: 'modern',
  },
  {
    filename: 'mid_century_modern.jpg',
    name: 'Mid-Century Modern',
    description: 'Organic shapes, minimalism, and functionality.',
    handle: 'mid_century_modern',
  },
  {
    filename: 'contemporary.jpg',
    name: 'Contemporary',
    description:
      'Neutral colors, clean lines, a mix of textures, current trends.',
    handle: 'contemporary',
  },
  {
    filename: 'minimalist.jpg',
    name: 'Minimalist',
    description:
      'Uncluttered, simple design, neutral color palette, functional.',
    handle: 'minimalist',
  },
  {
    filename: 'industrial.jpg',
    name: 'Industrial',
    description:
      'Raw, exposed materials like brick and steel, rugged, unfinished.',
    handle: 'industrial',
  },
  {
    filename: 'scandinavian.jpg',
    name: 'Scandinavian',
    description:
      'Light colors, clean lines, natural materials, functional design.',
    handle: 'scandinavian',
  },
  {
    filename: 'urban_modern.jpg',
    name: 'Urban Modern',
    description: 'A sleek blend of industrial and contemporary aesthetics.',
    handle: 'urban_modern',
  },
  {
    filename: 'japandi.jpg',
    name: 'Japandi',
    description:
      'Fusion of Japanese minimalism and Scandinavian functionality.',
    handle: 'japandi',
  },
  {
    filename: 'transitional.jpg',
    name: 'Transitional',
    description: 'Blend of traditional and contemporary elements.',
    handle: 'transitional',
  },
  {
    filename: 'eclectic.jpg',
    name: 'Eclectic',
    description:
      'Mix of diverse styles and eras, emphasizes individuality and creativity.',
    handle: 'eclectic',
  },
  {
    filename: 'bohemian.jpg',
    name: 'Bohemian',
    description:
      'Rich colors, layered textiles, patterns, personal expression.',
    handle: 'bohemian',
  },
  {
    filename: 'hollywood_regency.jpg',
    name: 'Hollywood Regency',
    description: 'Glamorous, bold colors, luxurious materials, dramatic flair.',
    handle: 'hollywood_regency',
  },
  {
    filename: 'art_deco.jpg',
    name: 'Art Deco',
    description:
      'Bold geometric shapes, metallic finishes, rich, opulent colors.',
    handle: 'art_deco',
  },
  {
    filename: 'art_nouveau.jpg',
    name: 'Art Nouveau',
    description:
      'Curved lines, floral motifs, intricate nature-inspired detailing.',
    handle: 'art_nouveau',
  },
  {
    filename: 'retro.jpg',
    name: 'Retro',
    description:
      'Playful shapes, bold colors, 1950s-1980s vintage-inspired style.',
    handle: 'retro',
  },
  {
    filename: 'farmhouse.jpg',
    name: 'Farmhouse',
    description: 'Cozy, rustic, distressed wood, vintage charm, warm feel.',
    handle: 'farmhouse',
  },
  {
    filename: 'rustic.jpg',
    name: 'Rustic',
    description:
      'Natural materials like wood and stone, earthy, weathered finish.',
    handle: 'rustic',
  },
  {
    filename: 'cottage_core.jpg',
    name: 'Cottage-Core',
    description: 'Romanticized rural life, vintage-inspired, floral patterns.',
    handle: 'cottage_core',
  },
  {
    filename: 'shabby_chic.jpg',
    name: 'Shabby Chic',
    description:
      'Feminine, distressed finishes, pastel colors, soft vintage touch.',
    handle: 'shabby_chic',
  },
  {
    filename: 'tropical.jpg',
    name: 'Tropical',
    description:
      'Bright colors, natural materials like bamboo, nature-inspired patterns.',
    handle: 'tropical',
  },
  {
    filename: 'nautical.jpg',
    name: 'Nautical',
    description:
      'Whites, blues, maritime accents, wood, inspired by coastal living.',
    handle: 'nautical',
  },
  {
    filename: 'southwestern.jpg',
    name: 'Southwestern',
    description: 'Warm colors, Native American patterns, adobe textures.',
    handle: 'southwestern',
  },
  {
    filename: 'parisian.jpg',
    name: 'Parisian',
    description: 'Chic elegance, classic French elements, ornate detailing.',
    handle: 'parisian',
  },
  {
    filename: 'mediterranean.jpg',
    name: 'Mediterranean',
    description:
      'Warm colors, terracotta, stucco, wrought iron, natural elements.',
    handle: 'mediterranean',
  },
  {
    filename: 'moroccan.jpg',
    name: 'Moroccan',
    description:
      'Vibrant colors, intricate patterns, and rich textures inspired by Morocco.',
    handle: 'moroccan',
  },
  {
    filename: 'futuristic.jpg',
    name: 'Futuristic',
    description: 'Sleek, bold shapes, modern technology, innovative materials.',
    handle: 'futuristic',
  },
  {
    filename: 'biophilic_design.jpg',
    name: 'Biophilic Design',
    description: 'Incorporating nature, plants, water features, natural light.',
    handle: 'biophilic_design',
  },
  {
    filename: 'maximalist.jpg',
    name: 'Maximalist',
    description: 'Bold colors, patterns, layered textures.',
    handle: 'maximalist',
  },
  {
    filename: 'cyberpunk.jpg',
    name: 'Cyberpunk',
    description: 'Futuristic, neon colors, metallic finishes, tech-inspired.',
    handle: 'cyberpunk',
  },
  {
    filename: 'eco_friendly.jpg',
    name: 'Eco-Friendly',
    description: 'Upcycled furniture, eco-conscious, natural materials.',
    handle: 'eco_friendly',
  },
  {
    filename: 'dark_academia.jpg',
    name: 'Dark Academia',
    description: 'Moody, vintage, academic aesthetic, dark woods, leather.',
    handle: 'dark_academia',
  },
];

const s3_url = process.env.REACT_APP_S3_URL;

export const getStyleImages = () => {

  const images = style_images.map((style) => {
    const filename = style.handle + '.webp';
    const src = s3_url + '/styles/' + filename;

    return {
      image: {
        src,
        alt: style.name,
      },
      name: style.name,
      value: style.handle,
      description: style.description,
    };
  });

  return images;
};
