import { useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPenToSquare,
  faFolderClosed,
  faShop,
  faWandSparkles,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';

import './button.styles.css';

const BUTTON_TYPE_CLASSES = {
  new_project: 'new-project',
  folder: 'folder',
  creator: 'creator',
  store_light: 'store-light',
  creator_light: 'creator-light',
  creator_save: 'creator-save',
  creator_private: 'creator-private',
  creator_public: 'creator-public',
  home: 'home-button',
  login: 'login-button',
  login_form: 'login-form',
  signup: 'sign-up-button',
  signup_form: 'sign-up-form',
  primary: 'light-button',
  secondary: 'medium-button',
  google: 'google-button',
  simple: 'simple',
  cancel: 'cancel',
  okay: 'okay',
  delete: 'delete',
  instruction: 'instruction',
};

const ICONS = {
  new_project: faPenToSquare,
  folder: faFolderClosed,
  creator: faUsers,
  store_light: faShop,
  creator_light: faWandSparkles,
  google: faGoogle,
  creator_private: faEyeSlash,
  creator_public: faEye,
  instruction: faPlus,
};

const Button = ({
  children,
  buttonType,
  collapsible = false,
  onClick,
  navigateTo = '',
  ...otherProps
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = (event) => {
    if (onClick) {
      onClick(event);
    }
    if (navigateTo) {
      navigate(navigateTo);
    }
  };

  const isActive = location.pathname === navigateTo;

  return (
    <button
      onClick={handleClick}
      className={`button-container ${BUTTON_TYPE_CLASSES[buttonType]} ${collapsible ? 'collapsible' : ''} ${isActive ? 'active' : ''}`}
      {...otherProps}
    >
      {ICONS[buttonType] && (
        <FontAwesomeIcon icon={ICONS[buttonType]} className="button-icon" />
      )}
      <div className={`${collapsible ? 'no-button-text' : ''}`}>{children}</div>
    </button>
  );
};

export default Button;
