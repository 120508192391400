import SignInForm from '../../components/sign-in-form/sign-in-form.component';

import './authenticate.styles.css';

const SignIn = () => {
  return (
    <div className="authenticate-container">
      <SignInForm />
    </div>
  );
};

export default SignIn;
