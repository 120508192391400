import React, { useContext, useState, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { CreatorsContext } from '../../context/creator.context';
import Button from '../button/button.component';
import MuiButton from '@mui/material/Button';
import { ProjectContext } from '../../context/project.context';
import { ProjectMessagesContext } from '../../context/messages.context';
import CreatorSelect from '../creator-select/creator-select.component';
import './assistant-top-navigation.styles.css';

const AssistantTopNavigation = ({
  store = false,
  discover = false,
  chat = false,
}) => {
  const { currentCreator, setCurrentCreator } = useContext(CreatorsContext);
  const { currentProject } = useContext(ProjectContext);
  const { projectCreators, addProjectCreator, isLoading } = useContext(
    ProjectMessagesContext,
  );
  const [showCreatorSelector, setShowCreatorSelector] = useState(false);

  const handleAddCreator = (creator) => {
    // if creator is already in projectCreators, don't add them again ok
    if (projectCreators.some((c) => c.id === creator.id)) {
      setShowCreatorSelector(false);
      return;
    }
    addProjectCreator(creator);
    setShowCreatorSelector(false);
  };

  const filteredCreators = projectCreators.filter(
    (creator) => creator.id !== currentCreator?.id,
  );

  return (
    <AppBar
      position="absolute"
      sx={{ zIndex: 1500, width: '100%', alignItems: 'start' }}
    >
      <Toolbar>
        {chat && (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Avatar
              sx={{
                bgcolor: 'transparent',
                color: 'black',
                border: '2px solid black',
              }}
              onClick={() => setShowCreatorSelector(!showCreatorSelector)}
            >
              {showCreatorSelector ? (
                <FontAwesomeIcon icon={faMinus} />
              ) : (
                <FontAwesomeIcon icon={faPlus} />
              )}
            </Avatar>
            <AvatarGroup max={4}>
              {filteredCreators.map((creator, index) => (
                <Avatar
                  key={creator.id}
                  alt={creator.persona.handle}
                  src={creator.profile_image_url}
                  onClick={() => setCurrentCreator(creator)}
                />
              ))}
            </AvatarGroup>
            <div className="creator-section">
              {currentCreator && currentCreator.profile_image_url && (
                <>
                  <Avatar
                    src={currentCreator.profile_image_url}
                    alt={currentCreator.persona.handle}
                    sx={{ marginRight: '10px' }}
                  />
                  <div className="creator-group">
                    <span className="creator-text">You're creating with</span>
                    <span className="creator-name">
                      {currentCreator.persona?.handle}
                    </span>
                  </div>
                </>
              )}
            </div>
            {currentCreator && (
              <MuiButton
                variant="outlined"
                color="secondary"
                href={`https://${process.env.REACT_APP_SHOPIFY_SHOP_DOMAIN}/collections/${currentCreator.persona.handle}-collection`}
                target="_blank"
                sx={{ marginLeft: '16px' }}
              >
                {' '}
                Store
              </MuiButton>
            )}
          </Box>
        )}
        {store && currentCreator && (
          <div className={`assistant-top-container ${store ? 'store' : ''}`}>
            <Button
              buttonType="creator_light"
              type="button"
              collapsible={true}
              navigateTo="/chat"
              onClick={() => setCurrentCreator(currentCreator)}
            >
              Design With Me
            </Button>
            <h4 className="store-title">
              {currentCreator.persona?.handle}'s Store
            </h4>
          </div>
        )}
        {discover && (
          <div
            className={`assistant-top-container ${discover ? 'discover' : ''}`}
          >
            <Button
              buttonType="creator_light"
              type="button"
              collapsible={true}
              navigateTo={
                currentProject ? `/project/${currentProject.id}` : '/project'
              }
            >
              Back to chat
            </Button>
          </div>
        )}
      </Toolbar>
      <CreatorSelect
        open={showCreatorSelector}
        onClose={() => setShowCreatorSelector(false)}
        onSelect={handleAddCreator}
      />
    </AppBar>
  );
};

export default AssistantTopNavigation;
