import React, { useState } from 'react';
import './additional-info.styles.css';

const AdditionalInfo = ({ onChange, command }) => {
  const [budget, setBudget] = useState('');
  const [roomSize, setRoomSize] = useState('');
  const [otherDetails, setOtherDetails] = useState('');

  const handleBudgetChange = (e) => {
    let newBudget;
    newBudget = e.target.value;
    setBudget(e.target.value);
    onChange(newBudget, 'budget', command);
  };

  const handleRoomSizeChange = (e) => {
    let newRoomSize;
    newRoomSize = e.target.value;
    setRoomSize(e.target.value);
    onChange(newRoomSize, 'room_size', command);
  };

  const handleOtherInfoChange = (e) => {
    let newOtherInfo;
    newOtherInfo = e.target.value;
    setOtherDetails(e.target.value);
    onChange(newOtherInfo, 'other_info', command);
  };

  return (
    <div className="additional-info">
      <p>Additional Info</p>
      <input
        type="text"
        id="budget"
        placeholder="What's your budget for this project?"
        onChange={handleBudgetChange}
      />
      <input
        type="text"
        id="square-feet"
        placeholder="What's the size of the room in sq. feet?"
        onChange={handleRoomSizeChange}
      />
      <textarea
        id="other-details"
        placeholder="Any other info you'd like to add?"
        onChange={handleOtherInfoChange}
      />
    </div>
  );
};

export default AdditionalInfo;
