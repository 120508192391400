import { useState } from 'react';

import { roomTypes, styles } from '../../options/room-types-styles.options';

import SampleNumber from '../sample-number/sample-number.component';

import './type-of-room.styles.css';

const TypeOfRoom = ({ onChange, command, samples = true }) => {
  const [designCount, setDesignCount] = useState(1);
  const [roomType, setRoomType] = useState('');
  const [style, setStyle] = useState('');
  const [style2, setStyle2] = useState('');

  const handleRoomTypeChange = (e) => {
    setRoomType(e.target.value);
    onChange(
      e.target.options[e.target.selectedIndex].text,
      'type_of_room',
      command,
    );
  };

  const handleStyleChange = (e) => {
    setStyle(e.target.value);
    onChange(
      e.target.options[e.target.selectedIndex].text,
      'design_style_1',
      command,
    );
  };

  const handleStyle2Change = (e) => {
    setStyle2(e.target.value);
    onChange(
      e.target.options[e.target.selectedIndex].text,
      'design_style_2',
      command,
    );
  };

  const DesignCountChange = (newDesignCount) => {
    onChange(newDesignCount, 'number_samples', command);
  };

  return (
    <div className="type-of-room">
      <p>Type of Room & Styles</p>
      <SampleNumber onChange={DesignCountChange} samples={samples} />
      <select value={roomType} onChange={handleRoomTypeChange}>
        <option value="" disabled>
          Choose type of room...
        </option>
        {roomTypes.map(
          (room) =>
            room.status === 'active' && (
              <option key={room.value} value={room.value}>
                {room.label}
              </option>
            ),
        )}
      </select>
      <select value={style} onChange={handleStyleChange}>
        <option value="" disabled>
          Choose a style...
        </option>
        {styles.map(
          (style) =>
            style.status === 'active' && (
              <option key={style.value} value={style.value}>
                {style.label}
              </option>
            ),
        )}
      </select>
      <select value={style2} onChange={handleStyle2Change}>
        <option value="" disabled>
          Choose a second style (optional)...
        </option>
        {styles.map(
          (style) =>
            style.status === 'active' && (
              <option key={style.value} value={style.value}>
                {style.label}
              </option>
            ),
        )}
      </select>
    </div>
  );
};

export default TypeOfRoom;
