import React from 'react';
import './spinner.styles.css';

const Spinner = ({ spin = false }) => {
  return (
    <div className="loading-overlay">
      {spin ? (
        <div className="spinner"></div>
      ) : (
        <div className="fading-dots">
          <span>●</span>
          <span>●</span>
          <span>●</span>
        </div>
      )}
    </div>
  );
};

export default Spinner;
