import { createContext, useState, useContext } from 'react';

const CtaContext = createContext();

export const CtaProvider = ({ children }) => {
  const [activeCta, setActiveCta] = useState('ctaActive');

  return (
    <CtaContext.Provider value={{ activeCta, setActiveCta }}>
      {children}
    </CtaContext.Provider>
  );
};

export const useCtaContext = () => useContext(CtaContext);
