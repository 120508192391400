import { fetchProtectedRoute } from './utils';

export const createProject = async (projectData, creatorId) => {
  try {
    const response = await fetchProtectedRoute('/projects', 'POST', null, {
      project: projectData,
      creatorId,
    });
    if (response.id) {
      console.log('Project created successfully:', response);
      return response;
    } else {
      throw new Error('Failed to create project');
    }
  } catch (error) {
    console.error('Error creating project:', error);
    throw error;
  }
};

export const getAllProjects = async () => {
  try {
    const response = await fetchProtectedRoute('/projects', 'GET');
    return response;
  } catch (error) {
    console.error('Error fetching projects:', error);
    throw error;
  }
};

export const getProjectById = async (projectId) => {
  try {
    const project = await fetchProtectedRoute(`/projects/${projectId}`, 'GET');
    console.log('Project fetched:', project);
    return project;
  } catch (error) {
    console.error('Error fetching project:', error);
    throw error;
  }
};

// Add other project-related API calls as needed

export const sendMessageToProject = async (
  projectId,
  creatorId,
  message,
  options,
) => {
  try {
    // Create FormData if we have a file to upload
    if (options?.baseImageData instanceof File) {
      const formData = new FormData();
      formData.append('message', message);
      formData.append('creatorId', creatorId);
      formData.append('baseImage', options.baseImageData);
      formData.append(
        'options',
        JSON.stringify({
          command: options.command || null,
          numberOfSamples: options.numberOfSamples || 1,
        }),
      );

      return await fetchProtectedRoute(
        `/projects/${projectId}/messages`,
        'POST',
        null, // Don't set Content-Type header
        formData,
        true, // Flag to indicate FormData
      );
    }

    // Regular JSON request if no file
    return await fetchProtectedRoute(
      `/projects/${projectId}/messages`,
      'POST',
      {
        'Content-Type': 'application/json',
      },
      {
        message,
        creatorId,
        options: {
          command: options?.command || null,
          numberOfSamples: options?.numberOfSamples || 1,
        },
      },
    );
  } catch (error) {
    console.error('Error sending message:', error);
    throw error;
  }
};

export const getCommand = async (projectId, message) => {
  try {
    const response = await fetchProtectedRoute(
      `/projects/${projectId}/command`,
      'POST',
      null,
      { message },
    );

    return response.command;
  } catch (error) {
    console.error('Error fetching command:', error);
    return null;
  }
};

export const enhancePrompt = async (prompt) => {
  try {
    const response = await fetchProtectedRoute(
      `/projects/enhance-prompt`,
      'POST',
      null,
      { prompt },
    );
    return response.enhancedPrompt;
  } catch (error) {
    console.error('Error enhancing prompt:', error);
    throw error;
  }
};

export const getProjectMessages = async (projectId) => {
  try {
    const response = await fetchProtectedRoute(
      `/projects/${projectId}/messages`,
      'GET',
    );
    return response;
  } catch (error) {
    console.error('Error fetching project messages:', error);
    throw error;
  }
};

export const streamResponse = async (projectId) => {
  try {
    const response = await fetchProtectedRoute(
      `/projects/${projectId}/messages/stream`,
      'GET',
    );

    return response;
  } catch (error) {
    console.error('Error streaming response:', error);
    throw error;
  }
};

// Add other project-related API calls as needed
