import './mini-spinner.styles.css';

const MiniSpinner = ({ size = 24 }) => {
  return (
    <div className="mini-spinner" style={{ width: size, height: size }}>
      <div className="mini-spinner-inner"></div>
    </div>
  );
};

export default MiniSpinner;
