export const materialsData = {
  "Marbles": [
    { "name": "Azul Aran Marble", "value": "azul_aran_marble" },
    { "name": "Azul Macauba Marble", "value": "azul_macauba_marble" },
    { "name": "Bianco Dolomiti Marble", "value": "bianco_dolomiti_marble" },
    { "name": "Black Marquinia Gold Marble", "value": "black_marquinia_gold_marble" },
    { "name": "Blue Bahia Marble", "value": "blue_bahia_marble" },
    { "name": "Breccia Onice Rosa Marble", "value": "breccia_onice_rosa_marble" },
    { "name": "Breccia Oniciata Marble", "value": "breccia_oniciata_marble" },
    { "name": "Breccia Pernice Marble", "value": "breccia_pernice_marble" },
    { "name": "Emperador Dark", "value": "emperador_dark" },
    { "name": "Emperador Light Marble", "value": "emperador_light_marble" },
    { "name": "Emperador Marble", "value": "emperador_marble" },
    { "name": "Giallo Reale", "value": "giallo_reale" },
    { "name": "Giallo Siena Marble", "value": "giallo_siena_marble" },
    { "name": "Golden Spider Marble", "value": "golden_spider_marble" },
    { "name": "Green Marble", "value": "green_marble" },
    { "name": "Marble (Calacatta)", "value": "marble_(calacatta)" },
    { "name": "Marble (Carrara)", "value": "marble_(carrara)" },
    { "name": "Nero Marquina Marble", "value": "nero_marquina_marble" },
    { "name": "Panda Marble Marble", "value": "panda_marble_marble" },
    { "name": "Portoro Marble", "value": "portoro_marble" },
    { "name": "Rainforest Green Marble", "value": "rainforest_green_marble" },
    { "name": "Rosa Aurora Marble", "value": "rosa_aurora_marble" },
    { "name": "Rosa Perlino Marble", "value": "rosa_perlino_marble" },
    { "name": "Rosso Levanto Marble", "value": "rosso_levanto_marble" },
    { "name": "Rosso Verona Marble", "value": "rosso_verona_marble" },
    { "name": "Statuario Marble", "value": "statuario_marble" },
    { "name": "Thassos White Marble", "value": "thassos_white_marble" },
    { "name": "Verde Alpi Marble", "value": "verde_alpi_marble" },
    { "name": "Verde Guatemala Marble", "value": "verde_guatemala_marble" },
    { "name": "Verde Ming Marble", "value": "verde_ming_marble" }
  ],
  "Stones and composites": [
    { "name": "Basalt", "value": "basalt" },
    { "name": "Engineered Stone (Quartz)", "value": "engineered_stone_(quartz)" },
    { "name": "Black Granite", "value": "black_granite" },
    { "name": "Limestone", "value": "limestone" },
    { "name": "Onyx", "value": "onyx" },
    { "name": "Quartzite", "value": "quartzite" },
    { "name": "Sandstone", "value": "sandstone" },
    { "name": "Slate", "value": "slate" },
    { "name": "Soapstone", "value": "soapstone" },
    { "name": "Terrazzo", "value": "terrazzo" },
    { "name": "Travertine", "value": "travertine" },
    { "name": "White Granite", "value": "white_granite" }
  ],
  "Composites": [
    { "name": "Acrylic", "value": "acrylic" },
    { "name": "Carbon Fiber", "value": "carbon_fiber" },
    { "name": "Color Acrylic", "value": "color_acrylic" },
    { "name": "Corian", "value": "corian" },
    { "name": "Fiberglass", "value": "fiberglass" },
    { "name": "Resin", "value": "resin" },
    { "name": "Vinyl", "value": "vinyl" }
  ],
  "Concrete": [
    { "name": " Concrete", "value": "_concrete" },
    { "name": "Concrete with Pigment", "value": "concrete_with_pigment" },
    { "name": "Polished Concrete", "value": "polished_concrete" }
  ],
  "Fabrics": [
    { "name": "Canvas", "value": "canvas" },
    { "name": "Cotton", "value": "cotton" },
    { "name": "Denim", "value": "denim" },
    { "name": "Jute", "value": "jute" },
    { "name": "Linen", "value": "linen" },
    { "name": "Silk", "value": "silk" },
    { "name": "Velvet", "value": "velvet" },
    { "name": "Wool", "value": "wool" }
  ],
  "Glass and Ceramics": [
    { "name": "Ceramic", "value": "ceramic" },
    { "name": "Colored Glass", "value": "colored_glass" },
    { "name": "Frosted Glass", "value": "frosted_glass" },
    { "name": "Glass", "value": "glass" },
    { "name": "Mirror", "value": "mirror" },
    { "name": "Porcelain", "value": "porcelain" },
    { "name": "Smoked Glass", "value": "smoked_glass" },
    { "name": "Tempered Glass", "value": "tempered_glass" },
    { "name": "Terracotta", "value": "terracotta" }
  ],
  "Metals": [
    { "name": "Aluminum", "value": "aluminum" },
    { "name": "Brass", "value": "brass" },
    { "name": "Bronze", "value": "bronze" },
    { "name": "Copper", "value": "copper" },
    { "name": "Iron (Cast Iron)", "value": "iron_(cast_iron)" },
    { "name": "Steel", "value": "steel" },
    { "name": "Tin", "value": "tin" },
    { "name": "Titanium", "value": "titanium" },
    { "name": "Zinc", "value": "zinc" }
  ],
  "Natural Materials": [
    { "name": "Aniline Leather", "value": "aniline_leather" },
    { "name": "Bonded Leather", "value": "bonded_leather" },
    { "name": "Cane", "value": "cane" },
    { "name": "Colored Cork", "value": "colored_cork" },
    { "name": "Dyed Rattan", "value": "dyed_rattan" },
    { "name": "Faux Leather (PU Leather)", "value": "faux_leather_(pu_leather)" },
    { "name": "Full-Grain Leather", "value": "full-grain_leather" },
    { "name": "Natural Cork", "value": "natural_cork" },
    { "name": "Natural Wicker", "value": "natural_wicker" },
    { "name": "Nubuck Leather", "value": "nubuck_leather" },
    { "name": "Polished Rattan", "value": "polished_rattan" },
    { "name": "Rattan", "value": "rattan" },
    { "name": "Suede Leather", "value": "suede_leather" },
    { "name": "Synthetic Rattan", "value": "synthetic_rattan" },
    { "name": "Top-Grain Leather", "value": "top-grain_leather" }
  ],
  "Tiles": [
    { "name": "Ceramic Mosaic Tiles", "value": "ceramic_mosaic_tiles" },
    { "name": "Glass Mosaic Tiles", "value": "glass_mosaic_tiles" },
    { "name": "Glossy Glazed Tiles", "value": "glossy_glazed_tiles" },
    { "name": "Matte Glazed Tiles", "value": "matte_glazed_tiles" },
    { "name": "Metallic Mosaic Tiles", "value": "metallic_mosaic_tiles" },
    { "name": "Patterned Glazed Tiles", "value": "patterned_glazed_tiles" },
    { "name": "Porcelain Tiles", "value": "porcelain_tiles" },
    { "name": "Terracotta Tiles", "value": "terracotta_tiles" }
  ],
  "Wallpapers and paneling": [
    { "name": "3D Wall Panels", "value": "3d_wall_panels" },
    { "name": "Metallic Wallpaper", "value": "metallic_wallpaper" },
    { "name": "Printed Pattern Wallpaper", "value": "printed_pattern_wallpaper" },
    { "name": "Wood Laminate Wall Panels", "value": "wood_laminate_wall_panels" }
  ],
  "Woods": [
    { "name": "African Ebony", "value": "african_ebony" },
    { "name": "African Mahogany", "value": "african_mahogany" },
    { "name": "Aged Teak", "value": "aged_teak" },
    { "name": "American Cherry", "value": "american_cherry" },
    { "name": "American Walnut", "value": "american_walnut" },
    { "name": "Bamboo", "value": "bamboo" },
    { "name": "Black Ash", "value": "black_ash" },
    { "name": "Brazilian Cherry (Jatoba)", "value": "brazilian_cherry_(jatoba)" },
    { "name": "Brazilian Rosewood", "value": "brazilian_rosewood" },
    { "name": "Carbonized Bamboo", "value": "carbonized_bamboo" },
    { "name": "Eastern Red Cedar", "value": "eastern_red_cedar" },
    { "name": "Eastern White Pine", "value": "eastern_white_pine" },
    { "name": "European Walnut", "value": "european_walnut" },
    { "name": "Golden Teak", "value": "golden_teak" },
    { "name": "Hard Maple", "value": "hard_maple" },
    { "name": "Honduran Mahogany", "value": "honduran_mahogany" },
    { "name": "Indian Rosewood", "value": "indian_rosewood" },
    { "name": "Laminates", "value": "laminates" },
    { "name": "Macassar Ebony", "value": "macassar_ebony" },
    { "name": "Mahogany", "value": "mahogany" },
    { "name": "Maple", "value": "maple" },
    { "name": "Natural Bamboo", "value": "natural_bamboo" },
    { "name": "Oak", "value": "oak" },
    { "name": "Plywood", "value": "plywood" },
    { "name": "Recycled Wood", "value": "recycled_wood" },
    { "name": "Red Birch", "value": "red_birch" },
    { "name": "Red Oak", "value": "red_oak" },
    { "name": "Southern Yellow Pine", "value": "southern_yellow_pine" },
    { "name": "Stained Wood", "value": "stained_wood" },
    { "name": "Teak", "value": "teak" },
    { "name": "Western Red Cedar", "value": "western_red_cedar" },
    { "name": "White Ash", "value": "white_ash" },
    { "name": "Yellow Birch", "value": "yellow_birch" }
  ]
};

const s3_url = process.env.REACT_APP_S3_URL;

export const getMaterials = () => {
  const materials = Object.entries(materialsData).flatMap(([category, items]) => 
    items.map((material) => {
      const filename = material.name + '.webp';
      const src = s3_url + '/materials/' + filename;

      return {
        image: {
          src,
          alt: material.name,
        },
        name: material.name,
        value: material.value,
        category,
      };
    })
  );

  return materials;
};