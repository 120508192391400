import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { signUpWithEmail, signInWithGoogle } from '../../utils/api/auth';

import FormInput from '../form-input/form-input.component';
import Button from '../button/button.component';

import './sign-up-form.styles.css';

const defaultFormFields = {
  displayName: '',
  email: '',
  password: '',
  confirmPassword: '',
};

const SignUpForm = () => {
  const [formFields, setFormFields] = useState(defaultFormFields);
  const { displayName, email, password, confirmPassword } = formFields;

  const resetFormFields = () => {
    setFormFields(defaultFormFields);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!email || !password || !confirmPassword) return;

    if (password !== confirmPassword) {
      alert('Passwords do not match');
      return;
    }

    try {
      await signUpWithEmail(email, password, {
        data: {
          full_name: displayName,
        },
      });
      resetFormFields();
      toast.success(
        'Sign-up successful! Please verify your email to continue.',
      );
    } catch (error) {
      console.log('User creation encountered an error', error);
      if (error.message.includes('email already in use')) {
        alert('Cannot create user, email already in use');
      } else {
        toast.error('An error occurred during sign-up. Please try again.');
      }
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormFields({ ...formFields, [name]: value });
  };

  const handleGoogleSignUp = async () => {
    try {
      await signInWithGoogle();
    } catch (error) {
      console.log('Google sign-up encountered an error', error);
    }
  };

  return (
    <div className="sign-up-form-container">
      <h2>Sign up</h2>
      <form onSubmit={handleSubmit} className="sign-up-form">
        <FormInput
          label="Full Name"
          inputOptions={{
            type: 'text',
            required: true,
            onChange: handleChange,
            name: 'displayName',
            value: displayName,
          }}
        />
        <FormInput
          label="Email"
          inputOptions={{
            type: 'email',
            required: true,
            onChange: handleChange,
            name: 'email',
            value: email,
          }}
        />
        <FormInput
          label="Password"
          inputOptions={{
            type: 'password',
            required: true,
            onChange: handleChange,
            name: 'password',
            value: password,
          }}
        />
        <FormInput
          label="Confirm Password"
          inputOptions={{
            type: 'password',
            required: true,
            onChange: handleChange,
            name: 'confirmPassword',
            value: confirmPassword,
          }}
        />
        <div className="signup-button-container">
          <Button buttonType="signup_form" type="submit">
            Sign Up
          </Button>
          <Button
            buttonType="google"
            type="button"
            onClick={handleGoogleSignUp}
          >
            Google Sign Up
          </Button>
        </div>
      </form>
      <div className="to-sign-in-container">
        <div>
        <Link className="to-sign-in" to="/sign-in">
          Already have an account? Log in.
        </Link>
      </div>
    </div>
    </div>
  );
};

export default SignUpForm;
