import { toast } from 'react-toastify';
import { fetchProtectedRoute, getAPIUrl, getGHUrl } from './utils';

export const createCreatorProfile = async (creatorData) => {
  const response = await fetchProtectedRoute(
    '/creators',
    'POST',
    null,
    creatorData,
  );
  return response;
};

export const updateCreator = async (creatorData) => {
  const response = await fetchProtectedRoute(
    `/creators/${creatorData.id}`,
    'PUT',
    null,
    creatorData,
  );
  return response;
};

export const getCreators = async (searchText) => {
  const response = await fetchProtectedRoute(
    `/creators?search=${searchText}`,
    'GET',
  );
  return response;
};

export const getCreatorById = async (creatorId) => {
  const response = await fetchProtectedRoute(`/creators/${creatorId}`, 'GET');
  return response;
};

export const getCreatorByHandle = async (handle) => {
  try {
    console.log(`Fetching creator with handle: ${handle}`);
    const data = await fetchProtectedRoute(`/creators/handle/${handle}`, 'GET');

    if (!data || typeof data !== 'object') {
      throw new Error('Invalid response from server');
    }

    return data;
  } catch (error) {
    console.error('Error in getCreatorByHandle:', error);
    throw error;
  }
};

export const addProductsToStore = async (graphqlProductIds) => {
  try {
    // Extract the numeric Shopify product ID from the GraphQL ID
    const shopifyProductIds = graphqlProductIds.map((id) =>
      id.split('/').pop(),
    );

    const response = await fetchProtectedRoute(
      '/creators/add-products-to-store',
      'POST',
      null,
      { productIds: shopifyProductIds },
    );
    if (response.success) {
      console.log('Product added successfully:', response);
      return response;
    } else {
      throw new Error('Failed to add product to store');
    }
  } catch (error) {
    console.error('Error adding product to store:', error);
    throw error;
  }
};

export const deleteCreator = async (creatorId) => {
  const response = await fetchProtectedRoute(
    `/creators/${creatorId}`,
    'DELETE',
  );
  return response;
};

export const getCreatorPersona = async () => {
  const response = await fetchProtectedRoute('/creators/persona', 'GET');
  return response;
};

export const createCreatorPersona = async (personaData) => {
  const response = await fetchProtectedRoute(
    '/creators/persona',
    'POST',
    null,
    personaData,
  );
  return response;
};

export const updateCreatorPersona = async (personaData, personaId) => {
  const response = await fetchProtectedRoute(
    `/creators/persona/${personaId}`,
    'PUT',
    null,
    personaData,
  );
  return response;
};

export const createSupplierConnection = async (storeUrl) => {
  const response = await fetchProtectedRoute(
    '/suppliers/connection',
    'POST',
    null,
    { store_url: storeUrl },
  );
  return response;
};

export const installSalesChannel = async (storeConnectionId) => {
  const url = `${getAPIUrl()}/auth/shopify/app?store_connection_id=${storeConnectionId}`;
  window.open(url, '_blank');
};

export const getCreatorLink = async (creatorHandle, onSuccess, onError) => {
  const link = `${getGHUrl()}/project?creatorHandle=${creatorHandle}`;
  navigator.clipboard.writeText(link).then(onSuccess).catch(onError);
};
