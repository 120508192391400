import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import TypeOfRoom from '../type-of-room/type-of-room.component';
import ColorModule from '../color-module/color-module.component';
import AdditionalInfo from '../additional-info/additional-info.component';

import './smart-search.styles.css';

const SmartSearch = ({ onChange, onClose }) => {
  return (
    <div className="smart-search">
      <FontAwesomeIcon
        icon={faPlus}
        className={`close-smart-search-button`}
        onClick={onClose}
      />
      <div className="smart-search-row">
        <TypeOfRoom onChange={onChange} command="SmartSearch" samples={false} />
        <ColorModule onChange={onChange} command="SmartSearch" />
        <AdditionalInfo onChange={onChange} command="SmartSearch" />
      </div>
    </div>
  );
};

export default SmartSearch;
