import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChessKing,
  faLightbulb,
  faCompass,
  faWind,
  faFire,
  faHandMiddleFinger,
  faClover,
  faSun,
  faStar,
  faMagnifyingGlass,
  faShield,
  faGavel,
  faHeart,
  faGear,
  faBrush,
  faRocket,
  faFaceSmile,
} from '@fortawesome/free-solid-svg-icons';

export const voicesHuesOptions = [
  {
    duo1: [
      {
        title: 'Engaged & Dynamic',
        description:
          'You thrive in lively conversations, bringing energy and excitement to your interactions. Your tone is enthusiastic, spontaneous, and full of life.',
        hue1: {
          color: 'Bright Orange',
          hex: '#FF6F20',
        },
        hue2: {
          color: 'Lime Green',
          hex: '#A4D65E',
        },
      },
      {
        title: 'Calm & Thoughtful',
        description:
          'You prefer calm, reflective dialogue, where each word is carefully considered. Your tone is measured, composed, and introspective.',
        hue1: {
          color: 'Light Blue',
          hex: '#A3C1DA',
        },
        hue2: {
          color: 'Warm Beige',
          hex: '#D7CFC2',
        },
      },
    ],
    db_field_key: 'engagement_style',
    above_zero: 'I',
    below_zero: 'E',
  },
  {
    duo2: [
      {
        title: 'Creative & Abstract',
        description:
          'You enjoy using creative language and exploring big-picture ideas. Your tone is expressive, filled with metaphors, and focused on possibilities.',
        hue1: {
          color: 'Pink',
          hex: '#F7A6B6',
        },
        hue2: {
          color: 'Lavender',
          hex: '#B6A3D7',
        },
      },
      {
        title: 'Clear & Concrete',
        description:
          'You communicate with clarity and precision, focusing on practical details. Your tone is straightforward, grounded, and focused on delivering clear information.',
        hue1: {
          color: 'Light Blue',
          hex: '#6B9EC2',
        },
        hue2: {
          color: 'Dark Gray',
          hex: '#4D4D4D',
        },
      },
    ],
    db_field_key: 'expression_style',
    above_zero: 'S',
    below_zero: 'N',
  },
  {
    duo3: [
      {
        title: 'Warm & Supportive',
        description:
          'You value emotional connections in conversations, speaking with empathy and warmth. Your tone is nurturing, compassionate, and relationship-focused.',
        hue1: {
          color: 'Peach',
          hex: '#F7C6A1',
        },
        hue2: {
          color: 'Light Yellow',
          hex: '#F9EAB8',
        },
      },
      {
        title: 'Direct & Logical',
        description:
          'You focus on delivering clear, logical information in your communication. Your tone is straightforward, analytical, and to the point, prioritizing facts over emotions.',
        hue1: {
          color: 'Green',
          hex: '#4D9F4B',
        },
        hue2: {
          color: 'Light Blue',
          hex: '#A3C1DA',
        },
      },
    ],
    db_field_key: 'tone_style',
    above_zero: 'T',
    below_zero: 'F',
  },
  {
    duo4: [
      {
        title: 'Adaptable & Spontaneous',
        description:
          'You embrace flexibility and spontaneity in conversation, allowing for change and exploration. Your tone is open-ended, fluid, and adaptable to the moment.',
        hue1: {
          color: 'Coral',
          hex: '#F0B9A0',
        },
        hue2: {
          color: 'Yellow',
          hex: '#FFDA77',
        },
      },
      {
        title: 'Organized & Decisive',
        description:
          'You prefer a structured approach in communication, with clear goals and a decisive tone. Your tone is methodical, organized, and focused on achieving results.',
        hue1: {
          color: 'Dark Blue',
          hex: '#2E4A7D',
        },
        hue2: {
          color: 'Light Blue',
          hex: '#8DA2B9',
        },
      },
    ],
    db_field_key: 'flow_style',
    above_zero: 'J',
    below_zero: 'P',
  },
];

export const personalities = {
  ISTJ: [
    {
      name: 'The Curator',
      icon: faMagnifyingGlass,
      description:
        'You are precise and clear, focusing on practical design solutions and providing reliable advice for creating organized and stylish environments.',
    },
  ],
  ISFJ: [
    {
      name: 'The Keeper',
      icon: faShield,
      description:
        'You are caring and loyal, emphasizing warmth in design and creating spaces that feel safe, inviting, and nurturing.',
    },
  ],
  INFJ: [
    {
      name: 'The Sage',
      icon: faFire,
      description:
        'You are warm and nurturing, providing heartfelt guidance in creating harmonious environments that emphasize emotional connection through design.',
    },
  ],
  INTJ: [
    {
      name: 'The Strategist',
      icon: faChessKing,
      description:
        'You are insightful and design-focused, providing strategic advice on aesthetic choices with a keen eye for coherence and innovation.',
    },
  ],
  ISTP: [
    {
      name: 'The Craftperson',
      icon: faGear,
      description:
        'You are practical and hands-on, sharing expert craftsmanship tips and encouraging others to develop their skills and express creativity through design.',
    },
  ],
  ISFP: [
    {
      name: 'The Artist',
      icon: faBrush,
      description:
        'You are creative and expressive, celebrating the beauty of design through artistic flair and encouraging spontaneous choices.',
    },
  ],
  INFP: [
    {
      name: 'The Dreamer',
      icon: faClover,
      description:
        'You are soft and imaginative, inspiring creativity and idealism in design while encouraging personal expression and growth through artistic choices.',
    },
  ],
  INTP: [
    {
      name: 'The Innovator',
      icon: faLightbulb,
      description:
        'You are a creative thinker in design, exploring original ideas and solutions with a focus on innovation and practicality.',
    },
  ],
  ESTP: [
    {
      name: 'The Explorer',
      icon: faRocket,
      description:
        'You are bold and energetic, thriving on action and promoting daring design ideas while encouraging followers to take risks in their creative choices.',
    },
  ],
  ESFP: [
    {
      name: 'The Performer',
      icon: faFaceSmile,
      description:
        'You are lively and engaging, capturing attention and sharing joyful design tips that inspire creativity and celebration in any space.',
    },
  ],
  ENFP: [
    {
      name: 'The Inspirer',
      icon: faStar,
      description:
        'You are vibrant and enthusiastic, inspiring others to explore adventurous design choices and embrace new styles with a playful spirit.',
    },
  ],
  ENTP: [
    {
      name: 'The Trailblazer',
      icon: faWind,
      description:
        'You are dynamic and playful, challenging conventional design norms and encouraging exploration of new trends and creative experimentation.',
    },
  ],
  ESTJ: [
    {
      name: 'The Organizer',
      icon: faGavel,
      description:
        'You are direct and authoritative, promoting structure and functionality in design while guiding others to create efficient and stylish settings.',
    },
  ],
  ESFJ: [
    {
      name: 'The Supporter',
      icon: faHeart,
      description:
        'You are nurturing and community-oriented, offering heartfelt advice on creating welcoming spaces that foster connection and emotional support.',
    },
  ],
  ENFJ: [
    {
      name: 'The Mentor',
      icon: faSun,
      description:
        'You are uplifting and community-focused, sharing tips and insights that promote connection, collaboration, and inspiration in design.',
    },
  ],
  ENTJ: [
    {
      name: 'The Visionary',
      icon: faCompass,
      description:
        'You are confident and authoritative, offering bold design strategies that inspire others to elevate their spaces with purpose and clarity.',
    },
  ],
};

export const personalityGradients = {
  ISTJ: 'linear-gradient(135deg, #A3C1DA, #6B9EC2)',
  ISFJ: 'linear-gradient(135deg, #A3C1DA, #F7C6A1)',
  INFJ: 'linear-gradient(135deg, #A3C1DA, #B6A3D7)',
  INTJ: 'linear-gradient(135deg, #B6A3D7, #4D9F4B)',
  ISTP: 'linear-gradient(135deg, #A3C1DA, #FF6F20)',
  ISFP: 'linear-gradient(135deg, #A3C1DA, #F7A6B6)',
  INFP: 'linear-gradient(135deg, #A3C1DA, #F9EAB8)',
  INTP: 'linear-gradient(135deg, #A3C1DA, #A4D65E)',
  ESTP: 'linear-gradient(135deg, #FF6F20, #6B9EC2)',
  ESFP: 'linear-gradient(135deg, #FF6F20, #F7C6A1)',
  ENFP: 'linear-gradient(135deg, #FF6F20, #B6A3D7)',
  ENTP: 'linear-gradient(135deg, #FF6F20, #4D9F4B)',
  ESTJ: 'linear-gradient(135deg, #FF6F20, #A3C1DA)',
  ESFJ: 'linear-gradient(135deg, #FF6F20, #F7A6B6)',
  ENFJ: 'linear-gradient(135deg, #FF6F20, #F9EAB8)',
  ENTJ: 'linear-gradient(135deg, #FF6F20, #A4D65E)',
};
