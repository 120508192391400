import './command-categories.styles.css';

const CommandCategory = ({ category, onClick, active }) => {
  return (
    <div className="category-container">
      <span
        className={`command-category ${active ? 'active' : ''}`}
        onClick={() => onClick(category)}
      >
        {category}
      </span>
    </div>
  );
};

export default CommandCategory;
