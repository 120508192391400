import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import TypeOfRoom from '../type-of-room/type-of-room.component';
import ColorModule from '../color-module/color-module.component';
import UploadImage from '../upload-image/upload-image.component';

import './moodboard.styles.css';

const Moodboard = ({ onChange, getImage, onClose }) => {
  return (
    <div className="moodboard-form">
      <FontAwesomeIcon
        icon={faPlus}
        className={`close-moodboard-button`}
        onClick={onClose}
      />
      <div className="moodboard-form-row">
        <TypeOfRoom onChange={onChange} command="Moodboard" />
        <ColorModule onChange={onChange} command="Moodboard" />
        <UploadImage
          onChange={onChange}
          command="Moodboard"
          getImage={getImage}
        />
      </div>
    </div>
  );
};

export default Moodboard;
