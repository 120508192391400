import React, { useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid2';
import { useCtaContext } from '../../context/cta.context';
import { CreatorsContext } from '../../context/creator.context';
import { ProjectContext } from '../../context/project.context';

import AssistantTopNavigation from '../../components/assistant-top-navigation/assistant-top-navigation.component';
import CreatorAvatar from '../../components/creator-avatar/creator-avatar.component';

import './discover.styles.css';

const Discover = () => {
  const { activeCta, setActiveCta } = useCtaContext();
  const { creators, setCurrentCreator } = useContext(CreatorsContext);
  const { currentProject, setCurrentProject } = useContext(ProjectContext);

  useEffect(() => {
    setActiveCta('ctaInactive');
  }, [setActiveCta, currentProject, setCurrentProject]);

  return (
    <Grid container flexGrow={1}>
      <AssistantTopNavigation chat={false} store={false} discover={true} />
      <div className="discover-content-container">
        <div className="all-creators-container">
          {creators.map((creator, index) => (
            <div key={index} className="discover-creator-content-container">
              <CreatorAvatar avatar={creator.avatar} />
              <Link to={`/chat/${creator.text}/store`}>
                <h5>
                  {creator.persona.handle} {creator.displayLastName}
                </h5>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </Grid>
  );
};

export default Discover;
