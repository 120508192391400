import React, { createContext, useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { UserContext } from './user.context';
import {
  getAllProjects,
  createProject,
  getProjectById,
} from '../utils/api/projects';

export const ProjectContext = createContext({
  currentProject: null,
  setCurrentProject: (project) => null,
  projects: [],
  setProjects: () => null,
  fetchProjects: () => null,
  addProject: () => null,
});

export const useProjectContext = () => useContext(ProjectContext);

export const ProjectsProvider = ({ children }) => {
  const [currentProject, setCurrentProject] = useState(null);
  const [projects, setProjects] = useState([]);
  const { currentUser: user } = useContext(UserContext);
  const { projectId } = useParams();

  const fetchProjects = async () => {
    if (user) {
      try {
        const fetchedProjects = await getAllProjects();
        setProjects(fetchedProjects);
      } catch (error) {
        console.error('Error fetching projects:', error);
      }
    }
  };

  const fetchCurrentProject = async () => {
    if (projectId) {
      try {
        const project = await getProjectById(projectId);
        setCurrentProject(project);
      } catch (error) {
        console.error('Error fetching project by ID:', error);
      }
    }
  };

  const addProject = async (projectData) => {
    try {
      const newProject = await createProject(projectData);
      setProjects((prevProjects) => [...prevProjects, newProject]);
      return newProject;
    } catch (error) {
      console.error('Error creating project:', error);
      throw error;
    }
  };

  useEffect(() => {
    fetchProjects();
  }, [user]);

  useEffect(() => {
    fetchCurrentProject();
  }, [projectId]);

  const value = {
    currentProject,
    setCurrentProject,
    projects,
    setProjects,
    fetchProjects,
    addProject,
  };

  return (
    <ProjectContext.Provider value={value}>{children}</ProjectContext.Provider>
  );
};
