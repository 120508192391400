import { useContext, useState, useEffect } from 'react';
import { useRef } from 'react';
import { createPortal } from 'react-dom';

import { useModalContext } from '../../context/modal.context';
import { ProjectContext } from '../../context/project.context';

import Button from '../button/button.component';

import './delete-project.styles.css';

const DeleteProjectModal = ({ projectId, onClose, visible }) => {
  const deleteRef = useRef(null);
  const { activeModal, setActiveModal } = useModalContext();
  const { currentProject, setCurrentProject, projects, setProjects } =
    useContext(ProjectContext);
  const [selectedProject, setSelectedProject] = useState(
    projects.filter((project) => project.id !== projectId),
  );

  useEffect(() => {
    const project = projects.find((project) => project.id === projectId);
    setSelectedProject(project);
  }, [projectId, projects]);

  const close = () => {
    onClose();
    setActiveModal('');
  };

  const onDeleteProject = () => {
    const deletedMessages = {
      ...currentProject,
      messages: [],
    };
    setCurrentProject(deletedMessages);
    const updatedProjects = projects.filter(
      (project) => project.id !== projectId,
    );
    setProjects(updatedProjects);
    setCurrentProject(null);
    setActiveModal('');
  };

  if (!visible) {
    return null;
  }

  return createPortal(
    <div
      ref={deleteRef}
      className={`delete-project-modal ${visible ? 'visible' : ''}`}
    >
      <div className="delete-project-modal-header">
        <h3>Delete project?</h3>
      </div>
      <div className="delete-project-modal-content">
        <p>You're about to delete {selectedProject.text}.</p>
      </div>
      <div className="delete-act-buttons">
        <div className="delete-cancel-button-container">
          <Button buttonType="cancel" onClick={close}>
            Cancel
          </Button>
        </div>
        <div className="delete-cancel-button-container">
          <Button buttonType="delete" onClick={onDeleteProject}>
            Delete
          </Button>
        </div>
      </div>
    </div>,
    document.getElementById('modal-portal'),
  );
};

export default DeleteProjectModal;
