import React, { useContext, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { UserContext } from '../../context/user.context';
import { getAccessToken, signOut } from '../../utils/api/auth';

const ProtectedRoute = ({ children, redirectTo = '/' }) => {
  const { currentUser, setCurrentUser } = useContext(UserContext);
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  useEffect(() => {
    const authenticateUser = async () => {
      try {
        const token = await getAccessToken();
        if (token) {
          setIsAuthenticated(true);
        } else {
          throw new Error('No token found');
        }
      } catch (error) {
        await signOut();
        setCurrentUser(null);
        setIsAuthenticated(false);
      }
    };

    if (currentUser === null) {
      authenticateUser();
    } else {
      setIsAuthenticated(true);
    }
  }, [currentUser, setCurrentUser]);

  if (isAuthenticated === null) {
    return null; // or a loading spinner
  }

  if (!isAuthenticated) {
    return <Navigate to={redirectTo} />;
  }

  return children;
};

export default ProtectedRoute;
